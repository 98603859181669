import { Box, List, ListItem, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import defaultProfilePic from '../../../../assets/defaultProfilePic.svg';
import { Link } from 'react-router-dom';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { SortOrderType } from '../../../../types';
import { sortDataByDateProp } from '../../../../utils';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import sun from '../../../../assets/sun.svg';
import shadow from '../../../../assets/shadow.svg';
import { deleteClient, getClients } from '../../../../api';
import { IF } from '../../../../components/core';
import { ConfirmationPopup } from '../../../../components/common';
import { ActionPanel, ClientImage } from '../components';

const useStyles = makeStyles((theme) => ({
  actionPanelContainer: {
    padding: '16px 0 0',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #bcbcbc',
  },
  mainHeading: {
    fontFamily: 'PTSerif',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 1.12,
    color: '#5f5f5f',
    textAlign: 'left',
    paddingBottom: 10,
  },
  noClients: {
    fontFamily: 'Roboto',
    color: '#7b7b78',
    fontSize: 22,
    marginTop: 20,
  },
  clientsList: {
    fontFamily: 'Roboto',
    color: '#7b7b78',
    fontSize: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: 30,
    width: '99%',
    maxWidth: '90vw',
    position: 'relative',
    overflowY: 'auto',
    maxHeight: '60vh',
    marginTop: 20,
    paddingRight: 15,
    '& ul': { padding: 0 },
  },
  clientCard: {
    justifyContent: 'center',
    maxWidth: 350,
    position: 'relative',
    padding: '22px 20px 15px',
    borderRadius: 5,
    border: 'solid 0.5px #bcbcbc',
    backgroundColor: '#efefef',
    flexDirection: 'column',
    '&.centeredList': { justifySelf: 'center' },
    '&.MuiListItem-gutters': {
      display: 'inherit',
      textAlign: 'unset',
      '& a': {
        textDecoration: 'unset',
        color: 'unset',
      },
    },
  },
  clientCard__header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    width: '100%',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: '#bcbcbc',
      height: 75,
      width: '100%',
      zIndex: 0,
    },
  },
  clientCard__header__details: {
    zIndex: 1,
    color: 'white',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: 14,
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    '&:hover': {
      color: '#efefef!important',
    },
  },
  clientCard__header__delete: {
    zIndex: 2,
  },
  clientCard__body: {
    marginTop: -20,
  },
  centered: {
    position: 'relative',
    textAlign: 'center',
    borderBottom: 'solid 2px #bcbcbc',
  },
  img: {
    position: 'relative',
    height: 68,
    '& > img': {
      borderRadius: '50%',
      width: 68,
      height: 68,
      position: 'relative',
      objectFit: 'cover',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 96,
      width: 96,
      backgroundColor: 'white',
      opacity: 0.7,
      borderRadius: '50%',
    },
  },
  info: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'flex-start',
    textAlign: 'left',
    marginTop: 20,
  },
  heading: {
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: 0.88,
    padding: '26px 15px 15px',
  },
  infoDetails: {
    marginBottom: 27,
    minWidth: '50%',
    '&:first-child': {
      width: '100%',
      height: 36,
    },
  },
  infoHeading: {
    fontWeight: 700,
  },
  emailLink: {
    position: 'absolute',
    zIndex: 2,
    color: '#7b7b78',
    textDecoration: 'underline!important',
    '&:hover': {
      color: '#bcbcbc!important',
    },
  },
  list: {
    width: '100%',
    maxWidth: 360,
    position: 'relative',
    overflowY: 'auto',
    maxHeight: 300,
    '& ul': { padding: 0 },
  },
  iconArrowForward: {
    fontSize: 22,
    cursor: 'pointer',
    backgroundColor: '#24c776',
    borderRadius: '50%',
  },
  sunContainer: {
    display: 'flex',
    fontSize: 24,
    marginBottom: 40,
  },
  sunnySide: {
    color: '#d8bb28',
    display: 'flex',
    position: 'relative',
    width: '50%',
  },
  shadowSide: {
    color: '#5f5f5f',
    display: 'flex',
    position: 'relative',
    width: '50%',
  },
  percentage: {
    position: 'absolute',
    right: 20,
    bottom: -30,
  },
  iconClearRounded: {
    fontSize: 26,
    cursor: 'pointer',
    color: 'white',
  },
}));

export const ClientList: FC = () => {
  const classes = useStyles();
  const [clients, setClients] = useState<any>([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteUserEmail, setDeleteUserEmail] = useState('');
  const [deleteUserId, setDeleteUserId] = useState(-1);
  const [orgClients, setOrgClients] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState<SortOrderType>(SortOrderType.DESC);
  const [sortBy] = useState('c_date');
  const [search, setSearch] = useState('');

  useEffect(() => {
    setClients([...sortDataByDateProp(clients, sortBy, sortOrder)]);
  }, [sortOrder]);

  useEffect(() => {
    getClientsData();
  }, []);

  const handleChange = () => {
    getClientsData();
  };

  const handleDelete = () => {
    setDeleteDialog(false);
    deleteClientAPI(deleteUserId);
    handleChange();
  };

  const deleteClientAPI = async (id: number) => {
    const payload = { ids: [id] };
    try {
      const res = await deleteClient(payload);
      handleChange();
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleOnDelete = (userId: number, userEmail: string) => {
    setDeleteUserId(userId);
    setDeleteUserEmail(userEmail);
    setDeleteDialog(true);
  };

  const getClientsData = async () => {
    try {
      const res = await getClients();
      const sortedData = [...sortDataByDateProp(res.data, sortBy, sortOrder)];
      setClients(sortedData);
      setOrgClients(sortedData);
    } catch (err: any) {
      console.log(err);
    }
  };

  const truncate = (str: string) => {
    return str.length > 25 ? str.substring(0, 24) + '...' : str;
  };

  const handleSearchField = (value: any) => {
    const cleanedValue = value.toLowerCase().replace(/\s+/g, '');
    setSearch(cleanedValue);
    setClients([...filterSearchedUsers(orgClients, cleanedValue)]);
  };

  const filterSearchedUsers = (data: any[], value: string) =>
    [...data].filter(
      (user) =>
        user.email.toLowerCase().trim().includes(value) ||
        user.first_name.toLowerCase().trim().includes(value) ||
        user.last_name.toLowerCase().trim().includes(value) ||
        (
          user.first_name.toLowerCase().trim() +
          user.last_name.toLowerCase().trim()
        ).includes(value)
    );

  return (
    <div>
      <Box className={classes.actionPanelContainer}>
        <div
          className={classes.mainHeading}
          data-testid="registered-clientlist-title"
        >
          Meine Klienten
        </div>
        <ActionPanel
          sortOrder={sortOrder}
          handleChangeSort={(sortType: SortOrderType) => {
            setSortOrder(sortType);
          }}
          showThreeDotsMenu={true}
          onChange={(e: any) => handleSearchField(e.target.value)}
          value={search}
        />
      </Box>
      {clients.length === 0 ? (
        <div className={classes.noClients} data-testid="no-clients">
          Du hast noch keine Klienten.
        </div>
      ) : (
        <List className={classes.clientsList}>
          {clients.map((client: any, index: number) => (
            <ListItem
              className={`${classes.clientCard} ${
                clients.length < 4 ? 'centeredList' : null
              }`}
              key={index}
            >
              <div className={classes.clientCard__header}>
                <Link
                  data-testid={`detail-${index + 1}`}
                  className={classes.clientCard__header__details}
                  to={{
                    pathname: '/coach/clients/dashboard',
                    state: {
                      emailProp: client.email,
                      c_dateProp: client.c_date,
                    },
                  }}
                >
                  Details
                  <ArrowForwardRoundedIcon
                    className={classes.iconArrowForward}
                  />
                </Link>
                <div className={classes.clientCard__header__delete}>
                  <CloseRoundedIcon
                    data-testid="delete-client"
                    className={classes.iconClearRounded}
                    onClick={() => handleOnDelete(client.user_id, client.email)}
                  />
                </div>
              </div>
              <div className={classes.clientCard__body}>
                <div className={classes.centered}>
                  <div className={classes.img}>
                    <ClientImage path={client.image_path} />
                  </div>
                  <div className={classes.heading} data-testid="fullname">
                    {`${client.first_name} ${client.last_name}`}
                  </div>
                </div>
                <div className={classes.info}>
                  <div className={classes.infoDetails}>
                    <div className={classes.infoHeading}>Mail-Adresse</div>
                    <a
                      className={classes.emailLink}
                      href={`mailto:${client.email}`}
                    >
                      {truncate(client.email)}
                    </a>
                  </div>

                  <div className={classes.infoDetails}>
                    <div className={classes.infoHeading}>Sensitivitätstyp</div>
                    <div>{client.neurotype}</div>
                  </div>
                  <div className={classes.infoDetails}>
                    <div className={classes.infoHeading}>Ausprägung</div>
                    <div>{client.auspraegung}</div>
                  </div>
                </div>
                <div className={classes.sunContainer}>
                  <div className={classes.sunnySide}>
                    <img src={sun} width="64" />
                    <div className={classes.percentage}>
                      {`${client.sonnenseite}%`}
                    </div>
                  </div>
                  <div className={classes.shadowSide}>
                    <img src={shadow} width="66" />
                    <div className={classes.percentage}>
                      {`${client.schattenseite}%`}
                    </div>
                  </div>
                </div>
              </div>
            </ListItem>
          ))}
        </List>
      )}
      <IF condition={deleteDialog}>
        <ConfirmationPopup
          title={'Bist Du sicher?'}
          description={
            <div>
              <div>Dadurch wird der Klient</div>
              <div style={{ fontWeight: 500 }}>{deleteUserEmail}</div>
              <div>permanent gelöscht.</div>
            </div>
          }
          buttonClose={'abbrechen'}
          button={'Löschen'}
          buttonClass={'buttonRed'}
          onChange={handleDelete}
          onClose={() => setDeleteDialog(false)}
        />
      </IF>
    </div>
  );
};

export default ClientList;
