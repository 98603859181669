import React, { FC, Fragment, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  popupContainer: {},
  popup: {
    '& .MuiDialogContent-root': {
      flex: 'unset',
    },
    '& .MuiPaper-root': {
      backgroundColor: '#8e8e8e',
      borderRadius: 5,
      color: 'white',
      textAlign: 'center',
      padding: 20,
      justifyContent: 'center',
      minWidth: 400,
      minHeight: 200,
      '& .MuiDialogTitle-root': {
        padding: 0,
      },
      '& .MuiDialogTitle-root .MuiTypography-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
      },
      '& .MuiDialogContent-root > p': {
        color: 'white',
        fontStyle: 'italic',
        marginBottom: 0,
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
      },
      '& .MuiDialogActions-root .MuiButtonBase-root': {
        color: 'white',
      },
    },
  },
  closeButton: {
    backgroundColor: '#efefef',
    textTransform: 'none',
    padding: '10px 30px',
    marginRight: 7,
    '& > span': {
      color: '#5f5f5f',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  okButton: {
    padding: '10px 30px',
    textTransform: 'uppercase',
    '&.buttonGreen': {
      backgroundColor: '#24c776',
      '&.MuiButton-root:hover': {
        backgroundColor: 'rgba(36, 199, 118, 0.7)',
      },
    },
    '&.buttonRed': {
      backgroundColor: '#fd5f58',
      color: '#fcfcfc',
      '&.MuiButton-root:hover': {
        backgroundColor: 'rgba(253,95,88,0.7)',
      },
    },
  },
}));

type Props = {
  title: string;
  description: any;
  buttonClose: string;
  button: string;
  buttonClass: string;
  onChange: Function;
  onClose?: Function;
};

export const ConfirmationPopup: FC<Props> = ({
  title,
  description,
  buttonClose,
  button,
  buttonClass,
  onChange,
  onClose,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleConfirm = () => {
    setOpen(false);
    onChange();
  };

  return (
    <div className={classes.popupContainer}>
      <Dialog
        className={classes.popup}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="confirm-dialog"
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter') {
            handleConfirm();
          }
        }}
      >
        <DialogTitle data-testid="confirmation-title" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            data-testid="confirmation-desc"
            id="alert-dialog-description"
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttonClose.length !== 0 ? (
            <Button
              data-testid="confirmation-closeBtn"
              className={classes.closeButton}
              onClick={handleClose}
            >
              {buttonClose}
            </Button>
          ) : null}
          <Button
            data-testid="confirmation-okBtn"
            className={`${classes.okButton} ${buttonClass}`}
            onClick={handleConfirm}
          >
            {button}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationPopup;
