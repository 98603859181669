import { Box, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ClientList } from './components';
import LeadList from './components/LeadList';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'calc(100% - 20%)',
    padding: '0px 10% 20px',
  },
}));

export const CoachesClients: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <ClientList />
      <LeadList />
    </Box>
  );
};

export default CoachesClients;
