import { makeStyles, Box } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { getImageAPI } from '../../../../api';
import defaultProfilePic from '../../../../assets/defaultProfilePic.svg';

const useStyles = makeStyles((theme) => ({}));

type Props = {
  path: string;
};
export const ClientImage: FC<Props> = ({ path }) => {
  const classes = useStyles();
  const [image, setImage] = useState<string>(defaultProfilePic);

  useEffect(() => {
    if (path !== '') getImage(path);
  }, [path]);

  const getImage = async (path: string) => {
    const payload = {
      path: path,
    };
    try {
      const res = await getImageAPI(payload);
      let imageStr = Buffer.from(res.data, 'binary').toString('base64');
      var imgsrc = 'data:image/png;base64,' + imageStr;
      setImage(imgsrc);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <img src={image} alt="profile-picture" />
    </>
  );
};

export default ClientImage;
