import React, { FC, useState, useEffect } from 'react';
import {
  Box,
  Button,
  makeStyles,
  IconButton,
  FilledInput,
  FormHelperText,
  FormControl,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Checkbox,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import { questions, EmailRegex } from '../utils';
import notificationIcon from '../assets/notification.svg';
import { Alert } from '@material-ui/lab';
import { testSubmit } from '../api';

const useStyles = makeStyles((theme) => ({
  qContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#bcbcbc',
    paddingBottom: 50,
    '& .MuiFormHelperText-contained': {
      margin: '5px 0 0 0',
      fontStyle: 'italic',
      color: '#ff5f58 !important',
    },
    '& .MuiIconButton-label': {
      color: 'white',
    },
    [theme.breakpoints.down('xs')]: {
      background: 'transparent',
    },
  },
  container: {
    background: '#7b7b78',
    marginTop: theme.spacing(5),
    padding: theme.spacing(10, 20),
    width: '60vw',
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 10),
    },
    [theme.breakpoints.down('xs')]: {
      padding: '32px 18px',
      width: 'unset',
    },
  },
  arrowContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: 270,
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrowRoundActive: {
    borderRadius: '50%',
    width: 58,
    height: 58,
    color: 'white',
    [theme.breakpoints.down('md')]: {
      width: 38,
      height: 38,
    },
    '&,.MuiIconButton-root.Mui-disabled': {
      color: 'white !important',
      background: '#0ea554 !important',
    },
  },
  qsnIndex: {
    font: 'normal normal bold 22px/29px Roboto',
    letterSpacing: '0.44px',
    color: 'white',
  },
  qsnName: {
    textAlign: 'left',
    color: 'white',
    paddingTop: theme.spacing(3.5),
    font: "700 24px/34px 'Roboto'",
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      lineHeight: 1.14,
    },
  },
  smallText: {
    width: '100%',
    maxWidth: 700,
    font: "400 16px/22px 'Roboto'",
    color: 'white',
    fontStyle: 'italic',
    textAlign: 'left',
    paddingTop: theme.spacing(1),
  },
  submitBtn: {
    borderRadius: 2,
    boxShadow: 'none',
    background: '#5f5f5f',
    font: "700 13px/24px 'Roboto'",
    color: 'white',
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
    },
    '&.Mui-disabled': {
      borderRadius: 2,
      boxShadow: 'none',
      background: '#5f5f5f',
      font: "700 13px/24px 'Roboto'",
      color: 'white',
      opacity: 0.5,
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    marginTop: theme.spacing(3),
  },
  termsConditionsContainer: {
    textAlign: 'left',
  },
  termsCondition: {
    font: "400 16px/22px 'Roboto'",
    color: 'white',
    fontStyle: 'italic',
    '& a': {
      color: 'white',
    },
  },
  emailInput: {
    background: theme.palette.common.white,
    borderRadius: 2,
    '&:hover': {
      background: '#eeeeee',
    },
    '&,.MuiInputBase-input:focus': {
      background: theme.palette.common.white,
    },
    '&,.MuiFilledInput-input': {
      color: '#0000008A',
      font: '400 16px / 24px "Roboto"',
      letterSpacing: 0.9,
    },
  },
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > img': {
      margin: 'auto',
    },
  },
  notifications: {
    font: '700 16px / 24px "Roboto"',
    letterSpacing: '0.09px',
    color: '#5F5F5F',
  },
}));

type Props = {
  handelBack: Function;
  handleVielenDank: Function;
  answers: any;
};

export const QuestionnaireSubmit: FC<Props> = (props) => {
  const { handelBack, answers, handleVielenDank } = props;
  const classes = useStyles();
  const [conditionOne, setConditionOne] = useState(false);
  const [email, setEmail] = useState('');
  const [errorObj, setErrorObj] = useState({ email: '' });
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  const dataPrivacyUrl = `${window.location.origin}/datenschutz`;

  const handleChangeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditionOne(event.target.checked);
    setIsFormEmpty(false);
  };

  const validateEmail = (email: string) => EmailRegex.test(email.toLowerCase());

  const validateForm = () => {
    if (isFormEmpty === true) return;
    let errorList: any = {};

    if (!email) {
      errorList.email = 'Bitte gib Deine E-Mail Adresse an';
    }

    if (!validateEmail(email)) {
      errorList.email = 'Bitte gib Deine E-Mail Adresse an';
    }

    if (Object.keys(errorList).length === 0) {
      setErrorObj({ email: '' });
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };

  useEffect(() => {
    validateForm();
  }, [email, conditionOne]);

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    setIsFormEmpty(false);
  };

  const handelSubmit = async () => {
    if (!validateForm()) return;

    const { origin, href } = new URL(
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    );

    const answersPayload: any = {};
    answers.forEach((i: any) => {
      answersPayload[`question${i.questionNumber}`] = i.question;
      answersPayload[`answer${i.questionNumber}`] =
        i.selectedAnswer.optionNumber;
    });

    const payload = {
      email,
      url: href,
      test_data: answersPayload,
    };

    try {
      const res = await testSubmit(payload);
      handleVielenDank();
    } catch (err: any) {
      console.log(err);
      setApiErrorMsg(err.response.data.detail);
    }
  };

  return (
    <Box className={classes.qContainer}>
      <Box className={classes.container}>
        <Box className={classes.arrowContainer}>
          <Box>
            <IconButton
              data-testid="arrow-back-submit"
              className={classes.arrowRoundActive}
              onClick={() => handelBack()}
            >
              <ArrowBackIcon
                fontSize="large"
                className={classes.arrowRoundActive}
              />
            </IconButton>
          </Box>
          <Box
            className={classes.qsnIndex}
          >{`${answers.length}/${questions.length}`}</Box>
          <Box className={classes.notificationsContainer}>
            <img src={notificationIcon} width="35" height="35" />
            <Box className={classes.notifications} data-testid="notification">
              Geschafft!
            </Box>
          </Box>
        </Box>
        <Box className={classes.qsnName} data-testid="submit-heading">
          Erhalte jetzt Dein Ergebnis samt Erläuterungen zu Deinem Test!
        </Box>
        <Box className={classes.smallText} data-testid="submit-description">
          Durch den Eintrag Deiner Mailadresse und die anschließende Bestätigung
          erhältst Du per Mail automatisch das Resultat. Danach erhältst Du
          gelegentlich weitere Informationen, Angebote und Tipps. Sobald Du dies
          nicht mehr möchtest, kannst Du Dich jederzeit austragen.
        </Box>
        {apiErrorMsg && (
          <Box mt={3}>
            <Alert severity="error">{apiErrorMsg}</Alert>
          </Box>
        )}
        <Box mt={2}>
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="E-MAIL">E-Mail</InputLabel>
            <FilledInput
              inputProps={{ 'data-testid': 'email' }}
              id="email-id"
              className={classes.emailInput}
              disableUnderline
              onChange={(e: any) => handleChange(e)}
              onBlur={() => validateForm()}
              error={!!errorObj.email}
              value={email}
              type="email"
              endAdornment={
                errorObj.email === '' && email !== '' ? (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      style={{
                        background: '#0EA554',
                        width: 30,
                        height: 30,
                        marginRight: 4,
                      }}
                    >
                      <CheckIcon style={{ color: 'white' }} fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  ''
                )
              }
            />
            <FormHelperText id="helper-text" style={{ color: 'red' }}>
              {errorObj.email === '' && email !== '' && !conditionOne ? (
                <div style={{ height: '100%' }}>
                  {
                    'Bitte akzeptiere die Datenschutzbestimmungen und die Nutzungsbedingungen um Dich anzumelden.'
                  }
                </div>
              ) : (
                `${errorObj.email}`
              )}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mt={2}>
          <FormGroup row>
            <FormControlLabel
              className={classes.termsConditionsContainer}
              control={
                <Checkbox
                  data-testid="terms"
                  checked={conditionOne}
                  onChange={handleChangeOne}
                />
              }
              label={
                <span
                  className={classes.termsCondition}
                  data-testid="terms-text"
                >
                  Hiermit stimme ich den{' '}
                  <a href={dataPrivacyUrl} target="_blank">
                    Datenschutzbestimmungen
                  </a>{' '}
                  zu.
                </span>
              }
            />
          </FormGroup>
        </Box>

        <Box className={classes.btnContainer}>
          <Button
            data-testid="submit"
            onClick={handelSubmit}
            variant="contained"
            size="medium"
            disabled={
              conditionOne && email !== '' && errorObj.email === ''
                ? false
                : true
            }
            className={classes.submitBtn}
            color="inherit"
          >
            ABSENDEN
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
