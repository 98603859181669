import { makeStyles, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.75,
    textAlign: 'center',
    color: '#7b7b78',
    justifyContent: 'center',
    '& > div': {
      margin: '40px 10px',
      '& > a': {
        color: 'unset',
        textDecoration: 'unset',
      },
    },
  },
  link: {
    '&:hover': {
      fontWeight: 700,
    },
  },
}));

export const MainFooter: FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box data-testid="copyright">Copyright © 2022 Vantage Space</Box>
      <Box data-testid="datenschutz" className={classes.link}>
        <Link
          to={{
            pathname: '/datenschutz',
          }}
        >
          Datenschutz
        </Link>
      </Box>
      <Box data-testid="impressum" className={classes.link}>
        <Link
          to={{
            pathname: '/impressum',
          }}
        >
          Impressum
        </Link>
      </Box>
      <Box>v1.0.2</Box>
    </footer>
  );
};

export default MainFooter;
