import { Box, Button, makeStyles } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LogoutNotification } from '.';
import { isEmptyOrIsNull } from '../../utils';

const useStyles = makeStyles((theme) => ({
  button: {},
}));

export const LoginLogoutButton: FC = () => {
  const classes = useStyles();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setLoggedIn(!isEmptyOrIsNull(localStorage.getItem('token')));
  }, []);

  const login = () => {
    setLoggedIn(localStorage.length !== 0);
  };

  return (
    <Box>
      {!loggedIn ? (
        <Button
          data-testid="login"
          className={classes.button}
          component={Link}
          to={'/login'}
          onClick={() => login()}
        >
          Login
        </Button>
      ) : (
        <LogoutNotification />
      )}
    </Box>
  );
};

export default LoginLogoutButton;
