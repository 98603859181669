import { Box, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import ShadowSideSvg from '../../assets/shadowDashboard.svg';
import SunnysideSvg from '../../assets/sunDashboard.svg';
import { Circle } from '../core';
import { StatusMeinerDataType } from '../../types/StatusMeinerDataType';

const useStyles = makeStyles((theme) => ({
  circleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '50px',
      '& > div': {
        position: 'relative',
        marginRight: '15px',
      },
    },
  },
  circleContainerCircle: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '140px',
    '& > div': {
      height: '100%',
    },
    '& svg': {
      overflow: 'visible',
    },
  },
  circleCaption: {
    fontSize: '14px',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    width: '90%',
    minHeight: 38,
  },
  circleCaptionBold: {
    fontSize: '18px',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    marginBottom: 10,
    minHeight: 38,
  },
  percentageText: {
    color: theme.palette.common.white,
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sun: {
    marginTop: 120,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -120,
      left: '50%',
      transform: 'translateX(-50%)',
      height: 114,
      width: 114,
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${SunnysideSvg})`,
    },
  },
  shadow: {
    marginTop: 120,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -120,
      left: '50%',
      transform: 'translateX(-50%)',
      height: 114,
      width: 130,
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${ShadowSideSvg})`,
    },
  },
}));

type Props = {
  circlesData: StatusMeinerDataType | null;
};

const initialData: StatusMeinerDataType = {
  efn: 0,
  efp: 0,
  schattenseite: 0,
  sonnenseite: 0,
  unvertrauen: 0,
  wahrnehmungsfaehigkeit: 0,
};

export const StatusMeiner: FC<Props> = ({ circlesData }) => {
  const classes = useStyles();
  const data: StatusMeinerDataType = circlesData || initialData;

  const getCircleOrPercentageComponent = (value: number, color: string) =>
    value > 0 ? (
      <Circle percentageValue={value} color={color} />
    ) : (
      <Box className={classes.percentageText}>{value}%</Box>
    );

  return (
    <Box>
      <Box className={classes.circleContainer}>
        <Box className={classes.sun}>
          <Box className={classes.circleContainerCircle}>
            <Typography className={classes.circleCaption}>
              Empfänglichkeit für Positives
            </Typography>
            {getCircleOrPercentageComponent(data.efp, '#6c9d7b')}
          </Box>
          <Box className={classes.circleContainerCircle}>
            <Typography className={classes.circleCaption}>
              Wahrnehmungsfähigkeit
            </Typography>
            {getCircleOrPercentageComponent(
              data.wahrnehmungsfaehigkeit,
              '#4882b3'
            )}
          </Box>
          <Box className={classes.circleContainerCircle}>
            <Box component="span" className={classes.circleCaptionBold}>
              = Sonnenseite
            </Box>
            {getCircleOrPercentageComponent(data.sonnenseite, '#bbba58')}
          </Box>
        </Box>

        <Box className={classes.shadow}>
          <Box className={classes.circleContainerCircle}>
            <Typography className={classes.circleCaption}>
              Empfänglichkeit für Negatives
            </Typography>
            {getCircleOrPercentageComponent(data.efn, '#6c9d7b')}
          </Box>
          <Box className={classes.circleContainerCircle}>
            <Typography className={classes.circleCaption}>
              Fehlendes Urvertrauen
            </Typography>
            {getCircleOrPercentageComponent(data.unvertrauen, '#bb9851')}
          </Box>
          <Box className={classes.circleContainerCircle}>
            {' '}
            <Box component="span" className={classes.circleCaptionBold}>
              = Schattenseite
            </Box>
            {getCircleOrPercentageComponent(data.schattenseite, '#b87270')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
