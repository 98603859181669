import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthState } from '../providers';

type PrivateRouteType = {
  component: React.ComponentType<any>;
  path?: string | string[];
};

export const PrivateRoute: FC<PrivateRouteType> = ({
  component,
  ...rest
}: any) => {
  const { isLoggedIn }: any = useAuthState();

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isLoggedIn === true ? (
          React.createElement(component, props)
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
