import { BACKEND_URL} from '../config';

export const getMessage = async () => {
  const response = await fetch(BACKEND_URL);

  const data = await response.json();

  if (data.message) {
    return data.message;
  }

  return Promise.reject('Failed to get message from backend');
};

export const getDocument = async (formInput: Object) => {
    let request_data={'form': formInput};
    let response = await fetch(
        '/api/document/', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(request_data)
        }
    );
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
    return response.json();
};


export const getSection = async (formInput: Object, section_id: number) => {
    let request_data={'form': formInput,"section_id": section_id};
    let response = await fetch(
        '/api/workflow/', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(request_data)
        }
    );
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
    return response.json();
};
