import { List, ListItem, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { getSelectedToolsAPI } from '../../api';
import { IF } from '../core';

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '1rem',
    borderRadius: 10,
    background: '#7b7b78',
    padding: '20px 20px 0',
  },
  accordion: {
    width: '100%',
    boxShadow: 'none',
    color: theme.palette.common.white,
  },
  heading: {
    background: '#7b7b78',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'left',
    borderBottom: '2px solid rgb(255 255 255 / 25%)',
    paddingBottom: '20px',
  },
  listWrapper: {
    backgroundColor: '#9d9d9d',
    borderRadius: 10,
  },
  list: {
    margin: '20px 0px',
    minHeight: 100,
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'self-start',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    lineHeight: 1.55,
    letterSpacing: 0.66,
  },
  listItemHeading: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: 1.5,
  },
}));

type Props = {
  email: string;
  prevModuleId: number;
  prevModuleDone: boolean;
};

export const ClientReflections: FC<Props> = ({
  email,
  prevModuleId,
  prevModuleDone,
}) => {
  const classes = useStyles();
  const [prevSubmodules, setPrevSubmodules] = useState([]);

  useEffect(() => {
    if (prevModuleDone) getAllPrevSubmodules();
  }, []);

  const getAllPrevSubmodules = async () => {
    let payload = {
      email: email,
      course: 1,
      module: prevModuleId,
    };
    try {
      const res = await getSelectedToolsAPI(payload);
      setPrevSubmodules(res.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.accordion}>
        <div className={classes.heading}>Reflexion</div>
        <div className={classes.listWrapper}>
          <List className={classes.list}>
            {prevModuleDone &&
              prevSubmodules.map((el: any, index: number) => (
                <ListItem key={index} className={classes.listItem}>
                  <div className={classes.listItemHeading}>{el.name}</div>
                  <div>{el.reflections}</div>
                  <IF condition={!el.reflections}>
                    <div>{'- Keine Reflexion vorhanden'}</div>
                  </IF>
                </ListItem>
              ))}
          </List>
        </div>
      </div>
    </div>
  );
};

export default ClientReflections;
