import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  DialogContent,
  Box,
  TextField,
  DialogTitle,
  FormControl,
  FilledInput,
  FormHelperText,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import TrashIcon from '../../assets/iconTrash.svg';
import defaultProfilePic from '../../assets/defaultProfilePic.svg';
import CloseIcon from '@material-ui/icons/Add';
import {
  getCoachProfileDetailsAPI,
  getImageAPI,
  getOwnUserAPI,
  hardDeleteUserAPI,
  updateCoachProfileDetailsAPI,
  updateCoachProfilePictureAPI,
} from '../../api';
import { CoachType, UserType } from '../../types';
import { ChangePassword, UploadImage } from '.';
import { IF } from '../core';
import { isEmptyOrIsNull, objToQueryString, URLRegex } from '../../utils';
import { Alert } from '@material-ui/lab';
import { ConfirmationPopup } from '../common';
import { useAuthDispatch } from '../../providers';

const useStyles = makeStyles((theme) => ({
  popup: {
    '& .MuiPaper-root': {
      minWidth: '60vw',
      backgroundColor: '#f7f6f1',
      borderRadius: 5,
      color: 'white',
      textAlign: 'center',
      padding: '0 20px 20px',
      '& .MuiDialogTitle-root': {
        padding: 0,
      },
      '& .MuiDialogTitle-root .MuiTypography-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
      },
      '& .MuiDialogContent-root > p': {
        color: 'white',
        fontStyle: 'italic',
        marginBottom: 0,
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
      },
      '& .MuiDialogActions-root .MuiButtonBase-root': {
        color: 'white',
      },
    },
  },
  trashIcon: {
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    position: 'absolute',
    left: 34,
    top: '50%',
    '& > img': {
      display: 'flex',
    },
  },
  imageIcon: {
    cursor: 'pointer',
    width: 25,
  },
  profileImage: {
    cursor: 'pointer',
    width: 25,
    height: 25,
    borderRadius: '50%',
    '& > img': {
      borderRadius: '50%',
      width: 25,
      height: 25,
      objectFit: 'cover',
    },
  },
  editProfileImage: {
    cursor: 'unset',
    margin: 'auto',
    width: 54,
    height: 54,
    position: 'relative',
    '& > img': {
      position: 'relative',
      borderRadius: '50%',
      width: 54,
      height: 54,
      objectFit: 'cover',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 65,
      height: 65,
      backgroundColor: 'white',
      borderRadius: '50%',
      zIndex: 0,
    },
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.17,
    letterSpacing: 0.72,
    color: '#5f5f5f',
    textAlign: 'center',
    padding: 10,
    borderBottom: '1px solid #dbdee7',
    marginBottom: 20,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    position: 'relative',
    width: '100%',
    margin: '10px 0 20px 0',
  },
  closeIcon: {
    color: '#8e8e8e',
    transform: 'translateY(-50%) rotate(45deg)',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: '50%',
  },
  textFieldContainer: {
    marginTop: 20,
  },
  textFieldRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-input, & .MuiOutlinedInput-multiline': {
      backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      margin: '0 10px',
    },
    '& .MuiBox-root, & .MuiBox-root > .MuiFormControl-root': {
      width: '100%',
    },
  },
  multilineFieldContainer: {
    margin: '0 10px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-input, & .MuiOutlinedInput-multiline': {
      backgroundColor: 'white',
    },
  },
  multilineLabel: {
    textAlign: 'left',
    color: '#5f5f5f',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginBottom: 20,
    marginTop: 20,
  },
  outlineButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #aaa9a8',
    color: '#8e8e8e',
    padding: '7px 70px',
    '& > span': {
      color: '#24c776',
      fontWeight: 'bold',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  closeButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #8e8e8e',
    color: '#8e8e8e',
    padding: '7px 30px',
    width: 160,
    '& > span': {
      color: '#5f5f5f',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  saveButton: {
    backgroundColor: '#24c776',
    padding: '7px 30px',
    textTransform: 'none',
    fontWeight: 'bold',
    color: 'white',
    width: 160,
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(36, 199, 118, 0.7)',
    },
  },
  apiErrorMsg: {
    color: '#FF5F58 !important',
    padding: '0px 0px 10px 0px !important',
  },
  helperText: {
    color: '#FF5F58',
    fontStyle: 'italic',
    marginLeft: '10px',
  },
  formField: {
    '& .MuiFilledInput-inputMarginDense': {
      padding: '17px 12px',
    },
    '& .MuiInputBase-root': {
      margin: '0 10px',
      backgroundColor: 'white',
      borderRadius: 5,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '&:hover': {
        borderColor: 'black',
      },
      '&.Mui-focused': {
        border: '2px solid #3f51b5',
      },
    },
  },
}));

const initialData = {
  id: -1,
  activated: false,
  email: '',
  first_name: '',
  last_name: '',
  nickname: '',
  image: '',
  skills: [''],
  certificates: [''],
  url: '',
  api: '',
};

export const EditProfile: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [upload, setUpload] = useState(false);
  const [newImage, setNewImage] = useState(defaultProfilePic);
  const [image, setImage] = useState(defaultProfilePic);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [url, setUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiKeyDisplay, setApiKeyDisplay] = useState('');
  const [certificates, setCertificates] = useState(['']);
  const [skills, setSkills] = useState(['']);

  const [imageFile, setImageFile] = useState<FormData>();

  const [coach, setCoach] = useState<CoachType>(initialData);
  const [changePW, setChangePW] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [errorObj, setErrorObj] = useState<any>({
    url: '',
  });
  const [criteriaObj, setCriteriaObj] = useState({
    url: '',
  });

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [apiKeyChanges, setApiKeyChanges] = useState(false);
  const [urlChanges, setUrlChanges] = useState(false);

  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [confirmationDesc, setConfirmationDesc] = useState('');

  const confUrlTitle = 'Änderung der URL – Bist Du sicher?';
  const confUrlDesc =
    'Deine URL sollte mit der URL übereinstimmen, unter welcher auch der Neurosensitivitätstest integriert ist.';

  const confApiKeyTitle = 'Deinen Sendinblue API Key ändern – Bist Du sicher?';
  const confApiKeyDesc =
    'Die Funktionen der Plattform können nur in vollem Umfang genutzt werden, solange der korrekte API Key hinterlegt ist.';

  const confBothTitle = 'URL und Sendinblue API Key ändern – Bist Du sicher?';
  const confBothDesc =
    'Die Funktionen der Platform können nur in vollem Umfang genutzt werden, solange die URL und der API Key korrekt sind.';

  const { onLogout }: any = useAuthDispatch();
  const history = useHistory();

  useEffect(() => {
    getProfileDetails();
    getPictureDetails();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    getProfileDetails();
  };

  const getProfileDetails = async () => {
    const payload = {
      email: localStorage.getItem('email'),
    };
    try {
      const res = await getCoachProfileDetailsAPI(payload);
      setCoachDetails(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getPictureDetails = async () => {
    try {
      const res = await getOwnUserAPI();
      setUserDetails(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getImage = async (path: string) => {
    const payload = {
      path: path,
    };
    try {
      const res = await getImageAPI(payload);
      let imageStr = Buffer.from(res.data, 'binary').toString('base64');
      var imgsrc = 'data:image/png;base64,' + imageStr;
      setImage(imgsrc);
      setNewImage(imgsrc);
    } catch (err) {
      console.log(err);
    }
  };

  const setUserDetails = (user: UserType) => {
    if (user.image) getImage(user.image);
  };

  const setCoachDetails = (coach: CoachType) => {
    setCoach(coach);
    setName(coach.first_name);
    setSurname(coach.last_name);
    setEmail(coach.email);
    setNickname(coach.nickname);
    setUrl(coach.url);
    setCriteriaObj({ url: coach.url });
    setApiKey(coach.api);
    setCertificates(coach.certificates);
    setSkills(coach.skills);

    encryptApiKey(coach.api);
  };

  const encryptApiKey = (api: string) => {
    let firstPart = api.substring(0, 5);
    let apiKeylength = api.length - 5;
    let lastPart = '';
    for (let i = 0; i < apiKeylength; i++) {
      lastPart = lastPart + '*';
    }
    setApiKeyDisplay(firstPart + lastPart);
  };

  const handleClose = () => {
    setOpen(false);
    setChangePW(false);
    getProfileDetails();
  };

  const handleUploadImage = () => {
    setUpload(true);
  };

  const handleSave = () => {
    if (!validateForm()) return;

    if (apiKeyChanges && urlChanges) {
      setConfirmationTitle(confBothTitle);
      setConfirmationDesc(confBothDesc);
      setOpenConfirmation(true);
    } else if (apiKeyChanges) {
      setConfirmationTitle(confApiKeyTitle);
      setConfirmationDesc(confApiKeyDesc);
      setOpenConfirmation(true);
    } else if (urlChanges) {
      setConfirmationTitle(confUrlTitle);
      setConfirmationDesc(confUrlDesc);
      setOpenConfirmation(true);
    } else {
      saveProfileChanges();
    }
  };

  const saveProfileChanges = () => {
    setApiErrorMsg('');
    updateProfileDetails();
    setApiKeyChanges(false);
    setUrlChanges(false);
    setOpenConfirmation(false);

    if (image !== newImage) {
      setImage(newImage);
      updateProfilePicture();
    }
  };

  const updateProfileDetails = async () => {
    const payload = {
      email: localStorage.getItem('email'),
      first_name: name,
      last_name: surname,
      nickname: nickname,
      activated: true,
      skills: skills,
      certificates: certificates,
      url: criteriaObj.url,
      api: apiKey,
    };
    try {
      const res = await updateCoachProfileDetailsAPI(payload);
      setApiErrorMsg('');
      setOpen(false);
    } catch (err: any) {
      console.log(err);
      setApiErrorMsg(err.response.data.detail);
    }
  };

  const updateProfilePicture = async () => {
    const qs = objToQueryString({ email: localStorage.getItem('email') });
    try {
      const res = await updateCoachProfilePictureAPI(qs, imageFile);
    } catch (err) {
      console.log(err);
    }
  };

  const handleName = (event: any) => {
    if (event.target.value !== '') setName(event.target.value);
  };

  const handleSurname = (event: any) => {
    if (event.target.value !== '') setSurname(event.target.value);
  };

  const handleNickname = (event: any) => {
    if (event.target.value !== '') setNickname(event.target.value);
  };

  const validateURL = (url: string) => URLRegex.test(url.toLowerCase());

  const updateValue = (key: any, value: any) => {
    setCriteriaObj({ ...criteriaObj, [key]: value });
    setUrlChanges(true);
  };

  const handleAPIKey = (event: any) => {
    setApiKey(event.target.value);
    encryptApiKey(event.target.value);
    setApiKeyChanges(true);
  };

  const handleCertificates = (event: any) => {
    setCertificates([event.target.value]);
  };

  const handleSkills = (event: any) => {
    setSkills([event.target.value]);
  };

  const updateUpload = (image: string, file: FormData) => {
    setNewImage(image);
    setImageFile(file);
  };

  const onBlur = () => {
    validateForm();
  };

  const validateForm = () => {
    let errorList: any = {};
    if (isEmptyOrIsNull(criteriaObj.url)) {
      errorList.url = 'URL ist erforderlich';
    } else if (!validateURL(criteriaObj.url)) {
      errorList.url =
        'Bitte gib die URL in folgendem Format ein: https://google.de/';
    } else {
      errorList.url = '';
    }

    if (errorList.url === '') {
      setErrorObj(errorList);
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };

  const handleDeleteAccount = async () => {
    setOpen(false);
    getOwnUserId();
  };

  const getOwnUserId = async () => {
    try {
      const res = await getOwnUserAPI();
      deleteAccount(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAccount = async (user: UserType) => {
    const payload = {
      id: user.id,
    };
    try {
      const res = hardDeleteUserAPI(payload);
      onLogout();
      history.push('/');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Box
        className={classes.profileImage}
        onClick={handleClickOpen}
        data-testid="coach-profile"
      >
        <img src={image} />
      </Box>
      <Dialog
        className={classes.popup}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box className={classes.heading}>
            <Box
              className={classes.trashIcon}
              onClick={() => setOpenDelete(true)}
              data-testid="coach-profile"
            >
              <img src={TrashIcon} />
            </Box>
            <Box data-testid="coach-profile-title">{'Mein Profil'}</Box>
            <CloseIcon
              className={classes.closeIcon}
              onClick={handleClose}
              data-testid="close-icon"
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <IF condition={!upload}>
            <Box
              className={`${classes.profileImage} ${classes.editProfileImage}`}
              onClick={handleUploadImage}
              data-testid="display-picture"
            >
              <img src={newImage} />
            </Box>
          </IF>

          <IF condition={upload}>
            <UploadImage
              onCloseUpload={() => setUpload(false)}
              onSaveUpload={(image: any, file: any) =>
                updateUpload(image, file)
              }
            />
          </IF>
          {apiErrorMsg && (
            <Box>
              <Alert severity="error" className={classes.apiErrorMsg}>
                {apiErrorMsg}
              </Alert>
            </Box>
          )}
          <Box className={classes.textFieldContainer}>
            <Box className={classes.textFieldRow}>
              <Box mb={4}>
                <TextField
                  id={'name'}
                  variant="outlined"
                  placeholder={'Vorname'}
                  onChange={handleName}
                  defaultValue={name}
                  inputProps={{ 'data-testid': 'firstname' }}
                />
              </Box>
              <Box mb={4}>
                <TextField
                  id={'surname'}
                  variant="outlined"
                  placeholder={'Name'}
                  onChange={handleSurname}
                  defaultValue={surname}
                  inputProps={{ 'data-testid': 'lastname' }}
                />
              </Box>
            </Box>
            <Box className={classes.textFieldRow}>
              <Box mb={4}>
                <TextField
                  id={'email'}
                  variant="outlined"
                  placeholder={'E-Mail'}
                  defaultValue={email}
                  disabled
                  inputProps={{ 'data-testid': 'email' }}
                />
              </Box>
              <Box mb={4}>
                <TextField
                  id={'nickname'}
                  variant="outlined"
                  placeholder={'Benutzername'}
                  onChange={handleNickname}
                  defaultValue={nickname}
                  inputProps={{ 'data-testid': 'nickname' }}
                />
              </Box>
            </Box>
            <Box className={classes.textFieldRow}>
              <FormControl fullWidth className={classes.formField}>
                <FilledInput
                  inputProps={{ 'data-testid': 'url' }}
                  defaultValue={url}
                  placeholder={'URL'}
                  id="url"
                  disableUnderline
                  margin="dense"
                  onChange={(event) => updateValue('url', event.target.value)}
                  onBlur={() => onBlur()}
                  error={!!errorObj.url}
                  value={criteriaObj.url}
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.url}
                </FormHelperText>
              </FormControl>

              <Box mb={4}>
                <TextField
                  id={'api-key'}
                  variant="outlined"
                  placeholder={'Sendinblue API Key'}
                  onChange={handleAPIKey}
                  defaultValue={apiKeyDisplay}
                  inputProps={{ 'data-testid': 'apikey' }}
                />
              </Box>
            </Box>
            <IF condition={!changePW}>
              <Box mb={2}>
                <Button
                  className={classes.outlineButton}
                  onClick={() => setChangePW(true)}
                  data-testid="change-coach-password"
                >
                  {'Passwort ändern'}
                </Button>
              </Box>
            </IF>
            <IF condition={changePW}>
              <ChangePassword onChange={() => setChangePW(false)} />
            </IF>
            <Box className={classes.multilineFieldContainer}>
              <Box className={classes.multilineLabel} mb={1}>
                Spezialisierung / Zertifikate
              </Box>
              <Box mb={4}>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={4}
                  id={'certificates'}
                  variant="outlined"
                  onChange={handleCertificates}
                  defaultValue={certificates}
                  inputProps={{ 'data-testid': 'certificate' }}
                />
              </Box>
              <Box className={classes.multilineLabel} mb={1}>
                Kompetenzen
              </Box>
              <Box mb={4}>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={4}
                  id={'skills'}
                  variant="outlined"
                  onChange={handleSkills}
                  defaultValue={skills}
                  inputProps={{ 'data-testid': 'skills' }}
                />
              </Box>
            </Box>
            <IF condition={openDelete}>
              <ConfirmationPopup
                title={'Bist Du sicher?'}
                description={'Dein Konto wird endgültig gelöscht'}
                buttonClose={'abbrechen'}
                button={'Konto löschen'}
                buttonClass={'buttonRed'}
                onChange={handleDeleteAccount}
                onClose={() => setOpenDelete(false)}
              />
            </IF>
          </Box>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Button
            className={classes.closeButton}
            onClick={handleClose}
            data-testid="cancel-coach-update"
          >
            {'abbrechen'}
          </Button>
          <Button
            className={classes.saveButton}
            onClick={handleSave}
            data-testid="confirm-coach-update"
          >
            {'OK'}
          </Button>
        </DialogActions>
      </Dialog>
      <IF condition={openConfirmation}>
        <ConfirmationPopup
          title={confirmationTitle}
          description={confirmationDesc}
          buttonClose={'abbrechen'}
          button={'OK'}
          buttonClass={'buttonRed'}
          onChange={saveProfileChanges}
          onClose={() => setOpenConfirmation(false)}
        />
      </IF>
    </Box>
  );
};

export default EditProfile;
