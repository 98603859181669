import React, { createContext, useContext, useReducer, FC } from 'react';
import { isAuthenticated } from '../utils';

const AuthState = createContext({});
const AuthDispatch = createContext({});

const EVENT_TYPES = {
  LOGIN_SUCCESS: 'login_success',
  LOGIN_ERROR: 'login_error',
  LOGOUT: 'logout',
  ERROR: 'error',
};

const INITIAL_STATE = {
  isLoggedIn: isAuthenticated(),
  token: '',
  email: '',
  permissions: '',
  error: '',
};

const EVENTS = {
  [EVENT_TYPES.LOGIN_SUCCESS]: (state: any, event: any) => {
    const { token, permissions, email } = event.payload;
    return {
      ...state,
      token,
      email,
      permissions,
      isLoggedIn: true,
    };
  },
  [EVENT_TYPES.LOGIN_ERROR]: (state: any, event: any) => {
    const { error } = event.payload;
    return {
      ...state,
      isLoggedIn: false,
      error,
    };
  },
  [EVENT_TYPES.LOGOUT]: (state: any, event: any) => {
    const { error = '' } = event.payload;
    return {
      token: '',
      email: '',
      permissions: '',
      error,
      isLoggedIn: false,
    };
  },
  [EVENT_TYPES.ERROR]: (state: any, event: any) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
    };
  },
};

const AuthReducer = (state: any, event: any) => {
  return EVENTS[event.type](state, event) || state;
};

const AuthProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  const handleLogin = (payload: any) => {
    payload && dispatch({ type: EVENT_TYPES.LOGIN_SUCCESS, payload });
  };

  const handleLogout = (message: any = '') => {
    localStorage.clear();
    dispatch({ type: EVENT_TYPES.LOGOUT, payload: { error: message } });
  };

  const events = {
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return (
    <AuthState.Provider value={state}>
      <AuthDispatch.Provider value={events}>{children}</AuthDispatch.Provider>
    </AuthState.Provider>
  );
};

const useAuthState = () => {
  const context = useContext(AuthState);

  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
};

const useAuthDispatch = () => {
  const context = useContext(AuthDispatch);

  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuthState, useAuthDispatch };
