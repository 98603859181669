import { makeStyles, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import React, { FC, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  userContainer: {
    borderBottom: '1px solid #9d9d9d',
    color: '#f8f8f8',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .MuiCheckbox-root': {
      color: '#f8f8f8',
    },
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  },
  userRow: {
    padding: '20px 0',
    display: 'flex',
    fontFamily: 'Roboto',
    alignItems: 'center',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 14,
    '& a': {
      color: '#f8f8f8',
    },
  },
  deleteIcon: {
    color: '#f8f8f8',
    transform: 'rotate(45deg)',
    cursor: 'pointer',
    width: 32,
    height: 32,
  },
}));

type Props = {
  email: string;
  id: number;
  onDelete: Function;
};

export const UserRow: FC<Props> = ({ email, id, onDelete }) => {
  const classes = useStyles();

  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <Box>
      <Box className={classes.userContainer}>
        <Box className={classes.userRow}>
          <a data-testid="user-row" href={`mailto:${email}`}>
            {email}
          </a>
          <AddIcon
            data-testid="delete-icon"
            onClick={handleDelete}
            className={classes.deleteIcon}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserRow;
