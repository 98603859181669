import React, { FC } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  qContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#bcbcbc',
    paddingBottom: 50,
    [theme.breakpoints.down('xs')]: {
      background: 'transparent',
    },
  },
  container: {
    background: '#7b7b78',
    marginTop: theme.spacing(5),
    padding: theme.spacing(10, 20),
    width: '60vw',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 10),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 6),
      width: 'unset',
    },
  },
  iconBtn: {
    padding: theme.spacing(2),
    background: '#0EA554',
    '&:hover': {
      cursor: 'auto',
      background: '#0EA554',
      color: 'white',
    },
  },
  mainText: {
    color: '#ffffff',
    fontFamily: "'Noto Sans', sans-serif",
    opacity: 1,
    fontSize: 24,
    fontWeight: 600,
    paddingTop: theme.spacing(3),
  },
  smallText: {
    fontSize: 16,
    color: '#ffffff',
    fontFamily: "'Noto Sans', sans-serif",
    opacity: 1,
    fontWeight: 400,
    maxWidth: 400,
    paddingTop: theme.spacing(1.3),
    margin: 'auto',
  },
}));

export const VielenDank: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.qContainer}>
      <Box className={classes.container}>
        <Box>
          <IconButton className={classes.iconBtn}>
            <CheckIcon
              data-testid="check"
              style={{ color: 'white' }}
              fontSize="large"
            />
          </IconButton>
          <Box className={classes.mainText} data-testid="main-text">
            Bestätigung in Deiner (Spam-)Mailbox
          </Box>
          <Box className={classes.smallText} data-testid="small-text">
            In Deiner (allenfalls SPAM-)Mailbox findest Du jetzt eine Nachricht,
            mit der Du Deine Mailadresse aus datenrechtlichen Gründen bestätigen
            musst.
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
