import { Box, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { LoginLogoutButton, Navigation } from '.';
import { useAuthState } from '../../providers';
import { isClient, isCoach } from '../../utils';
import { IF } from '../core';

const useStyles = makeStyles((theme) => ({
  mainHeader: {
    color: '#5f5f5f',
    fontSize: 24,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '40px 10% 10px',
    fontFamily: 'PT serif',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  mainHeader__first: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  mainHeader__second: {},
  loginBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  navLink: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: 0.88,
    color: '#5f5f5f',
    textDecoration: 'none',
    marginRight: 15,
  },
  centerHeader: {},
}));

type Props = {
  showLoginButtons: boolean;
};

export const Mainheader: FC<Props> = ({ showLoginButtons }) => {
  const classes = useStyles();
  const { isLoggedIn }: any = useAuthState();
  const [showButtons, setShowButtons] = useState(true);

  useEffect(() => {
    setShowButtons(isLoggedIn && showLoginButtons);
  }, [isLoggedIn, showLoginButtons]);

  return (
    <header className={classes.mainHeader}>
      <Box></Box>
      <Box className={classes.centerHeader}>
        <span className={classes.mainHeader__first}>Vantage</span>
        <span className={classes.mainHeader__second}>.Space</span>
      </Box>
      <Box className={classes.loginBtnContainer}>
        <IF condition={showLoginButtons}>
          <IF condition={!isCoach() && !isClient()}>
            <LoginLogoutButton />
          </IF>
          <IF condition={isCoach() || isClient()}>
            <Navigation showLoginButtons={showButtons} />
          </IF>
        </IF>
      </Box>
    </header>
  );
};

export default Mainheader;
