import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import toolIcon from '../../assets/toolWhite.svg';
import CloseIcon from '@material-ui/icons/Close';
import { ToolType } from '../../types';
import { getToolDataAPI } from '../../api';

const useStyles = makeStyles((theme) => ({
  toolsLinks: {
    width: '175px',
    padding: '10px',
    backgroundColor: '#7a7976',
    color: theme.palette.common.white,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  toolsLinkText: {
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.44px',
    textAlign: 'center',
    color: theme.palette.common.white,
    paddingTop: 20,
  },
  toolsIcon: {
    width: '30px',
    height: '30px',
    marginBottom: 20,
    backgroundColor: '#24c776',
    borderRadius: '50%',
    padding: 5,
  },
  removeIcon: {
    alignSelf: 'end',
    position: 'absolute',
    top: 10,
    cursor: 'pointer',
  },
  removeContainer: {
    position: 'absolute',
  },
  linkContainer: {
    display: 'flex',
  },
}));

type Props = {
  moduleDone: boolean;
  toolId: number;
  isCoach: boolean;
  onRemoveTool: Function;
};

const initialData = {
  coach_id: 0,
  duration: 0,
  form: '',
  id: 0,
  last_updated: '',
  link: '',
  name: '',
  public: false,
  task: [''],
  task_description: '',
  tool_description: '',
};

export const SelectedTool: FC<Props> = ({
  moduleDone,
  toolId,
  isCoach,
  onRemoveTool,
}) => {
  const classes = useStyles();
  const [toolData, setToolData] = useState<ToolType>();

  useEffect(() => {
    getToolData(toolId);
  }, [toolId]);

  const getToolData = async (toolId: number) => {
    let payload = {
      id: toolId,
    };

    try {
      const res = await getToolDataAPI(payload);
      setToolData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const removeTool = () => {
    onRemoveTool(toolId);
  };

  return (
    <Box className={classes.linkContainer}>
      <Box className={classes.toolsLinks}>
        {isCoach && !moduleDone ? (
          <CloseIcon
            data-testid="delete-selected-tools"
            className={classes.removeIcon}
            onClick={removeTool}
          />
        ) : null}
        <Typography className={classes.toolsLinkText}>
          {toolData?.name}
        </Typography>
        <img className={classes.toolsIcon} src={toolIcon} alt="tool-icon" />
      </Box>
    </Box>
  );
};

export default SelectedTool;
