import { API_ENDPOINTS, post, get, deleteAPI, postImg } from '../utils';

export const handleChangeModuleSubmission = async (payload: any) =>
  post(API_ENDPOINTS.CLOSE_MODULE, payload);

export const getCourseStructureAPI = async (payload: any) =>
  post(API_ENDPOINTS.COURSE_MODULES, payload);

export const getSubmodulesAPI = async (payload: any) =>
  post(API_ENDPOINTS.GET_PRACTICAL_MODULE, payload);

export const getToolDataAPI = async (payload: any) =>
  post(API_ENDPOINTS.GET_TOOL, payload);

export const updatePasswordAPI = async (payload: any) =>
  post(API_ENDPOINTS.CHANGE_PASSWORD, payload);

export const getOwnUserAPI = async () => get(API_ENDPOINTS.GET_OWN_USER);

export const getImageAPI = async (payload: any) =>
  postImg(API_ENDPOINTS.DOWNLOAD_IMAGE_FILE, payload);

export const hardDeleteUserAPI = async (payload: any) =>
  deleteAPI(API_ENDPOINTS.HARD_DELETE_USER, payload);
