import { FC } from 'react';

type Props = {
  condition: boolean;
  children: any;
};

export const IF: FC<Props> = (props) => props.condition && props.children;

export default IF;
