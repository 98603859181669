export const plotLines = [
  {
    value: 0,
    color: 'rgba(85,85,85,0.35)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 0.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 0.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 0.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 1,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 1.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 1.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 1.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 2,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 2.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 2.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 2.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 3,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 3.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 3.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 3.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 4,
    color: 'rgba(157,157,157,0.5)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 4.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 4.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 4.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 5,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 5.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 5.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 5.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 6,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 6.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 6.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 6.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 7,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 7.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 7.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 7.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 8,
    color: 'rgba(85,85,85,0.35)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 8.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 8.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 8.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 9,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 9.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 9.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 9.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 10,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 10.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 10.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 10.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 11,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 11.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 11.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 11.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 12,
    color: 'rgba(157,157,157,0.5)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 12.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 12.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 12.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 13,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 13.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 13.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 13.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 14,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 14.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 14.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 14.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 15,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 15.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 15.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 15.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 16,
    color: 'rgba(85,85,85,0.35)',
    width: 2,
    zIndex: 3,
  },
  {
    value: 16.25,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 16.5,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 16.75,
    color: 'rgba(142,142,142,0.1)',
    width: 0,
    zIndex: 3,
  },
  {
    value: 17,
    color: 'rgba(142,142,142,0.1)',
    width: 2,
    zIndex: 3,
  },
];

export const chartConfig: any = {
  chart: {
    spacingBottom: 0,
    spacingTop: 10,
    spacingLeft: 10,
    spacingRight: 10,
    plotBorderWidth: '0.5px',
    plotBorderColor: 'rgba(85,85,85,0.35)',
    backgroundColor: 'transparent',
    exporting: {
      enabled: false,
    },
  },
  borderWidth: 0,
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    min: -1,
    max: 17,
    startOnTick: false,
    endOnTick: false,
    ticks: {
      stepSize: 1,
    },
    title: {
      enabled: false,
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 1,
    gridLineColor: 'rgba(142,142,142,0.1)',
    plotLines: plotLines,
  },
  yAxis: {
    min: -1,
    max: 17,
    startOnTick: false,
    endOnTick: false,
    ticks: {
      stepSize: 1,
    },
    title: {
      enabled: false,
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 1,
    gridLineColor: 'rgba(142,142,142,0.1)',
    plotLines: plotLines,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: '',
      type: 'scatter',
      color: 'white',
      data: [],
      dataLabels: {
        enabled: true,
        padding: 5,
        borderRadius: '50%',
        backgroundColor: 'white',
        shape: 'roundCircle',
        y: 10,
        style: {
          color: '#7b7b78',
          textOutline: 'none',
          fontSize: '14px',
        },
        format: '',
      },
    },
  ],
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
};
