import { Box, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { AllUsersList, ToolsList } from './components';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 30,
    width: 'calc(100% - 20%)',
    padding: '0px 10% 20px',
  },
}));

export const AdminPanel: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <ToolsList />
      <AllUsersList />
    </Box>
  );
};

export default AdminPanel;
