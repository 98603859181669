import { Box, makeStyles } from '@material-ui/core';
import * as Highcharts from 'highcharts';
import React, { FC, useEffect } from 'react';
import { ColorType } from '../../types';
import { chartConfig } from './';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highchartsFigure: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    overflow: 'hidden',
  },
  chartContainer: {
    width: 525, //380,
    height: 525, //380,
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '-25%',
      left: '-25%',
      width: '80%',
      height: '80%',
      background: `transparent radial-gradient(closest-side at 50% 50%, #fff96e, #fff96d, rgba(255, 255, 255, 0)) 0% 0% no-repeat padding-box`,
    },
  },
  chartContainerGreen: {
    position: 'absolute',
    content: '""',
    top: '5%',
    right: '8%',
    width: '100%',
    height: '100%',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '-30%',
      left: '40%',
      width: '90%',
      height: '90%',
      background: `transparent radial-gradient(closest-side at 50% 50%, #39dc82, #42e084, rgba(255, 255, 255, 0)) 0% 0% no-repeat padding-box`,
    },
  },
  chartContainerBlue: {
    position: 'absolute',
    content: '""',
    top: '55%',
    left: '8%',
    width: '100%',
    height: '100%',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '-35%',
      left: '-30%',
      width: '100%',
      height: '100%',
      background: `transparent radial-gradient(closest-side at 50% 50%, #7ee6ff, #77f9ff, rgba(255, 255, 255, 0)) 0% 0% no-repeat padding-box`,
    },
  },
  chartContainerRed: {
    position: 'absolute',
    content: '""',
    top: '55%',
    right: '8%',
    width: '100%',
    height: '100%',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '-25%',
      left: '50%',
      width: '80%',
      height: '80%',
      background: `transparent radial-gradient(closest-side at 50% 50%, #ff875d, rgba(255, 135, 94, 0.73), rgba(255, 255, 255, 0)) 0% 0% no-repeat padding-box`,
    },
  },
  chartContainerTriangle: {
    position: 'absolute',
    content: '""',
    top: '0%',
    right: '0%',
    width: '100%',
    height: '100%',
    '&:before': {
      position: 'absolute',
      content: '""',
      top: '0%',
      left: '0%',
      width: '100%',
      height: '100%',
      background: `linear-gradient(to bottom right, transparent 0%, transparent 50%, rgba(37, 37, 35, 0.11) 50%, transparent 100%)`,
    },
  },
}));

type Props = {
  colors: ColorType;
  coordinates?: number[] | null;
  moduleLabel?: number;
};

export const ScatterChart: FC<Props> = ({
  colors,
  coordinates,
  moduleLabel = 1,
}) => {
  const classes = useStyles();
  let config = JSON.parse(JSON.stringify(chartConfig));
  config.series[0].data = coordinates;
  config.series[0].dataLabels.format = `${moduleLabel}`;

  useEffect(() => {
    Highcharts.chart('container', config);
    Highcharts.SVGRenderer.prototype.symbols.roundCircle = (
      x: number,
      y: number,
      w: number,
      h: number
    ) => {
      let width = w,
        height = h,
        newX = x;
      if (height > width) {
        width = height;
        newX -= (width - w) / 2;
      } else if (width > height) {
        height = width;
      }
      // @ts-ignore
      return Highcharts.SVGRenderer.prototype.symbols.circle(
        newX,
        y,
        width,
        height
      );
    };
    setTimeout(() => {
      let el: any = document.querySelector('.highcharts-xaxis');
      if (el) {
        el.style.visibility = 'hidden';
      }
    }, 10);
  }, [config]);

  return (
    <Box className={classes.container}>
      <figure className={classes.highchartsFigure}>
        <div className={classes.chartContainerGreen}></div>
        <div className={classes.chartContainerBlue}></div>
        <div className={classes.chartContainerRed}></div>
        <div className={classes.chartContainerTriangle}></div>
        <div id="container" className={classes.chartContainer}></div>
      </figure>
    </Box>
  );
};
