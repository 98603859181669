import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '../../assets/expandGray.svg';
import React, { FC, useState, useEffect } from 'react';
import { getSubmodulesAPI } from '../../api';
import BadgeGreen from '../../assets/badgeGreen.svg';
import BadgeGrey from '../../assets/badgeGrey.svg';
import { ToolSubmodule } from './ToolSubmodule';
import { getFormattedDate, isCoach } from '../../utils';

const useStyles = makeStyles((theme) => ({
  accordionModule: {
    width: '100%',
    background: '#7b7b78',
    border: 0,
    boxShadow: '0 2px 8px 0 rgba(57, 64, 82, 0.1)',
    borderRadius: 10,
  },
  accordionSummaryModule: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    height: 70,
  },
  accordionHeadingModule: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
    flex: '1 1 0',
  },
  accordionHeadingModuleLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  accordionHeadingModuleHeading: {
    color: '#8e8e8e',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'bold',
    marginLeft: '20px',
  },
  accordionDetailsModule: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordionHeadingCoachingExpandMore: {
    width: 25,
    transform: 'rotate(180deg)',
  },

  practicalModuleNumber: {
    backgroundColor: '#9d9d9d',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    height: 25,
    width: 25,
  },
  badgeNumber: {
    margin: 'auto',
  },
  badgeImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#9d9d9d',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  badgeCount: {
    position: 'absolute',
    top: '13px',
    fontSize: '14px',
    fontFamily: 'PT serif',
  },
  moduleFinished: {
    color: '#24c776 !important',
  },
  moduleFinishedBg: {
    backgroundColor: '#24c776 !important',
  },
  pointerNone: {
    pointerEvents: 'none',
  },
  timestamp: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 70,
    fontSize: '14px',
    color: '#9d9d9d',
    fontFamily: 'Roboto',
  },
}));

type Props = {
  moduleName: string;
  moduleNumber: number;
  moduleDone: boolean;
  moduleId: number;
  numofsubmodules: number;
  email: string;
  isClient: boolean;
  handleChangeSubmission: Function;
  prevModuleDone: boolean;
  activeModule: number;
  lastUpdated: string;
};

export const PraxisModul: FC<Props> = ({
  moduleName,
  moduleNumber,
  moduleDone,
  moduleId,
  numofsubmodules,
  email,
  isClient,
  handleChangeSubmission,
  prevModuleDone,
  activeModule,
  lastUpdated,
}) => {
  const classes = useStyles();
  const [practiceModuleDone, setPracticeModuleDone] = useState(moduleDone);

  const [submodules, setSubmodules] = useState([]);
  const [submodulesDone, setSubmodulesDone] = useState<number>(0);
  const [lastSubmodule, setLastSubmodule] = useState(
    numofsubmodules === 1 ? true : false
  );

  useEffect(() => {
    if (prevModuleDone) getSubmodules(moduleId, false);
  }, [prevModuleDone]);

  const handleSubmit = () => {
    getSubmodules(moduleId, true);
  };

  const getSubmodules = async (moduleId: number, afterUpdate: boolean) => {
    let payload = {
      email: email,
      course: 1,
      module: moduleId,
    };

    try {
      const res = await getSubmodulesAPI(payload);
      setSubmodules(res.data);
      updateModuleDone(afterUpdate, res.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  const updateModuleDone = (afterUpdate: boolean, submodulesArray: any) => {
    let newValue = 0;
    submodulesArray.map((submodule: any) => {
      if (submodule.submodule_done) {
        newValue += 1;
      }
    });
    setSubmodulesDone(newValue);
    setSubmodulesDone((state) => {
      checkModuleDone(state, afterUpdate);
      return state;
    });
  };

  const checkModuleDone = (numSubmodulesDone: number, afterUpdate: boolean) => {
    if (numofsubmodules - 1 === numSubmodulesDone) {
      setLastSubmodule(true);
    }
    if (afterUpdate && numofsubmodules === numSubmodulesDone) {
      submitFinal(moduleId);
    }
  };

  const submitFinal = (moduleId: number) => {
    handleChangeSubmission(moduleId);
    setPracticeModuleDone(true);
  };

  return (
    <Accordion
      className={`${classes.accordionModule} ${
        moduleNumber > activeModule ? classes.pointerNone : null
      }`}
      square={true}
    >
      <AccordionSummary
        expandIcon={
          <img
            className={classes.accordionHeadingCoachingExpandMore}
            src={ExpandMoreIcon}
            alt="expand-icon"
          />
        }
        aria-controls="status"
        id="status"
        className={classes.accordionSummaryModule}
      >
        <Box className={classes.accordionHeadingModule}>
          <Box className={classes.accordionHeadingModuleLeft}>
            <Box
              component="span"
              className={`
                ${classes.practicalModuleNumber}
                ${practiceModuleDone ? classes.moduleFinishedBg : ''}
                `}
            >
              <Box component="span" className={classes.badgeNumber}>
                {moduleNumber}
              </Box>
            </Box>
            <Typography
              className={`
                    ${classes.accordionHeadingModuleHeading}
                    ${practiceModuleDone ? classes.moduleFinished : ''}
                    `}
              data-testid="module-name"
            >
              {moduleName}
            </Typography>
          </Box>
          <Box
            className={`${classes.badgeImageContainer} ${
              practiceModuleDone ? classes.moduleFinished : ''
            }`}
          >
            <img
              data-testid="badge"
              alt="Badge"
              src={practiceModuleDone ? BadgeGreen : BadgeGrey}
              width="40"
              height="60"
            />
            <Box
              component="span"
              className={classes.badgeCount}
              data-testid="module-number"
            >
              {moduleNumber}
            </Box>
          </Box>
          {moduleDone && isCoach() && lastUpdated && (
            <Box className={classes.timestamp}>
              {getFormattedDate(lastUpdated)}
            </Box>
          )}
          <Box></Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsModule}>
        {submodules.map((submodule: any, index: number) => (
          <ToolSubmodule
            key={index}
            moduleId={moduleId}
            submoduleId={submodule.id}
            submoduleName={submodule.name}
            toolId={submodule.tool_id}
            email={email}
            isClient={isClient}
            submoduleDone={submodule.submodule_done}
            handleChangeSubmission={handleSubmit}
            lastSubmodule={lastSubmodule}
            taskProgress={submodule.task_progress}
            lastUpdated={submodule.last_updated}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
