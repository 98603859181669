import { useMemo, FC } from 'react';
import { useAuthDispatch } from '../providers';
import { jsonApi } from '.';

export const WithAxios: FC<any> = ({ children }) => {
  const { onLogout }: any = useAuthDispatch();

  useMemo(() => {
    jsonApi.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        return new Promise((resolve, reject) => {
          if (error.response.status === 401) {
            onLogout(
              'Deine Sitzung ist abgelaufen. Bitte melde Dich erneut an.'
            );
            return reject(error);
          }
          return reject(error);
        });
      }
    );
  }, []);

  return children;
};
