import { makeStyles, Box } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'left',
    width: 'calc(100% - 30%)',
    padding: '0px 10% 20px',
  },
  text: {
    letterSpacing: 0.54,
    color: '#3e3e3e',
    lineHeight: 1.7,
    fontSize: 14,
    '& p:first-child': {
      marginTop: 0,
    },
  },
  headline: {
    fontWeight: 700,
  },
}));

export const TermsOfUse: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={`${classes.headline} ${classes.text}`}>
        Nutzungsbedingungen
      </Box>
      <Box className={classes.text}>
        <p>
          Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
          Datenschutz hat einen besonders hohen Stellenwert für die
          Geschäftsleitung der Golden Gate, Baldermann&amp;Okusluk GbR. Eine
          Nutzung der Internetseiten der Golden Gate, Baldermann&amp;Okusluk GbR
          ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich.
          Sofern eine betroffene Person besondere Services unseres Unternehmens
          über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch
          eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die
          Verarbeitung personenbezogener Daten erforderlich und besteht für eine
          solche Verarbeitung keine gesetzliche Grundlage, holen wir generell
          eine Einwilligung der betroffenen Person ein.
        </p>
        <p>
          Die Verarbeitung personenbezogener Daten, beispielsweise des Namens,
          der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
          Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
          und in Übereinstimmung mit den für die Golden Gate,
          Baldermann&amp;Okusluk GbR geltenden landesspezifischen
          Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte
          unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der
          von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
          informieren. Ferner werden betroffene Personen mittels dieser
          Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
        </p>
      </Box>
      <Box className={`${classes.headline} ${classes.text}`}>
        1. Begriffsbestimmungen
      </Box>
      <Box className={classes.text}>
        <p>
          Die Datenschutzerklärung der Golden Gate, Baldermann&amp;Okusluk GbR
          beruht auf den Begrifflichkeiten, die durch den Europäischen
          Richtlinien- und Verordnungsgeber beim Erlass der
          Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
          Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für
          unsere Kunden und Geschäftspartner einfach lesbar und verständlich
          sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten
          Begrifflichkeiten erläutern.
        </p>
        <p>
          Wir verwenden in dieser Datenschutzerklärung unter anderem die
          folgenden Begriffe:
        </p>
      </Box>
      <Box className={`${classes.headline} ${classes.text}`}>
        a) Personenbezogene Daten
      </Box>
      <Box className={classes.text}>
        <p>
          Personenbezogene Daten sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person (im Folgenden
          „betroffene Person“) beziehen. Als identifizierbar wird eine
          natürliche Person angesehen, die direkt oder indirekt, insbesondere
          mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
          Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
          oder mehreren besonderen Merkmalen, die Ausdruck der physischen,
          physiologischen, genetischen, psychischen, wirtschaftlichen,
          kulturellen oder sozialen Identität dieser natürlichen Person sind,
          identifiziert werden kann.
        </p>
      </Box>
    </Box>
  );
};

export default TermsOfUse;
