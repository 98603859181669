import {
  makeStyles,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  Box,
  FormHelperText,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  eyeIcon: {
    backgroundColor: 'white',
    '& .MuiButtonBase-root': {
      margin: 0,
      padding: 0,
      color: '#bcbcbc',
    },
  },
  inputLabel: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.31,
    color: '#5f5f5f',
    textAlign: 'left',
    margin: '0 10px 8px',
  },
  inputField: {
    backgroundColor: 'white',
  },
  helperText: {
    color: '#FF5F58',
    fontStyle: 'italic',
    margin: '0 10px 8px',
  },
}));

type Props = {
  label: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onInput?: React.FormEventHandler<HTMLInputElement>;
  error?: any;
  ['data-testid']?: string;
};
export const PasswordField: FC<Props> = ({
  label,
  value,
  onChange,
  onBlur,
  onInput,
  error,
  ['data-testid']: dataTestId = 'passwordfield',
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant="outlined">
      <Box className={classes.inputLabel}>{label}</Box>
      <OutlinedInput
        className={classes.inputField}
        id={`outlined-adornment-password-${label}`}
        inputProps={{ 'data-testid': `${dataTestId}` }}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onInput={onInput}
        endAdornment={
          <InputAdornment position="end" className={classes.eyeIcon}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
              data-testid="togglepassword"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && (
        <FormHelperText
          id="helper-text"
          data-testid="error"
          className={classes.helperText}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default PasswordField;
