import { makeStyles } from '@material-ui/core';

export const ResetPasswordStyles = makeStyles((theme) => ({
  textContainer: {
    marginTop: 100,
    maxWidth: 488,
    height: 180,
    width: '100%',
    background: 'rgba(14,165,84,0.5)',
    padding: theme.spacing(7, 5),
    borderRadius: 5,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  container: {
    marginTop: 100,
    maxWidth: 488,
    width: '100%',
    background: 'rgba(14,165,84,0.5)',
    padding: theme.spacing(7, 5),
    borderRadius: 5,
    boxShadow: 'none',
  },
  eyeIcon: {
    backgroundColor: 'white',
    '& .MuiButtonBase-root': {
      margin: 0,
      padding: 0,
      color: '#bcbcbc',
    },
  },
  signInText: {
    font: "500 26px/30px 'Roboto'",
    width: '100%',
    color: '#ffffff',
  },
  signInBtn: {
    background: '#5f5f5f',
    color: theme.palette.common.white,
    padding: theme.spacing(1.4, 0),
    boxShadow: 'none',
    borderRadius: 2,
    fontSize: 18,
    textTransform: 'none',
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
      boxShadow: 'none',
    },
  },
  inputFieldContainer: {
    padding: theme.spacing(0, 4),
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#9D9D9D',
    },
  },
  inputField: {
    background: theme.palette.common.white,
    borderRadius: 2,
    '&:hover': {
      background: '#eeeeee',
    },
    '&,.MuiInputBase-input:focus': {
      background: theme.palette.common.white,
    },
  },
  inputLabel: {
    color: '#9D9D9D',
  },
  helperText: {
    color: '#FF5F58',
    marginLeft: theme.spacing(-0.2),
    fontStyle: 'italic',
  },
  apiErrorMsg: {
    color: '#FF5F58',
    marginLeft: theme.spacing(-0.2),
    fontSize: '20px',
    marginTop: '10px',
  },
  alert: {
    width: 424,
    color: '#ffffff',
    margin: '24px auto',
    '& [data-content="invalid"] .MuiAlert-message': {
      flex: '1 1 0',
    },
    '& [data-content="re-requested"] .MuiAlert-message': {
      // textAlign: 'left',
      flex: '1 1 0',
    },
  },
  resetPassword: {
    textAlign: 'right',
    '& .MuiButtonBase-root': {
      color: '#5f5f5f',
    },
    '& .MuiButtonBase-root:hover': {
      color: '#3e3e3e',
      backgroundColor: 'transparent',
    },
  },
  button: {
    marginTop: '20px',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '5px',
    background: '#5f5f5f',
    padding: '15px 50px',
    color: '#fff',
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
    },
  },
  invalidPassword: {
    color: '#ff5f58',
  },
  validPassword: {
    color: '#ffffff',
  },
}));
