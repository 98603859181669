import { Box, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import SunnysideSvg from '../../assets/sunnyside.svg';
import CloudSvg from '../../assets/shadowside.svg';
import { LoginLogoutButton } from '.';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    height: '40vh',
    maxHeight: '830px',
    padding: '2rem 20%',
  },
  textContainer: {
    padding: '5% 20% 0',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    '& > div:first-child': {
      flex: '0 0 114px',
    },
    '& > div:nth-child(2)': {
      flex: '1 1 auto',
    },
    '& > div:last-child': {
      flex: '0 0 137px',
    },
  },
  descriptions: {
    font: "400 18px/28px 'Roboto'",
    textAlign: 'left',
    margin: theme.spacing(0, '30px'),
  },
  leftImg: {
    color: '#FFCE00',
    '& > img': {
      filter:
        'invert(77%) sepia(72%) saturate(1515%) hue-rotate(359deg) brightness(103%) contrast(102%)',
    },
  },
  rightImg: {
    display: 'flex',
    alignItems: 'center',
  },
  mailLink: {
    fontWeight: 700,
    color: 'inherit',
    textDecoration: 'none',
  },
}));

export const Landing: FC = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.videoContainer}>
        <iframe
          width="100%"
          height="100%"
          src="https://player.vimeo.com/video/447477812"
          title="Video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </Box>
      <Box className={classes.textContainer}>
        <Box className={classes.row}>
          <Box className={classes.leftImg}>
            <img alt="Sunshine" src={SunnysideSvg} width="114" height="114" />
          </Box>
          <Box className={classes.descriptions}>
            <span style={{ fontWeight: 700 }} data-testid="title">
              {' '}
              Hochsensibilität, Hochsensitivität, Sensitivität,
              Neurosensitivität –
            </span>
            <span data-testid="all-dies">
              {' '}
              all dies sind Begriffe, die man überall hört und derzeit in der
              populärwissenschaftlichen Literatur Hochkonjunktur erlangt haben.
              Dabei fällt auf, dass der Forschungsstand zu (Hoch-)Sensitivität
              in der deutschsprachigen Öffentlichkeit oftmals unterschätzt wird.
              Ein Hauptgrund dafür liegt darin, dass erst kürzlich eine
              Integration der verschiedenen Forschungsströme zu Sensitivität bei
              Erwachsenen, Kindern und Tieren erfolgt ist.
            </span>
          </Box>
          <div></div>
        </Box>
        <Box className={classes.row}>
          <div></div>
          <Box className={classes.descriptions} mt={4} mr={2}>
            <span data-testid="um-auf">
              Um auf die Coaching Plattform zugreifen zu können, muss Dir ein
              Zugangscode durch einen unserer Coaches geschickt werden. Wenn Du
              dies wünschst, sende uns bitte eine E-Mail an:
            </span>
            <a
              data-testid="email"
              className={classes.mailLink}
              href={'mailto:support@patricewyrsch.ch'}
            >
              {' support@patricewyrsch.ch'}
            </a>
          </Box>
          <Box className={classes.rightImg}>
            <img alt="Clouds" src={CloudSvg} width="137" height="118" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Landing;
