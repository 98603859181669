import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '../../assets/expandWhite.svg';
import { Alert } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';
import {
  EnergylevelType,
  QuestionsAndAnswersType,
  SensitivityTypescaleType,
  StatusMeinerDataType,
  VisualizationType,
} from '../../types';
import { isEmptyOrIsNull } from '../../utils';
import {
  Energylevel,
  QuestionsAndAnswers,
  SensitivityTypescale,
  StatusMeiner,
  Visualization,
} from '../common';
import { IF } from '../core';
import defaultProfilePic from '../../assets/defaultProfilePic.svg';
import { getImageAPI } from '../../api';

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%',
    background: '#7b7b78',
    border: 0,
    boxShadow: 'none',
    borderRadius: 10,
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid transparent',
    transition: 'border-color 0.1s',
    transitionDelay: '0.5s',
    height: 140,
    '&.Mui-expanded': {
      borderBottom: '1px solid rgb(255 255 255 / 25%)',
      transition: 'border-color 0.1s',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'center',
      margin: '0',
    },
  },
  expandMore: {
    color: theme.palette.common.white,
    width: 25,
    transform: 'rotate(180deg)',
  },
  heading: {
    background: '#7b7b78',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  subHeading: {
    background: '#7b7b78',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  typeHeading: {
    borderBottom: '1px solid rgb(255 255 255 / 25%)',
    paddingBottom: 10,
  },
  pageWrapper: {
    background: '#7b7b78',
    padding: '16px',
  },
  img: {
    position: 'relative',
    height: 50,
    margin: '20px 0px',
    '& > img': {
      borderRadius: '50%',
      width: 50,
      height: 50,
      objectFit: 'cover',
      position: 'relative',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 70,
      width: 70,
      backgroundColor: 'white',
      opacity: 0.7,
      borderRadius: '50%',
    },
  },
  clientContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  clientDetailsContainer: {
    fontFamily: 'Roboto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: '20px',
    margin: '20px 0',
    width: '750px',
  },
  clientDetails: {
    background: '#7b7b78',
    color: theme.palette.common.white,
    fontSize: '18px',
  },
  telLink: {
    zIndex: 2,
    color: theme.palette.common.white,
    textDecoration: 'none!important',
    marginLeft: '5px',
    '&:hover': {
      color: '#bcbcbc!important',
    },
  },
  emailLink: {
    zIndex: 2,
    color: theme.palette.common.white,
    textDecoration: 'underline!important',
    marginLeft: '5px',
    '&:hover': {
      color: '#bcbcbc!important',
    },
  },
  timestamp: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 60,
    fontSize: '14px',
    color: 'white',
    fontFamily: 'Roboto',
  },
}));

const visualizationNames: VisualizationType = {
  first: 'vantage',
  second: 'generell',
  third: 'gering',
  fourth: 'vulnerabel',
};

type Props = {
  coordinates: any;
  activeModule?: number;
  sensitivityTypescaleData: SensitivityTypescaleType | null;
  energylevelData: EnergylevelType | null;
  circlesData: StatusMeinerDataType | null;
  questionsAndAnswersData: QuestionsAndAnswersType | null;
  isCoach: boolean;
  onUpdateSensitivityState: Function;
  apiErrorMsg?: string;
  fokus: Array<boolean> | null;
  editDashboard: Function;
  modules: Array<any>;
  editModulePosition?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  image?: any;
  nickname?: string;
  telephone?: string;
  c_date: string;
};

export const Sensitivity: FC<Props> = ({
  coordinates,
  activeModule,
  sensitivityTypescaleData,
  energylevelData,
  circlesData,
  questionsAndAnswersData,
  isCoach,
  onUpdateSensitivityState,
  apiErrorMsg,
  fokus,
  editDashboard,
  modules,
  editModulePosition,
  email,
  firstName,
  lastName,
  image,
  nickname,
  telephone,
  c_date,
}) => {
  const classes = useStyles();
  const [profilePic, setProfilePic] = useState<string>(defaultProfilePic);

  useEffect(() => {
    if (image) {
      getImage(image);
    }
  }, [image]);

  const getImage = async (path: string) => {
    const payload = {
      path: path,
    };
    try {
      const res = await getImageAPI(payload);
      let imageStr = Buffer.from(res.data, 'binary').toString('base64');
      var imgsrc = 'data:image/png;base64,' + imageStr;
      setProfilePic(imgsrc);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Accordion defaultExpanded className={classes.accordion} square={true}>
      <AccordionSummary
        expandIcon={
          <img
            className={classes.expandMore}
            src={ExpandMoreIcon}
            alt="expand-icon"
          />
        }
        aria-controls="status"
        id="status"
        className={classes.accordionSummary}
      >
        <Box>
          {isCoach && c_date && (
            <Box className={classes.timestamp}>
              {'Test absolviert: '}
              {c_date}
            </Box>
          )}
          <Box className={classes.clientContainer}>
            <IF condition={!isCoach}>
              <Typography
                className={classes.heading}
                data-testid="sensitivity-title"
              >
                Status Der Sensitivität
              </Typography>
            </IF>
            <IF condition={isCoach}>
              <Box>
                <div className={classes.img}>
                  <img src={profilePic} />
                </div>
              </Box>
              <Box className={classes.clientDetailsContainer}>
                <Box className={classes.clientDetails}>{firstName}</Box>
                <Box className={classes.clientDetails}>{lastName}</Box>

                <Box
                  className={classes.clientDetails}
                  onClick={(e) => e.stopPropagation()}
                >
                  {!isEmptyOrIsNull(telephone) && (
                    <a className={classes.telLink} href={`tel:${telephone}`}>
                      Tel: {telephone}
                    </a>
                  )}
                </Box>
                <Box
                  className={classes.clientDetails}
                  onClick={(e) => e.stopPropagation()}
                >
                  {' '}
                  <a className={classes.emailLink} href={`mailto:${email}`}>
                    {email}
                  </a>
                </Box>
              </Box>
            </IF>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className={classes.pageWrapper} container>
          <Grid item xs={12}>
            <Typography
              className={`${classes.subHeading} ${classes.typeHeading}`}
            >
              Sensitivitätstyp
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Visualization
              visualizationNames={visualizationNames}
              coordinates={coordinates}
              activeModule={activeModule}
              editDashboard={editDashboard}
              modules={modules}
              editModulePosition={editModulePosition}
            />
          </Grid>
          <Grid item xs={5}>
            <SensitivityTypescale
              sensitivityTypescaleData={sensitivityTypescaleData}
            />
            <Energylevel energylevelData={energylevelData} />
          </Grid>
          <Grid item xs={12}>
            <StatusMeiner circlesData={circlesData} />
          </Grid>
          <Grid item xs={12}>
            <QuestionsAndAnswers
              questionsAndAnswersData={questionsAndAnswersData}
              isCoach={isCoach}
              onUpdateSensitivityState={onUpdateSensitivityState}
              fokus={fokus}
              activeModule={activeModule}
              editModulePosition={editModulePosition}
            />
          </Grid>
          <IF condition={!isEmptyOrIsNull(apiErrorMsg)}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Alert severity="error">{apiErrorMsg}</Alert>
              </Box>
            </Grid>
          </IF>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
