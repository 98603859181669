import React, { FC, useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  DialogContent,
  Box,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Tool from './Tool';
import { ToolType } from '../../../../types';
import toolIcon from '../../../../assets/toolGreen.svg';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
  popupContainer: {
    margin: 'auto 10px',
  },
  addButton: {
    color: theme.palette.common.white,
    textTransform: 'none',
    '&.MuiButton-root:hover': {
      backgroundColor: '#7b7b78',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  popup: {
    '& .MuiDialog-container .MuiPaper-root': {
      backgroundColor: '#7b7b78',
      width: '75%',
      height: '80%',
      maxWidth: 'unset',
      color: '#f8f8f8',
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'flex-end',
      marginRight: 6,
    },
  },
  topMenuContainer: {},
  topMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #9d9d9d',
    padding: '24px 0 20px 0',
    margin: '0 24px',
  },
  headlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headline: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginRight: 20,
  },
  subheadline: {
    fontStyle: 'italic',
    letterSpacing: 0.8,
  },
  selectedToolsContainer: {
    overflow: 'scroll',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 220,
  },
  selectedTool: {
    display: 'flex',
    fontFamily: 'Roboto',
    padding: '20px 0',
    margin: '0 24px',
    borderBottom: '1px solid #9d9d9d',
    fontSize: 16,
    justifyContent: 'space-between',
    '& > div': {
      marginRight: 30,
      display: 'flex',
    },
  },
  selectedToolsHeadline: {
    fontWeight: 'bold',
    marginRight: 40,
    width: 210,
  },
  selectedToolsDescription: {
    maxWidth: 640,
  },
  toolsIcon: {
    width: 30,
    height: 20,
    marginRight: 10,
  },
  closeButton: {
    backgroundColor: '#9d9d9d',
    textTransform: 'none',
    padding: '10px 30px',
    '& > span': {
      color: '#f8f8f8',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(157,157,157,0.7)',
    },
  },
  saveButton: {
    color: '#f8f8f8',
    padding: '10px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&.MuiButton-root:hover': {
      backgroundColor: '#9d9d9d',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  saveButtonIcon: {
    fontSize: 16,
    cursor: 'pointer',
    backgroundColor: '#24c776',
    borderRadius: '50%',
    marginLeft: '10px',
    padding: '5px',
  },
  addIcon: {
    background: '#24c776',
    borderRadius: '50%',
    color: '#f8f8f8',
    padding: 5,
    fontSize: 18,
    marginLeft: 5,
  },
  deleteIcon: {
    borderRadius: '50%',
    color: '#f8f8f8',
    background: '#9d9d9d',
    cursor: 'pointer',
    transform: 'rotate(45deg)',
  },
  closeModalIcon: {
    color: '#f8f8f8',
    cursor: 'pointer',
    transform: 'rotate(45deg)',
    width: 34,
    height: 34,
    marginRight: -5,
  },
  moduleSubmitted: {
    opacity: 0.7,
  },
  disabled: {
    cursor: 'default',
  },
  hidden: {
    display: 'none',
  },
}));

type Props = {
  moduleDone: boolean;
  onChange: Function;
  allToolsList: any;
  selectedToolsData: Array<number>;
};

export const ToolsListPopup: FC<Props> = ({
  moduleDone,
  onChange,
  allToolsList,
  selectedToolsData,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedTools, setSelectedTools] = useState<ToolType[]>([]);
  const [selectedToolsList, setSelectedToolsList] =
    useState<Array<number>>(selectedToolsData);

  useEffect(() => {
    setSelectedToolsList(selectedToolsData);
  }, [selectedToolsList, selectedToolsData]);

  const handleClickOpen = () => {
    setOpen(true);
    updateSelectedToolsList(selectedToolsList);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    handleClose();
    let toolIds: Array<number> = [];
    selectedTools.map((el: any) => {
      toolIds.push(el.id);
    });
    setSelectedToolsList(toolIds);
    onChange(toolIds);
  };

  const handleSelectedTools = (tool: ToolType, selected: boolean) => {
    if (selected) {
      let newArr = [...selectedTools];
      newArr.push(tool);
      setSelectedTools(newArr);
    } else {
      let newArr = [...selectedTools];
      let index = newArr.indexOf(tool);
      newArr.splice(index, 1);
      setSelectedTools(newArr);
    }
  };

  const updateSelectedToolsList = (selectedToolsList: Array<number>) => {
    const newList = selectedTools.filter((tool) =>
      selectedToolsList.includes(tool.id)
    );
    setSelectedTools(newList);
  };

  const inSelectedToolsList = (tool: ToolType) => {
    let inList: boolean = false;
    selectedTools.map((selectedTool) => {
      if (selectedTool.id == tool.id) inList = true;
      return;
    });
    return inList;
  };

  return (
    <div
      className={`${classes.popupContainer} ${
        moduleDone ? classes.hidden : null
      }`}
    >
      <Button
        className={`${classes.addButton} ${
          moduleDone ? classes.disabled : null
        }`}
        onClick={!moduleDone ? handleClickOpen : undefined}
      >
        <Typography>Tool hinzufügen</Typography>
        <AddIcon data-testid="add-tools" className={classes.addIcon} />
      </Button>
      <Dialog
        className={classes.popup}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.topMenuContainer}>
          <Box className={classes.topMenu}>
            <Box className={classes.headlineContainer}>
              <Box className={classes.headline}>TOOLS</Box>
              {selectedTools.length === 0 ? (
                <Box className={classes.subheadline}>
                  Bitte triff eine Auswahl
                </Box>
              ) : null}
            </Box>
            <AddIcon className={classes.closeModalIcon} onClick={handleClose} />
          </Box>
          <Box className={classes.selectedToolsContainer}>
            {selectedTools.map((tool: ToolType, index: number) => (
              <Box key={index} className={classes.selectedTool}>
                <Box>
                  <img
                    className={classes.toolsIcon}
                    src={toolIcon}
                    alt="tool-icon"
                  />
                  <Box className={classes.selectedToolsHeadline}>
                    {tool.name}
                  </Box>
                  <Box className={classes.selectedToolsDescription}>
                    {tool.tool_description}
                  </Box>
                </Box>
                <AddIcon
                  data-testid="delete-selected-toolofpopup"
                  className={classes.deleteIcon}
                  onClick={() => handleSelectedTools(tool, false)}
                />
              </Box>
            ))}
          </Box>
          {selectedTools.length !== 0 ? (
            <DialogActions>
              <Button
                data-testid="speichern-tools"
                className={classes.saveButton}
                onClick={handleSelect}
              >
                <Typography>speichern</Typography>
                <CloudUploadIcon className={classes.saveButtonIcon} />
              </Button>
            </DialogActions>
          ) : null}
        </Box>
        <DialogContent>
          {allToolsList.map((el: any, index: number) => {
            return (
              <Tool
                key={index}
                toolData={el}
                onCheckChange={handleSelectedTools}
                checked={inSelectedToolsList(el)}
                adminView={false}
                handleDeleteOrEditTool={() => {}}
              />
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ToolsListPopup;
