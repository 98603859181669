import { makeStyles, Box, FilledInput } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { UserType } from '../../../types';

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: '0',
    height: '40px',
    background: theme.palette.common.white,
    borderRadius: 2,
    '& .MuiFilledInput-input': {
      padding: '10px',
    },
    '&:hover': {
      background: '#eeeeee',
    },
    '&,.MuiInputBase-input:focus': {
      background: theme.palette.common.white,
    },
  },
}));

type Props = {
  users: Array<UserType>;
  onSearch: Function;
};
export const UserSearch: FC<Props> = ({ users, onSearch }) => {
  const classes = useStyles();

  const [searched, setSearched] = useState<string>('');
  const [usersList, setUsersList] = useState<Array<UserType>>([]);

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  const handleSearchField = (value: any) => {
    setSearched(value);
    const filteredRows = users.filter((user) => {
      return user.email.toLowerCase().includes(value.toLowerCase());
    });
    onSearch(filteredRows);
  };

  return (
    <Box>
      <FilledInput
        disableUnderline
        type="search"
        placeholder="Nutzer suchen"
        className={classes.inputField}
        value={searched}
        onChange={(e: any) => handleSearchField(e.target.value)}
      />
    </Box>
  );
};

export default UserSearch;
