export const API_ENDPOINTS = {
  ACCESS_CODE: 'access',
  CREATE_ACCOUNT_COACH: 'v1/coaches',
  CREATE_ACCOUNT_CLIENT: 'v1/clients',
  COACH_ME_LIST: 'v1/coaches/me/list',
  COACH_ME_LIST_CLIENT_RESULTS: 'v1/coaches/me/list/client/',
  POST_REFLECTIONS_CLIENT: 'v1/submodule/client/',
  GET_REFLECTIONS_CLIENT: 'v1/submodule/client',
  TOOLS_LIST: '/v1/tools/list',
  UNUSED_TOOL_EDIT: 'v1/tools/update',
  UNUSED_TOOL_DELETE: '/v1/tools/delete',
  MODULES_DASHBOARD_UPDATE: 'v1/modules/dashboard_update',
  TOOLS_UPLOAD: '/v1/tools/upload',
  COURSE_MODULES: '/v1/modules/get_modules',
  CLOSE_MODULE: 'v1/modules/close',
  CLOSE_SUBMODULE: '/v1/submodule/closed',
  POST_NEXT_STEPS: 'v1/submodule/client/next/',
  GET_NEXT_STEPS: 'v1/submodule/client/next',
  GET_SUBMODULE_DONE: 'v1/submodule/done',
  GET_TOOL: 'v1/tools/get',
  ADD_TOOLS_TO_MODULE: 'v1/submodule/client/tools',
  GET_PRACTICAL_MODULE: 'v1/modules/get_practical',
  SAVE_TASK_PROGRESS: 'v1/submodule/task_progress',
  GET_ALL_USERS: 'v1/users',
  GET_MODULE_DASHBOARD: 'v1/modules/dashboard',
  HARD_DELETE_USER: '/v1/users/delete',
  GET_COACH_ME: '/v1/coaches/me',
  EDIT_COACH_PROFILE: '/v1/coaches/edit',
  UPLOAD_PROFILE_PICTURE: 'v1/users/upload_image',
  GET_LEADS: 'v1/coaches/me/list/lead',
  TEST: '/test',
  CHANGE_PASSWORD: 'v1/users/change_password',
  GET_CLIENT_ME: 'v1/clients/me',
  EDIT_CLIENT_PROFILE: 'v1/clients/edit',
  GET_OWN_USER: 'v1/users/me',
  DOWNLOAD_IMAGE_FILE: 'v1/download_file',
  FORGOT_PASSWORD: 'v1/users/forgot_password',
  RESET_PASSWORD: 'v1/users/restore_password/',
  CONFIRM_REGISTRATION: 'v1/user/confirm_email/',
  CONFIRM_TEST: 'v1/clients/confirm_email_test/',
  RESEND_CONFIRMATION: 'v1/users/resend_confirmation',
  COACH_CLIENT_PROFILE: 'v1/coaches/client_profile/',
};
