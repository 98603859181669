import { Box, FilledInput, IconButton, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { FC, Fragment } from 'react';
import { FilterType, SortOrderType } from '../../../../types';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px 0',
    textAlign: 'left',
  },
  itemsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  icon: {
    fontSize: 32,
    cursor: 'pointer',
  },
  iconPlus: {
    color: '#24c776',
  },
  inputField: {
    margin: '0',
    height: '40px',
    background: theme.palette.common.white,
    borderRadius: 2,
    '& .MuiFilledInput-input': {
      padding: '10px',
    },
    '&:hover': {
      background: '#eeeeee',
    },
    '&,.MuiInputBase-input:focus': {
      background: theme.palette.common.white,
    },
  },
}));

type Props = {
  sortOrder: SortOrderType;
  handleChangeSort: Function;
  handleChangeFilter?: Function;
  showThreeDotsMenu?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
};

export const ActionPanel: FC<Props> = ({
  sortOrder,
  handleChangeSort,
  handleChangeFilter,
  showThreeDotsMenu = false,
  onChange,
  value,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(null);
  };

  return (
    <Box className={classes.itemsContainer}>
      <Box></Box>
      {sortOrder === SortOrderType.ASC ? (
        <IconButton
          data-testid="arrowup"
          onClick={() => handleChangeSort(SortOrderType.DESC)}
          title="Datum aufsteigend"
        >
          <ArrowUpwardIcon className={classes.icon} />
        </IconButton>
      ) : (
        <IconButton
          data-testid="arrowdown"
          onClick={() => handleChangeSort(SortOrderType.ASC)}
          title="Datum absteigend"
        >
          <ArrowDownwardIcon className={classes.icon} />
        </IconButton>
      )}
      {showThreeDotsMenu && (
        <Fragment>
          <IconButton
            data-testid="menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon className={classes.icon} />
          </IconButton>
          <Menu
            anchorEl={isOpen}
            keepMounted
            open={Boolean(isOpen)}
            onClose={handleClose}
            disableScrollLock={true}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onKeyDown={(e) => e.stopPropagation()}>
              <FilledInput
                inputProps={{ 'data-testid': 'search' }}
                disableUnderline
                type="search"
                placeholder="Suche nach Klienten..."
                className={classes.inputField}
                value={value}
                onChange={onChange}
              />
            </MenuItem>
            <MenuItem
              data-testid="all"
              onClick={() => {
                handleChangeFilter && handleChangeFilter(FilterType.ALL);
                handleClose();
              }}
            >
              Alle
            </MenuItem>
            <MenuItem
              data-testid="older-than-3-months"
              onClick={() => {
                handleChangeFilter &&
                  handleChangeFilter(FilterType.OLDER_THAN_3_MONTHS);
                handleClose();
              }}
            >
              Älter als drei Monate
            </MenuItem>
          </Menu>
        </Fragment>
      )}
      {/* <IconButton data-testid="add">
        <AddCircleIcon className={`${classes.icon} ${classes.iconPlus}`} />
      </IconButton> */}
    </Box>
  );
};
