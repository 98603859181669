import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { SensitivityTypescaleType } from '../../types/SensitivityTypescaleType';

const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 300,
    letterSpacing: 0.72,
    textAlign: 'left',
    marginBottom: 50,
    color: 'white',
  },
  subContainer: {
    paddingRight: 50,
    '& > div': {
      borderBottom: '1px solid white',
      padding: '12px 12px 12px 24px',
    },
    '&:nth-child(2)': {
      marginTop: 40,
    },
  },
  headline: {
    fontWeight: 'bold',
    padding: 19.5,
    textAlign: 'center',
  },
  sensitivityType: {
    fontWeight: 'bold',
  },
  selected: {
    fontWeight: 'bold',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      height: 0,
      width: 0,
      transform: 'translateY(-50%) rotate(360deg)',
      borderStyle: 'solid',
      borderWidth: '7px 0 7px 13px',
      borderColor: 'transparent transparent transparent white',
    },
  },
}));

type Props = {
  sensitivityTypescaleData: SensitivityTypescaleType | null;
};

const initialData: SensitivityTypescaleType = {
  neurotype: '',
  auspraegung: '',
};

export const SensitivityTypescale: FC<Props> = ({
  sensitivityTypescaleData,
}) => {
  const classes = useStyles();
  const data: SensitivityTypescaleType =
    sensitivityTypescaleData || initialData;

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.headline}>Typ</div>
        <div
          data-testid="vantage"
          className={data.neurotype === 'vantage' ? classes.selected : ''}
        >
          vantage-sensitiv
        </div>
        <div
          data-testid="generell"
          className={data.neurotype === 'generell' ? classes.selected : ''}
        >
          generell-sensitiv
        </div>
        <div
          data-testid="gering"
          className={data.neurotype === 'gering' ? classes.selected : ''}
        >
          gering-sensitiv
        </div>
        <div
          data-testid="vulnerabel"
          className={data.neurotype === 'vulnerabel' ? classes.selected : ''}
        >
          vulnerabel-sensitiv
        </div>
      </div>
      <div className={classes.subContainer}>
        <div className={classes.headline}>Ausprägung</div>
        <div
          data-testid="stark-erhöht"
          className={
            data.auspraegung === 'stark erhöht' ? classes.selected : ''
          }
        >
          stark erhöht
        </div>
        <div
          data-testid="leicht-erhöht"
          className={
            data.auspraegung === 'leicht erhöht' ? classes.selected : ''
          }
        >
          leicht erhöht
        </div>
        <div
          data-testid="leicht-verringert"
          className={
            data.auspraegung === 'leicht verringert' ? classes.selected : ''
          }
        >
          leicht verringert
        </div>
        <div
          data-testid="stark-verringert"
          className={
            data.auspraegung === 'stark verringert' ? classes.selected : ''
          }
        >
          stark verringert
        </div>
      </div>
    </div>
  );
};

export default SensitivityTypescale;
