import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { AuthProvider } from './providers';
import { WithAxios } from './utils';

ReactDOM.render(
  <AuthProvider>
    <WithAxios>
      <App />
    </WithAxios>
  </AuthProvider>,
  document.getElementById('root')
);
