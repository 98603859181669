import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkAccessCode } from '../api';
import { USER_TYPE } from '../utils';
import { objToQueryString } from '../utils/functions';
import SunnysideSvg from '../assets/sunLanding.svg';
import CloudSvg from '../assets/shadowLanding.svg';
import { IF } from '../components/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
  },
  topContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    background: 'rgba(255,255,255,0.5)',
    justifyContent: 'space-between',
  },
  sunImage: {
    transform: 'scaleX(-1)',
    margin: 'auto 0',
    maxHeight: 270,
    width: 205,
    '& > img': {
      maxHeight: 280,
    },
  },
  cloudImage: {
    transform: 'translateX(90px) scaleX(-1)',
    margin: 'auto 0',
    maxHeight: 270,
    width: 205,
    '& > img': {
      maxHeight: 280,
    },
  },
  descriptions: {
    width: '30%',
    font: "400 18px/28px 'Roboto'",
    textAlign: 'center',
    margin: '40px auto',
  },
  leftImg: {
    color: '#FFCE00',
    '& > img': {
      filter:
        'invert(77%) sepia(72%) saturate(1515%) hue-rotate(359deg) brightness(103%) contrast(102%)',
    },
  },
  rightImg: {
    display: 'flex',
    alignItems: 'center',
  },
  mailLink: {
    textDecoration: 'none',
    color: '#0ea554',
  },
  textContainer: {
    padding: '0% 20%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    '& > div:first-child': {
      flex: '0 0 114px',
    },
    '& > div:nth-child(2)': {
      flex: '1 1 auto',
    },
    '& > div:last-child': {
      flex: '0 0 137px',
    },
  },
  accessCodeAndButtonContainer: {
    maxWidth: 480,
    width: '100%',
    margin: '22px 0',
    zIndex: 2,
    position: 'relative',
  },
  accessCodeButton: {
    padding: theme.spacing(4, 0),
    boxShadow: 'none',
    borderRadius: 10,
    background: '#24C776',
    border: '1px solid #707070',
    '&:hover': {
      background: '#24C776',
      boxShadow: 'none',
    },
  },
  accessCodeButtonActive: {
    padding: theme.spacing(4, 0),
    maxWidth: 350,
    fontWeight: 700,
    boxShadow: 'none',
    borderRadius: 10,
    margin: 'auto',
    background: '#24c776',
    color: '#FFFFFF',
    textTransform: 'none',
    font: 'normal normal normal 28px/26px Source Sans Pro',
    letterSpacing: 'unset',
    '&:hover': {
      background: '#0EA554',
      boxShadow: 'none',
    },
    '&:disabled': {
      color: '#FFFFFF',
      background: '#24C776',
      '&> span': {
        font: 'normal normal normal 28px/26px Source Sans Pro',
      },
    },
  },
  accessCodeValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgba(95,95,95,0.9)',
    height: 250,
    borderRadius: 10,
    borderColor: '#707070',
    padding: '20px',
  },
  accessCodeHeading: {
    color: 'white',
    fontFamily: 'PTSerif',
    fontSize: 28,
    marginTop: 20,
  },
  accessCodeTextField: {
    marginTop: 70,
    '&,.MuiInput-underline:before': {
      borderBottom: '1px solid #ffffff',
    },
    '&,.MuiInput-underline:after': {
      borderBottom: '1px solid #ffffff33',
    },
    '&,.MuiInputBase-input': {
      color: 'white',
      textAlign: 'center',
    },
  },
  accessCodeContainer: {
    width: 300,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  accessCodeText: {
    font: "400 18px/28px 'Source Sans Pro'",
    paddingTop: theme.spacing(2),
    color: 'white',
  },
  errorText: {
    font: "400 18px/28px 'Source Sans Pro'",
    color: '#FD5F58',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  error: {
    position: 'absolute',
    left: '50%',
    top: '20%',
    width: '80%',
    transform: 'translateX(-50%)',
  },
}));

export const LandingPage: FC = () => {
  const classes = useStyles();
  let history = useHistory();

  const [showButton, setShowButton] = useState(false);

  const [apiErrorMsg, setApiErrorMsg] = useState('');

  const [accessCode, setAccessCode] = useState('');

  const handleChange = async (event: any) => {
    setAccessCode(event.target.value);
    setApiErrorMsg('');

    const qs = objToQueryString({ access_code: event.target.value });

    try {
      const res = await checkAccessCode(qs, {});
      localStorage.setItem(USER_TYPE, res.data.type);
      setShowButton(true);
    } catch (err: any) {
      console.log(err);
      setApiErrorMsg(err.response.data.detail);
    }
  };

  const submit = async () => {
    history.push('/create-account');
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.topContainer}>
        <Box className={classes.sunImage}>
          <img alt="Sunshine" src={SunnysideSvg} />
        </Box>

        <Box className={classes.accessCodeAndButtonContainer}>
          {apiErrorMsg && (
            <Box className={classes.error} mt={3} mb={3}>
              <Alert severity="error">{apiErrorMsg}</Alert>
            </Box>
          )}

          <Box className={classes.accessCodeValue}>
            <IF condition={!showButton}>
              <Box className={classes.accessCodeHeading} data-testid="welcome">
                Herzlich willkommen!
              </Box>

              <Box className={classes.accessCodeContainer}>
                <TextField
                  inputProps={{ 'data-testid': 'accessCode' }}
                  className={classes.accessCodeTextField}
                  fullWidth
                  color="primary"
                  margin="dense"
                  value={accessCode}
                  onChange={handleChange}
                  placeholder={'Bitte gib Deinen Zugangscode ein'}
                />
              </Box>
            </IF>
            <IF condition={showButton}>
              <Button
                fullWidth
                data-testid="register"
                variant="contained"
                color="primary"
                className={classes.accessCodeButtonActive}
                onClick={submit}
              >
                weiter zur Registrierung
              </Button>
            </IF>
          </Box>
        </Box>

        <Box className={classes.cloudImage}>
          <img alt="Clouds" src={CloudSvg} />
        </Box>
      </Box>

      <Box className={classes.descriptions} mt={4} mr={2}>
        <div data-testid="accesscode-text">
          Um auf die Coaching Plattform zugreifen zu können, muss Dir ein
          Zugangscode durch einen unserer Coaches geschickt werden. Wenn Du dies
          wünschst, sende uns bitte eine E-Mail an:
          <a
            className={classes.mailLink}
            href={'mailto:support@patricewyrsch.ch'}
            data-testid="info-email"
          >
            {' support@patricewyrsch.ch'}
          </a>
        </div>
      </Box>
    </Box>
  );
};
