import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  DialogContent,
  Box,
  TextField,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {
  API_ENDPOINTS,
  calcDurationFromSeconds,
  calcDurationInSeconds,
  put,
} from '../../../utils';
import { ToolType } from '../../../types';
import { ConfirmationPopup } from '../../../components/common';

const useStyles = makeStyles((theme) => ({
  popupContainer: {},
  popup: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
      backgroundColor: '#f7f6f1',
      borderRadius: 5,
      color: 'white',
      textAlign: 'center',
      padding: '0 20px 20px',
      '& .MuiDialogTitle-root': {
        padding: 0,
      },
      '& .MuiDialogTitle-root .MuiTypography-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
      },
      '& .MuiDialogContent-root > p': {
        color: 'white',
        fontStyle: 'italic',
        marginBottom: 0,
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
      },
      '& .MuiDialogActions-root .MuiButtonBase-root': {
        color: 'white',
      },
    },
  },
  editContainer: {
    color: '#5f5f5f',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'left',
    '& .MuiOutlinedInput-input, & .MuiOutlinedInput-multiline': {
      backgroundColor: 'white',
      width: 350,
    },
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.17,
    letterSpacing: 0.72,
    color: '#5f5f5f',
    textAlign: 'center',
    padding: 10,
    borderBottom: '1px solid #dbdee7',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    position: 'relative',
    width: '100%',
    margin: '10px 0 20px 0',
  },
  closeIcon: {
    color: '#8e8e8e',
    transform: 'translateY(-50%) rotate(45deg)',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: '50%',
  },
  editIcon: {
    cursor: 'pointer',
    marginRight: 5,
  },
  buttonContainer: {
    marginBottom: 20,
    marginTop: 20,
  },
  closeButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #8e8e8e',
    color: '#8e8e8e',
    padding: '7px 30px',
    width: 160,
    '& > span': {
      color: '#5f5f5f',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  deleteButton: {
    backgroundColor: '#fd5f58',
    padding: '10px 30px',
    textTransform: 'uppercase',
    color: '#fcfcfc',
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(253,95,88,0.7)',
    },
  },
  saveButton: {
    backgroundColor: '#24c776',
    padding: '7px 30px',
    textTransform: 'none',
    fontWeight: 'bold',
    color: 'white',
    width: 160,
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(36, 199, 118, 0.7)',
    },
  },
  inputRow: {
    display: 'flex',
    '& .MuiBox-root': {
      marginRight: 20,
    },
  },
  durationFields: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-input': {
      width: 40,
    },
    '& .MuiOutlinedInput-root': {
      marginRight: 10,
    },
  },
  inputFieldContainer: {
    position: 'relative',
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiInputBase-root, & .MuiOutlinedInput-input': {
        width: '100%',
      },
    },
  },
  stepsFields: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-multiline': {
      width: 120,
      minHeight: 90,
    },
  },
}));

type Props = {
  toolData: ToolType;
  handleDeleteOrEditTool: Function;
};

const initialData: ToolType = {
  id: 0,
  name: '',
  form: '',
  coach_id: 0,
  link: '',
  public: true,
  task: ['task 1', 'task 2', 'task 3', 'task 4'],
  task_description: '',
  tool_description: '',
  duration: 0,
  last_updated: '',
};

export const EditToolPopup: FC<Props> = ({
  toolData,
  handleDeleteOrEditTool,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [toolUsed, setToolUsed] = useState(false);
  const [title, setTitle] = useState(toolData.name);
  const [link, setLink] = useState(toolData.link);
  const [toolDesc, setToolDesc] = useState(toolData.tool_description);
  const [taskDesc, setTaskDesc] = useState(toolData.task_description);
  const [step1, setStep1] = useState(toolData.task?.[0]);
  const [step2, setStep2] = useState(toolData.task?.[1]);
  const [step3, setStep3] = useState(toolData.task?.[2]);
  const [step4, setStep4] = useState(toolData.task?.[3]);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [confirmationStep, setConfirmationStep] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setToolUsed(false);
    setTitle(toolData.name);
    setLink(toolData.link);
    setToolDesc(toolData.tool_description);
    setTaskDesc(toolData.task_description);
    setStep1(toolData.task?.[0]);
    setStep2(toolData.task?.[1]);
    setStep3(toolData.task?.[2]);
    setStep4(toolData.task?.[3]);
    setHours('');
    setMinutes('');
    setSeconds('');
    calcDuration(toolData.duration);
  };

  const handleClose = () => {
    setConfirmationStep(false);
    setOpen(false);
  };

  const handleTitle = (event: any) => {
    if (event.target.value !== '') setTitle(event.target.value);
  };

  const handleHours = (event: any) => {
    setHours(event.target.value);
  };

  const handleMinutes = (event: any) => {
    setMinutes(event.target.value);
  };

  const handleSeconds = (event: any) => {
    setSeconds(event.target.value);
  };

  const handleLink = (event: any) => {
    if (event.target.value !== '') setLink(event.target.value);
  };

  const handleToolDesc = (event: any) => {
    if (event.target.value !== '') setToolDesc(event.target.value);
  };

  const handleTaskDesc = (event: any) => {
    if (event.target.value !== '') setTaskDesc(event.target.value);
  };

  const handleStep1 = (event: any) => {
    if (event.target.value !== '') setStep1(event.target.value);
  };

  const handleStep2 = (event: any) => {
    if (event.target.value !== '') setStep2(event.target.value);
  };

  const handleStep3 = (event: any) => {
    if (event.target.value !== '') setStep3(event.target.value);
  };

  const handleStep4 = (event: any) => {
    if (event.target.value !== '') setStep4(event.target.value);
  };

  const handleSave = () => {
    setConfirmationStep(true);
  };

  const handleSubmit = () => {
    editToolAPI();
    setConfirmationStep(false);
    setToolUsed(false);
  };

  const editToolAPI = () => {
    let data = {
      name: title,
      form: 'video',
      link: link,
      public: true,
      duration: calcDurationFromInput(),
      id: toolData.id,
      task: [step1, step2, step3, step4],
      task_description: toolDesc,
      tool_description: taskDesc,
    };
    put(API_ENDPOINTS.UNUSED_TOOL_EDIT, data)
      .then((res: any) => {
        handleDeleteOrEditTool();
        handleClose();
      })
      .catch((err: any) => {
        setToolUsed(true);
        console.log(err);
      });
  };

  const calcDuration = (toolDuration: number) => {
    const duration = calcDurationFromSeconds(toolDuration);

    let hoursString: string = duration.hours.toString();
    let minutesString: string = duration.minutes.toString();
    let secondsString: string = duration.seconds.toString();

    if (duration.hours < 10) {
      hoursString = '0' + duration.hours;
    }
    if (duration.minutes < 10) {
      minutesString = '0' + duration.minutes;
    }
    if (duration.seconds < 10) {
      secondsString = '0' + duration.seconds;
    }

    setHours(hoursString);
    setMinutes(minutesString);
    setSeconds(secondsString);
  };

  const calcDurationFromInput = () => {
    return calcDurationInSeconds(
      Number(hours),
      Number(minutes),
      Number(seconds)
    );
  };

  return (
    <div className={classes.popupContainer}>
      <EditIcon
        data-testid="edit-tool"
        className={classes.editIcon}
        onClick={handleClickOpen}
      />

      <Dialog
        className={classes.popup}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box className={classes.heading}>
            <Box>Tool Bearbeiten</Box>
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.editContainer} id="alert-dialog-description">
            <Box className={classes.inputRow}>
              <Box>
                <Box mb={1}>Titel</Box>
                <Box mb={4}>
                  <TextField
                    id={`title-${toolData.id}`}
                    variant="outlined"
                    onChange={handleTitle}
                    defaultValue={toolData.name}
                  />
                </Box>
              </Box>
              <Box>
                <Box mb={1}>Dauer (optional)</Box>
                <Box mb={4} className={classes.durationFields}>
                  <TextField
                    id={`hour-${toolData.id}`}
                    variant="outlined"
                    placeholder="h"
                    onChange={handleHours}
                    defaultValue={hours !== '00' ? hours : ''}
                  />
                  <TextField
                    id={`min-${toolData.id}`}
                    variant="outlined"
                    placeholder="min"
                    onChange={handleMinutes}
                    defaultValue={minutes !== '00' ? minutes : ''}
                  />
                  <TextField
                    id={`sec-${toolData.id}`}
                    variant="outlined"
                    placeholder="sec"
                    onChange={handleSeconds}
                    defaultValue={seconds !== '00' ? seconds : ''}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.inputFieldContainer}>
              <Box mb={1}>Link</Box>
              <Box mb={4}>
                <TextField
                  id={`link-${toolData.id}`}
                  variant="outlined"
                  onChange={handleLink}
                  defaultValue={toolData.link}
                />
              </Box>
            </Box>
            <Box className={classes.inputFieldContainer}>
              <Box mb={1}>Beschreibung des Tools</Box>
              <Box mb={4}>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={4}
                  id={`desc-${toolData.id}`}
                  variant="outlined"
                  onChange={handleToolDesc}
                  defaultValue={toolData.tool_description}
                />
              </Box>
            </Box>
            <Box className={classes.inputFieldContainer}>
              <Box mb={1}>Beschreibung der Aufgabe</Box>
              <Box mb={4}>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={4}
                  id={`task-${toolData.id}`}
                  variant="outlined"
                  onChange={handleTaskDesc}
                  defaultValue={toolData.task_description}
                />
              </Box>
            </Box>
            <Box className={classes.stepsFields}>
              <Box>
                <Box mb={1}>Erfolgsstufe 1</Box>
                <Box mb={1}>
                  <TextField
                    multiline
                    id={`step1-${toolData.id}`}
                    variant="outlined"
                    onChange={handleStep1}
                    defaultValue={toolData.task?.[0]}
                  />
                </Box>
              </Box>
              <Box>
                <Box mb={1}>Erfolgsstufe 2</Box>
                <Box mb={1}>
                  <TextField
                    multiline
                    id={`step2-${toolData.id}`}
                    variant="outlined"
                    onChange={handleStep2}
                    defaultValue={toolData.task?.[1]}
                  />
                </Box>
              </Box>
              <Box>
                <Box mb={1}>Erfolgsstufe 3</Box>
                <Box mb={1}>
                  <TextField
                    multiline
                    id={`step3-${toolData.id}`}
                    variant="outlined"
                    onChange={handleStep3}
                    defaultValue={toolData.task?.[2]}
                  />
                </Box>
              </Box>
              <Box>
                <Box mb={1}>Erfolgsstufe 4</Box>
                <Box mb={1}>
                  <TextField
                    multiline
                    id={`step4-${toolData.id}`}
                    variant="outlined"
                    onChange={handleStep4}
                    defaultValue={toolData.task?.[3]}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Button className={classes.closeButton} onClick={handleClose}>
            {'abbrechen'}
          </Button>
          <Button className={classes.saveButton} onClick={handleSave}>
            {'Speichern'}
          </Button>
          {confirmationStep ? (
            <ConfirmationPopup
              title={'Bist Du sicher?'}
              description={
                'Dadurch werden die bisherigen Einträge überschrieben.'
              }
              buttonClose={'abbrechen'}
              button={'Alles klar'}
              buttonClass={'buttonRed'}
              onChange={handleSubmit}
              onClose={handleClose}
            />
          ) : toolUsed ? (
            <ConfirmationPopup
              title={'Dieses Tool wird aktuell verwendet'}
              description={
                'Und kann deshalb nicht gelöscht oder bearbeitet werden.'
              }
              buttonClose={''}
              button={'Alles klar'}
              buttonClass={'buttonRed'}
              onChange={handleClose}
            />
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditToolPopup;
