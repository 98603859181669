import { API_ENDPOINTS, put, post, formPost, get } from '../utils';

export const createAccountClient = async (payload: any) =>
  put(API_ENDPOINTS.CREATE_ACCOUNT_CLIENT, payload);

export const submitSubmoduleAPI = async (payload: any) =>
  post(API_ENDPOINTS.CLOSE_SUBMODULE, payload);

export const saveTaskProgressAPI = async (payload: any) =>
  post(API_ENDPOINTS.SAVE_TASK_PROGRESS, payload);

export const submitReflectionsAPI = async (payload: any) =>
  post(API_ENDPOINTS.POST_REFLECTIONS_CLIENT, payload);

export const getReflectionsAPI = async (payload: any) =>
  post(API_ENDPOINTS.GET_REFLECTIONS_CLIENT, payload);

export const getClientProfileDetailsAPI = async () =>
  get(API_ENDPOINTS.GET_CLIENT_ME);

export const updateClientProfileDetailsAPI = async (payload: any) =>
  put(API_ENDPOINTS.EDIT_CLIENT_PROFILE, payload);

export const updateClientProfilePictureAPI = async (qs: any, payload: any) =>
  formPost(`${API_ENDPOINTS.UPLOAD_PROFILE_PICTURE}?${qs}`, payload);
