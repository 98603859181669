import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { deleteTool } from '../../../../api';
import videoIcon from '../../../../assets/videoIcon.svg';
import { ConfirmationPopup } from '../../../../components/common';
import { IF } from '../../../../components/core';
import { ToolType } from '../../../../types';
import { EditToolPopup } from '../../../admin/components';
import DeleteIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  toolContainer: {
    borderBottom: '1px solid #9d9d9d',
    color: '#f8f8f8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiCheckbox-root': {
      color: '#f8f8f8',
    },
  },
  tool: {
    padding: '20px 0',
    display: 'flex',
    fontFamily: 'Roboto',
    alignItems: 'center',
    fontWeight: 'bold',
    color: '#f8f8f8',
    fontSize: 14,
    '& > img': {
      width: 22,
      height: 22,
      marginRight: 10,
      marginLeft: 10,
    },
  },
  adminIcons: {
    display: 'flex',
  },
  deleteIcon: {
    transform: 'rotate(45deg)',
    cursor: 'pointer',
    width: 32,
    height: 32,
  },
}));

const initialData: ToolType = {
  id: 0,
  name: '',
  form: '',
  coach_id: 0,
  link: '',
  public: true,
  task: ['task 1', 'task 2', 'task 3', 'task 4'],
  task_description: '',
  tool_description: '',
  duration: 0,
  last_updated: '',
};

type Props = {
  toolData: ToolType;
  onCheckChange: Function;
  checked: boolean;
  adminView: boolean;
  handleDeleteOrEditTool: Function;
};

export const Tool: FC<Props> = ({
  toolData,
  onCheckChange,
  checked,
  adminView,
  handleDeleteOrEditTool,
}) => {
  const classes = useStyles();
  const data: ToolType = toolData || initialData;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [toolUsed, setToolUsed] = useState(false);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckChange(data, event.target.checked);
  };

  const handleDeleteOrEdit = () => {
    handleDeleteOrEditTool();
  };

  const handleDelete = () => {
    setDeleteDialog(false);
    deleteToolAPI(data.id);
    setToolUsed(false);
  };

  const deleteToolAPI = async (toolId: number) => {
    const payload = {
      id: toolId,
    };
    try {
      const res = await deleteTool(payload);
      handleDeleteOrEdit();
    } catch (err: any) {
      setToolUsed(true);
      console.log(err);
    }
  };

  return (
    <Box className={classes.toolContainer}>
      {adminView ? (
        <div className={classes.tool}>
          <img src={videoIcon} alt="video-icon" />
          <Box data-testid="tool-name">{data.name}</Box>
        </div>
      ) : (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                data-testid={`tool${data.id}`}
                onChange={handleCheckChange}
                checked={checked}
              />
            }
            label={
              <div className={classes.tool}>
                <img src={videoIcon} alt="video-icon" />
                <Box data-testid="tool-name">{data.name}</Box>
              </div>
            }
          />
        </FormGroup>
      )}
      <IF condition={adminView}>
        <div className={classes.adminIcons}>
          <EditToolPopup
            toolData={data}
            handleDeleteOrEditTool={handleDeleteOrEdit}
          />
          <DeleteIcon
            data-testid="delete-tool"
            className={classes.deleteIcon}
            onClick={() => setDeleteDialog(true)}
          />
          <IF condition={deleteDialog}>
            <ConfirmationPopup
              title={'Bist Du sicher?'}
              description={'Dadurch wird das Tool permanent gelöscht.'}
              buttonClose={'abbrechen'}
              button={'Löschen'}
              buttonClass={'buttonRed'}
              onChange={handleDelete}
              onClose={() => setDeleteDialog(false)}
            />
          </IF>
          <IF condition={toolUsed}>
            <ConfirmationPopup
              title={'Dieses Tool wird aktuell verwendet'}
              description={
                'Und kann deshalb nicht gelöscht oder bearbeitet werden.'
              }
              buttonClose={''}
              button={'Alles klar'}
              buttonClass={'buttonRed'}
              onChange={() => setToolUsed(false)}
            />
          </IF>
        </div>
      </IF>
    </Box>
  );
};

export default Tool;
