import { Box, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    letterSpacing: '0.88px',
    textAlign: 'left',
    color: '#fff',
    paddingBottom: 5,
    borderBottom: '1px solid #5f5f5f',
  },
  description: {
    marginTop: '10px',
    textAlign: 'left',
    fontSize: 14,
  },
}));

type Props = {
  title: string;
  description: any;
  ['data-testid']?: string;
};

export const ReadonlyTitleDescription: FC<Props> = ({
  title,
  description,
  ['data-testid']: dataTestId = 'readonly-title-description',
}) => {
  const classes = useStyles();

  return (
    <Box data-testid={dataTestId}>
      <Box className={classes.title}>{title}</Box>
      <Box className={classes.description}>{description}</Box>
    </Box>
  );
};
