export const isClient = (): boolean => {
  const permissions: any = localStorage.getItem('permissions');
  return permissions === 'client';
};

export const isCoach = (): boolean => {
  const permissions: any = localStorage.getItem('permissions');
  return permissions === 'coach';
};

export const isAdmin = (): boolean => {
  const permissions: any = localStorage.getItem('permissions');
  return permissions === 'admin';
};
