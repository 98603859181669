import { makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import plusSign from '../../assets/plusSign.svg';
import { EnergylevelType } from '../../types/EnergylevelType';

const useStyles = makeStyles((theme) => ({
  energylevel: {
    textAlign: 'left',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '0.72px',
    marginBottom: 50,
    paddingRight: 50,
  },
  percentages: {
    fontSize: '17px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
  },
  percentage: {
    position: 'relative',
    marginTop: 20,
    '&::after': {
      content: '""',
      width: 1,
      height: 10,
      background: '#bcbcbc',
      position: 'absolute',
      bottom: '-20px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '&:first-child::after': {
      left: '20px',
      transform: 'unset',
    },
    '&:last-child::after': {
      left: 'unset',
      right: '20px',
      transform: 'unset',
    },
  },
  coloredBar: {
    margin: '20px',
    height: '50px',
    background: 'linear-gradient(to right, #e38665, #7ae783 39%, #fff871)',
    position: 'relative',
  },
  plusIcon: {
    width: 21,
    height: 21,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

type Props = {
  energylevelData: EnergylevelType | null;
};

const initialData: EnergylevelType = {
  energie: 0,
};

export const Energylevel: FC<Props> = ({ energylevelData }) => {
  const classes = useStyles();
  const data: EnergylevelType = energylevelData || initialData;
  const [energyLevelPosition, setEnergyLevelPosition] = useState('');

  const calculateEnergyLevelPosition = () => {
    let newPosition = energyLevelPosition;
    if (data.energie >= 0) {
      newPosition = (50 + data.energie / 2).toString() + '%';
      setEnergyLevelPosition(newPosition);
    } else {
      newPosition = -(50 - (100 + data.energie / 2)).toString() + '%';
      setEnergyLevelPosition(newPosition);
    }
  };

  useEffect(() => {
    calculateEnergyLevelPosition();
  });

  return (
    <div className={classes.energylevel}>
      <div>Energielevel</div>
      <div className={classes.percentages}>
        <div className={classes.percentage}>-100%</div>
        <div className={classes.percentage}>0%</div>
        <div className={classes.percentage}>100%</div>
      </div>
      <div className={classes.coloredBar}>
        <div
          data-testid="energy-bar"
          className={classes.plusIcon}
          style={{ left: energyLevelPosition }}
        >
          <img alt="plus-icon" src={plusSign} width="21" height="21" />
        </div>
      </div>
    </div>
  );
};

export default Energylevel;
