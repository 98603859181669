import { makeStyles, Box, Button } from '@material-ui/core';
import React, { FC, useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    margin: '0 10px 20px',
  },
  upload: {
    backgroundColor: 'white',
    padding: 80,
    textAlign: 'center',
    borderRadius: 5,
  },
  cloudIcon: {
    width: 120,
    height: 90,
    color: '#d8d8d8',
  },
  uploadText: {
    color: '#96a0b0',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    marginTop: 0,
  },
  boldText: {
    color: '#3e3e3e',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  noDraggedFiles: {
    border: '1px solid #d8d8d8',
    borderRadius: 5,
  },
  dragAcceptedFile: {
    border: '1px solid #24c776',
    borderRadius: 5,
    '& .MuiSvgIcon-root': {
      color: '#24c776',
    },
  },
  dragRejectedFile: {
    border: '1px solid #fd5f58',
    borderRadius: 5,
    '& .MuiSvgIcon-root': {
      color: '#fd5f58',
    },
  },
  buttonContainer: {
    marginTop: 30,
  },
  closeButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #8e8e8e',
    color: '#8e8e8e',
    padding: '7px 30px',
    width: 160,
    margin: '0 5px',
    '& > span': {
      color: '#5f5f5f',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  saveButton: {
    backgroundColor: '#24c776',
    padding: '7px 30px',
    textTransform: 'none',
    fontWeight: 'bold',
    color: 'white',
    width: 160,
    margin: '0 5px',
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(36, 199, 118, 0.7)',
    },
  },
}));

type Props = {
  onCloseUpload: Function;
  onSaveUpload: Function;
};
export const UploadImage: FC<Props> = ({ onCloseUpload, onSaveUpload }) => {
  const classes = useStyles();

  const [file, setFile] = useState<FormData>();
  const [image, setImage] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [fileTooBig, setFileTooBig] = useState(false);

  const maxFileSize: number = 51200; // => 50 KB

  const handleDrop = (acceptedFiles: any) => {
    setFileTooBig(false);
    setFileNames(acceptedFiles.map((file: any) => file.name));

    let data_file = acceptedFiles?.[0];
    let formData = new FormData();
    formData.append('data_file', data_file);

    setImage(URL.createObjectURL(data_file));
    setFile(formData);
  };

  const handleDropRejected = () => {
    setFileTooBig(true);
  };

  const handleClose = () => {
    onCloseUpload();
  };
  const handleSave = () => {
    onSaveUpload(image, file);
    handleClose();
  };

  return (
    <Box className={classes.uploadContainer}>
      <Dropzone
        accept="image/jpeg, image/jpg, image/png"
        onDropAccepted={handleDrop}
        onDropRejected={handleDropRejected}
        maxSize={maxFileSize}
        multiple={false}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
          <section
            className={
              isDragAccept || (fileNames.length !== 0 && !isDragReject)
                ? classes.dragAcceptedFile
                : isDragReject || fileTooBig
                ? classes.dragRejectedFile
                : classes.noDraggedFiles
            }
            data-testid="dropzone"
          >
            <div {...getRootProps()} className={classes.upload}>
              <CloudUploadIcon
                className={classes.cloudIcon}
                data-testid="cloud"
              />
              <input {...getInputProps()} data-testid="upload-image" />

              {isDragReject ? (
                <p className={classes.uploadText}>
                  Dateityp wird nicht erkannt, bitte wähle eine .jpg oder .png
                  Datei aus
                </p>
              ) : fileNames.length !== 0 && !fileTooBig ? (
                <div className={classes.uploadText}>
                  {fileNames.map((fileName, index) => (
                    <p
                      key={index}
                      className={classes.uploadText}
                      data-testid="success-msg"
                    >
                      Datei <span key={fileName}>{fileName}</span> ist bereit
                      für Upload
                    </p>
                  ))}
                  <Box
                    {...getRootProps({
                      onClick: (event) => event.stopPropagation(),
                    })}
                    className={classes.buttonContainer}
                  >
                    <Button
                      className={classes.closeButton}
                      onClick={handleClose}
                    >
                      {'abbrechen'}
                    </Button>
                    <Button
                      className={classes.saveButton}
                      onClick={handleSave}
                      data-testid="submit"
                    >
                      {'OK'}
                    </Button>
                  </Box>
                </div>
              ) : fileNames.length === 0 && fileTooBig ? (
                <Box>
                  <p className={classes.uploadText} data-testid="reject-msg">
                    Deine gewählte Datei ist zu groß. <br />
                    Bitte lade <span className={classes.boldText}>
                      hier
                    </span>{' '}
                    eine Datei mit maximal 50KB hoch.
                  </p>
                  <Box
                    {...getRootProps({
                      onClick: (event) => event.stopPropagation(),
                    })}
                    className={classes.buttonContainer}
                  >
                    <Button
                      className={classes.closeButton}
                      onClick={handleClose}
                    >
                      {'abbrechen'}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <p className={classes.uploadText} data-testid="upload-text">
                    Bild hierhin ziehen oder{' '}
                    <span className={classes.boldText}>browsen</span> für Upload
                  </p>
                  <Box
                    {...getRootProps({
                      onClick: (event) => event.stopPropagation(),
                    })}
                    className={classes.buttonContainer}
                  >
                    <Button
                      className={classes.closeButton}
                      onClick={handleClose}
                      data-testid="abbrechen"
                    >
                      {'abbrechen'}
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </Box>
  );
};

export default UploadImage;
