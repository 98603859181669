import { Box, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { ToolUploadPopup } from '.';
import { getToolsList } from '../../../api';
import { Tool } from '../../coach/clients/components';

const useStyles = makeStyles((theme) => ({
  toolsContainer: {
    marginTop: 20,
    backgroundColor: '#7b7b78',
    borderRadius: 5,
    boxShadow: '0 2px 8px 0 rgba(57, 64, 82, 0.1)',
    padding: '20px 30px',
    maxHeight: '60vh',
    overflowY: 'auto',
  },
  addIcon: {
    background: '#24c776',
    borderRadius: '50%',
    color: '#f8f8f8',
    padding: 5,
    cursor: 'pointer',
  },
}));

export const ToolsList: FC = () => {
  const classes = useStyles();
  const [toolsList, setToolsList] = useState([{}]);

  useEffect(() => {
    getToolsFromAPI();
  }, []);

  const toolSelected = () => {};

  const handleUpdateView = () => {
    getToolsFromAPI();
  };

  const getToolsFromAPI = async () => {
    try {
      const res = await getToolsList();
      setToolsList(res.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Box>
      <ToolUploadPopup
        data-testid="tool-upload"
        handleUpload={handleUpdateView}
      />
      <Box className={classes.toolsContainer}>
        {toolsList.map((el: any, index: number) => (
          <Tool
            key={index}
            toolData={el}
            onCheckChange={toolSelected}
            checked={el.selected}
            adminView={true}
            handleDeleteOrEditTool={handleUpdateView}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ToolsList;
