import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '../../assets/expandWhite.svg';
import React, { FC, useEffect, useState } from 'react';
import checkDefault from '../../assets/checkDefault.svg';
import checkGreen from '../../assets/checkGreen.svg';
import { QuestionsAndAnswersType } from '../../types';
import { isEmptyOrIsNull, questions } from '../../utils';
import { IF } from '../core';

const useStyles = makeStyles((theme) => ({
  container: {},
  accordion: {
    width: '100%',
    background: '#7b7b78',
    border: 0,
    boxShadow: 'none',
    borderRadius: 10,
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid rgb(255 255 255 / 25%)',
    '& .MuiAccordionSummary-content': {
      justifyContent: 'center',
    },
  },
  accordionSummaryQuestions: {
    borderBottom: '1px solid rgb(255 255 255 / 25%)',
    '& .MuiAccordionSummary-content': {
      justifyContent: 'unset',
    },
  },
  expandMore: {
    color: theme.palette.common.white,
    width: 25,
    transform: 'rotate(180deg)',
  },
  heading: {
    background: '#7b7b78',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root:first-child': {
      borderLeft: 'none',
      width: '48%',
    },
    '& .MuiTableCell-head': {
      textAlign: 'center',
    },
    '& .MuiTableCell-root': {
      borderLeft: '1px solid #9d9d9d',
      borderBottom: '1px solid #9d9d9d',
      padding: 10,
      fontWeight: 'normal',
    },
  },
  tableText: {
    '& *': {
      color: 'white',
      fontSize: 16,
    },
  },
  tableHeadline: {
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: '0.72px',
    textAlign: 'left',
  },
  answerOptions: {
    textTransform: 'lowercase',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  optionCircleNone: {
    position: 'relative',
  },
  optionCircleVisible: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 15,
      height: 15,
      borderRadius: '50%',
      backgroundColor: '#24c776',
      transform: 'translate(-50%, -50%)',
    },
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px',
  },
  saveBtn: {
    color: theme.palette.common.white,
    textTransform: 'none',
    '&.MuiButton-root:hover': {
      backgroundColor: '#9d9d9d',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  iconArrowForward: {
    fontSize: 18,
    cursor: 'pointer',
    backgroundColor: '#24c776',
    borderRadius: '50%',
    marginLeft: '5px',
    padding: '5px',
  },
  checkIcon: {
    cursor: 'pointer',
  },
  pointerNone: {
    pointerEvents: 'none',
  },
  rows: {
    '& .MuiTableRow-root:nth-child(2)  > td::after, \
      .MuiTableRow-root:nth-child(4)  > td::after, \
      .MuiTableRow-root:nth-child(5)  > td::after, \
      .MuiTableRow-root:nth-child(7)  > td::after':
      {
        backgroundColor: '#ff674b',
      },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    width: '25px',
    maxWidth: '25px',
    textAlign: 'center',
    wordBreak: 'break-word',
    hyphens: 'manual',
  },
  body: {
    width: '25px',
    maxWidth: '25px',
    textAlign: 'center',
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    width: '10px',
    maxWidth: '10px',
    textAlign: 'center',
    wordBreak: 'break-word',
    hyphens: 'manual',
  },
  body: {
    width: '10px',
    maxWidth: '10px',
    textAlign: 'center',
  },
}))(TableCell);

type Props = {
  questionsAndAnswersData: QuestionsAndAnswersType | null;
  isCoach: boolean;
  onUpdateSensitivityState: Function;
  fokus: Array<boolean> | null;
  editModulePosition?: number;
  activeModule?: number;
};

const initialData: QuestionsAndAnswersType = {
  a_eight: 1,
  a_five: 1,
  a_four: 1,
  a_one: 1,
  a_seven: 1,
  a_six: 1,
  a_three: 1,
  a_two: 1,
};

export const QuestionsAndAnswers: FC<Props> = ({
  questionsAndAnswersData,
  isCoach,
  onUpdateSensitivityState,
  fokus,
  editModulePosition,
  activeModule,
}) => {
  const classes = useStyles();
  const data: QuestionsAndAnswersType = questionsAndAnswersData || initialData;
  let tableHeadElements = [''];
  let tableBodyElements: {
    question: string;
    options: string[];
    points: number;
    focus: boolean;
  }[] = [];
  const [rows, setRows] = useState<any>([]);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const answerOptionKeys = [
    'a_one',
    'a_two',
    'a_three',
    'a_four',
    'a_five',
    'a_six',
    'a_seven',
    'a_eight',
  ] as const;

  useEffect(() => {
    createTableElements();
    setRows(tableBodyElements);
  }, [data]);

  const createTableElements = () => {
    for (let i = 0; i < questions.length; i++) {
      let question = questions[i].question;
      let answerKey = answerOptionKeys[i];
      let answer: number = data[answerKey];
      let points = answer;
      let options = ['', '', '', '', ''];
      options[answer - 1] = 'x';
      tableBodyElements.push(
        createData(question, options, points, fokus ? fokus?.[i] : false)
      );
    }
  };

  for (let i = 0; i < questions[0].options.length; i++) {
    tableHeadElements.push(questions[0].options[i].option);
  }

  function createData(
    question: string,
    options: string[],
    points: number,
    focus: boolean
  ) {
    return { question, options, points, focus };
  }

  const onChangeOption = (rowIndex: number, optionIndex: number) => {
    let answerKey = answerOptionKeys[rowIndex];
    const currentRow: any = rows[rowIndex];
    const options = currentRow.options.map((option: any) => '');
    options[optionIndex] = 'x';
    currentRow.options = options;
    currentRow.points = optionIndex + 1;
    data[answerKey] = currentRow.points;
    setRows([
      ...rows.slice(0, rowIndex),
      currentRow,
      ...rows.slice(rowIndex + 1),
    ]);
  };

  const handleSubmit = async () => {
    setEnableEdit(false);
    const answerMap: any = {};
    Array.from(Array(8).keys()).forEach(
      (i: number) => (answerMap[`answer${i + 1}`] = rows[i].points)
    );
    const payload = {
      module_id: -1,
      ...answerMap,
      fokus: rows.map((row: any) => row.focus),
    };
    onUpdateSensitivityState(payload);
  };

  const changeCheck = (elem: any, index: number) => {
    const tempRows = [...rows];
    tempRows.splice(index, 1, { ...elem, focus: !elem.focus });
    setRows(tempRows);
  };

  const getSplittedWordWithHyphen = (value: string): any => {
    const wordMap: any = {
      'TRIFFT NICHT ZU': <>TRIF&shy;FT NICHT ZU</>,
      'EHER NICHT': <>EHER NICHT</>,
      UNENTSCHIEDEN: <>UN&shy;ENT&shy;SCHIE&shy;DE&shy;N</>,
      'TRIFFT EHER ZU': <>TRIF&shy;FT EHER ZU</>,
      'TRIFFT ZU': <>TRIF&shy;FT ZU</>,
      Punkte: <>Punk&shy;te</>,
      Fokus: <>Fo&shy;kus</>,
    };
    return wordMap[value];
  };

  return (
    <div className={classes.container}>
      <Accordion defaultExpanded className={classes.accordion} square={true}>
        <AccordionSummary
          expandIcon={
            <img
              className={classes.expandMore}
              src={ExpandMoreIcon}
              alt="expand-icon"
            />
          }
          aria-controls="status"
          id="status"
          className={classes.accordionSummaryQuestions}
        >
          <Typography className={classes.heading}>
            Sensitivitätsfragen
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer className={classes.tableText}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableHeadElements.map((value, index) => {
                    return (
                      <StyledTableCell
                        data-testid={`option-${index + 1}`}
                        className={classes.answerOptions}
                        key={index}
                      >
                        {getSplittedWordWithHyphen(value)}
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCell2 data-testid="punkte">
                    {getSplittedWordWithHyphen('Punkte')}
                  </StyledTableCell2>
                  <StyledTableCell2 data-testid="fokus">
                    {getSplittedWordWithHyphen('Fokus')}
                  </StyledTableCell2>
                </TableRow>
              </TableHead>
              <TableBody className={classes.rows}>
                {rows.map((tableBodyElement: any, rowIndex: any) => (
                  <TableRow key={tableBodyElement.question}>
                    <TableCell>{tableBodyElement.question}</TableCell>
                    {tableBodyElement.options.map(
                      (option: any, optionIndex: number) => (
                        <StyledTableCell
                          data-testid={`option-${rowIndex + 1}-${
                            optionIndex + 1
                          }`}
                          align="center"
                          key={optionIndex}
                          onClick={() => onChangeOption(rowIndex, optionIndex)}
                          className={`
                            ${
                              option !== ''
                                ? classes.optionCircleVisible
                                : classes.optionCircleNone
                            }
                            ${
                              enableEdit
                                ? classes.checkIcon
                                : classes.pointerNone
                            }
                          `}
                        ></StyledTableCell>
                      )
                    )}
                    <StyledTableCell2>
                      {tableBodyElement.points}
                    </StyledTableCell2>
                    <StyledTableCell2
                      data-testid={`check-${rowIndex + 1}`}
                      align="center"
                      className={
                        isCoach
                          ? enableEdit
                            ? classes.checkIcon
                            : classes.pointerNone
                          : classes.pointerNone
                      }
                      onClick={() => changeCheck(tableBodyElement, rowIndex)}
                    >
                      <img
                        data-testid={`checkIcon-${rowIndex + 1}`}
                        alt="check"
                        src={tableBodyElement.focus ? checkGreen : checkDefault}
                        width="18"
                        height="14"
                      />
                    </StyledTableCell2>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box>
              <IF condition={isCoach}>
                <IF
                  condition={
                    (isEmptyOrIsNull(editModulePosition) ||
                      editModulePosition === activeModule) &&
                    (activeModule ? activeModule % 2 !== 0 : false)
                  }
                >
                  <Box className={classes.btnContainer}>
                    {!enableEdit ? (
                      <Button
                        data-testid="bearbeiten-options"
                        className={classes.saveBtn}
                        onClick={() => setEnableEdit(!enableEdit)}
                      >
                        <Typography>bearbeiten</Typography>
                        <EditIcon className={classes.iconArrowForward} />
                      </Button>
                    ) : (
                      <Button
                        data-testid="speichern-options"
                        className={classes.saveBtn}
                        onClick={handleSubmit}
                      >
                        <Typography>speichern</Typography>
                        <CloudUploadIcon className={classes.iconArrowForward} />
                      </Button>
                    )}
                  </Box>
                </IF>
              </IF>
            </Box>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default QuestionsAndAnswers;
