import { makeStyles, Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC, useState } from 'react';
import { updatePasswordAPI } from '../../api';
import { PasswordField } from '.';
import {
  CapitalRegex,
  isEmptyOrIsNull,
  LowerRegex,
  NumberRegex,
  PasswordRegex,
} from '../../utils';

const useStyles = makeStyles((theme) => ({
  apiErrorMsg: {
    color: '#FF5F58 !important',
    padding: '0px 0px 10px 0px !important',
  },
  outlineButton: {
    maxWidth: 'calc(100% - 20px)',
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '2px solid #24c776',
    padding: '14px 0px',
    margin: '28px 10px 0',
    '& > span': {
      color: '#24c776',
      fontWeight: 'bold',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  textFieldRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-input, & .MuiOutlinedInput-multiline': {
      backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      margin: '0 10px',
    },
    '& .MuiButtonBase-root, & .MuiFormControl-root': {
      width: '100%',
      minWidth: '50%',
    },
  },
  invalidPassword: {
    color: '#ff5f58',
  },
  validPassword: {
    color: '#5f5f5f',
  },
}));

type Props = {
  onChange: Function;
};

export const ChangePassword: FC<Props> = ({ onChange }) => {
  const classes = useStyles();
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassConfirm, setNewPassConfirm] = useState('');
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [errorObj, setErrorObj] = useState<any>({
    oldPass: '',
    newPass: '',
    newPassConfirm: '',
  });

  const validatePassword = (password: string) => PasswordRegex.test(password);

  const getValidPasswordMessage = (value: any) => {
    return (
      <>
        <span
          className={`${
            value.toString().length < 8
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`Min. 8 Zeichen; `}
        </span>
        <span
          className={`${
            !CapitalRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Großbuchstabe; `}
        </span>
        <span
          className={`${
            !LowerRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Kleinbuchstabe; `}
        </span>
        <span
          className={`${
            !NumberRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Zahl`}
        </span>
      </>
    );
  };

  const validateForm = () => {
    let errorList: any = {};

    if (isEmptyOrIsNull(oldPass)) {
      errorList.oldPass = 'Bitte gib Dein altes Passwort ein';
    }

    if (isEmptyOrIsNull(newPass)) {
      errorList.newPass = 'Bitte gib Dein neues Passwort ein';
    } else {
      errorList.newPass = getValidPasswordMessage(newPass);
    }

    if (isEmptyOrIsNull(newPassConfirm)) {
      errorList.newPassConfirm = 'Bitte gib Dein neues Passwort erneut ein';
    }

    if (
      !isEmptyOrIsNull(newPass) &&
      !isEmptyOrIsNull(newPassConfirm) &&
      newPass !== newPassConfirm
    ) {
      errorList.newPassConfirm = 'Passwörter müssen identisch sein';
    }

    if (Object.keys(errorList).length === 1 && validatePassword(newPass)) {
      setErrorObj(errorList);
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };

  const handleOldPass = (value: any) => {
    setOldPass(value);
  };

  const handleNewPass = (value: any) => {
    let errorList: any = { ...errorObj };
    setNewPass(value);
    if (isEmptyOrIsNull(value)) {
      errorList.newPass = 'Bitte gib Dein neues Passwort ein';
    } else {
      errorList.newPass = getValidPasswordMessage(value);
    }
    setErrorObj({ ...errorList });
  };

  const handleNewPassConfirm = (value: any) => {
    setNewPassConfirm(value);
  };

  const handleChangePassword = async () => {
    if (!validateForm()) return;

    const payload = {
      email: localStorage.getItem('email'),
      old_password: oldPass,
      new_password: newPass,
    };
    try {
      const res = await updatePasswordAPI(payload);
      onChange();
    } catch (err: any) {
      console.log(err);
      setApiErrorMsg(err.response.data.detail);
    }
  };

  return (
    <Box>
      {apiErrorMsg && (
        <Box>
          <Alert severity="error" className={classes.apiErrorMsg}>
            {apiErrorMsg}
          </Alert>
        </Box>
      )}
      <Box className={classes.textFieldRow}>
        <PasswordField
          label={'Altes Passwort'}
          value={oldPass}
          onChange={(e: any) => handleOldPass(e.target.value)}
          onBlur={() => validateForm()}
          error={errorObj.oldPass}
          data-testid="oldpass"
        />
        <PasswordField
          label={'Neues Passwort'}
          value={newPass}
          onBlur={() => validateForm()}
          error={errorObj.newPass}
          onInput={(e: any) => handleNewPass(e.target.value)}
          data-testid="newpass"
        />
      </Box>
      <Box mt={2} mb={4} className={classes.textFieldRow}>
        <PasswordField
          label={'Neues Passwort wiederholen'}
          value={newPassConfirm}
          onChange={(e: any) => handleNewPassConfirm(e.target.value)}
          onBlur={() => validateForm()}
          error={errorObj.newPassConfirm}
          data-testid="cnewpass"
        />
        <Box className="MuiFormControl-root">
          <Button
            className={classes.outlineButton}
            onClick={handleChangePassword}
            data-testid="changepassword"
          >
            {'Neues Passwort sichern'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
