import React, { FC, useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { Questionnaire } from './Questionnaire';
import { QuestionnaireSubmit } from './QuestionnaireSubmit';
import { VielenDank } from './VielenDank';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  textContainer: {},
  row: {
    background: '#bcbcbc',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    '& > div:first-child': {
      flex: '0 0 114px',
    },
    '& > div:nth-child(2)': {},
    '& > div:last-child': {
      flex: '0 0 137px',
    },
    [theme.breakpoints.down('xs')]: {
      background: 'transparent',
    },
  },
  otpAndButtonContainer: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
    height: '500px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'unset',
    },
  },
  otpButton: {
    padding: theme.spacing(4, 0),
    boxShadow: 'none',
    borderRadius: 10,
    background: '#24C776',
    border: '1px solid #707070',
    '&:hover': {
      background: '#24C776',
      boxShadow: 'none',
    },
  },
  otpButtonActive: {
    minWidth: '260px',
    padding: theme.spacing(4, 0),
    boxShadow: 'none',
    borderRadius: 10,
    background: '#5f5f5f',
    border: '1px solid #707070',
    color: '#FFFFFF',
    '&> span': {
      font: 'normal normal bold 1.2rem/26px Roboto',
    },
    '&:hover': {
      background: '#5f5f5f',
      boxShadow: 'none',
    },
    '&:disabled': {
      color: '#FFFFFF',
      background: '#24C776',
      '&> span': {
        font: 'normal normal normal 1.2rem/26px Segoe UI',
      },
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '220px',
      marginTop: 20,
    },
  },
  otpValue: {
    width: '100%',
    background: '#5F5F5F',
    height: 140,
    marginBottom: theme.spacing(6),
    borderRadius: 10,
    borderColor: '#707070',
  },
  otpTextField: {
    '&,.MuiInput-underline:before': {
      borderBottom: '1px solid #ffffff',
    },
    '&,.MuiInput-underline:after': {
      borderBottom: '1px solid #ffffff33',
    },
    '&,.MuiInputBase-input': {
      color: 'white',
    },
  },
  otpContainer: {
    width: 300,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  otpText: {
    font: "700 18px/28px 'Roboto'",
    paddingTop: theme.spacing(2),
    color: 'white',
  },
  errorText: {
    font: "700 18px/28px 'Source Sans Pro'",
    color: '#FD5F58',
  },
  heading: {
    font: 'normal normal normal 52px/66px PT Serif',
    letterSpacing: '1.74px',
    color: '#0EA554',
    textAlign: 'center',
    opacity: 1,
    lineHeight: 1.14,
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
    },
  },
  subHeading: {
    marginTop: theme.spacing(1.7),
    marginBottom: theme.spacing(1.7),
    font: "500 16px/24px 'Roboto'",
    letterSpacing: 0,
    color: '#3d3d3d',
    opacity: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  smallText: {
    marginTop: theme.spacing(2.4),
    maxWidth: 556,
    margin: 'auto',
    letterSpacing: 0,
    color: '#3d3d3d',
    font: "500 16px/24px 'Roboto'",
    opacity: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  button: {
    background: 'black',
    padding: theme.spacing(1, 0),
    marginTop: theme.spacing(1.4),
    borderRadius: 0,
    boxShadow: 'none',
    textDecoration: 'none',
    font: "500 16px/22px 'Roboto'",
    '&:hover': {
      background: '#000000d4',
      boxShadow: 'none',
    },
  },
  btnContainer: {},
}));

type Props = {
  landing?: any;
  showText?: boolean;
};

export const NeuroSensitivityTest: FC<Props> = (props) => {
  const classes = useStyles();
  const [answers, setAnswers] = useState<any[]>([]);

  useEffect(() => {
    document.body.style.background = 'white';
  }, []);

  const [step, setStep] = useState('STEP_ONE');

  const onSubmitAnswers = (items: any) => {
    setAnswers([...items]);
    setStep('STEP_THREE');
  };

  const handelBack = () => {
    setStep('STEP_TWO');
  };

  const handleVielenDank = () => {
    setStep('STEP_FOUR');
  };

  return (
    <Fragment>
      {step === 'STEP_FOUR' ? (
        <VielenDank />
      ) : step === 'STEP_THREE' ? (
        <QuestionnaireSubmit
          handelBack={handelBack}
          answers={answers}
          handleVielenDank={handleVielenDank}
        />
      ) : step === 'STEP_TWO' ? (
        <Questionnaire onSubmitAnswers={onSubmitAnswers} />
      ) : (
        <Fragment>
          <Box>
            {props.landing}
            <Box className={classes.textContainer}>
              <Box className={classes.row}>
                <Box className={classes.otpAndButtonContainer}>
                  {props.showText && props.showText === true && (
                    <Box>
                      <Box className={classes.heading} data-testid="heading">
                        Neurosensitivitätstest
                      </Box>
                      <Box
                        className={classes.subHeading}
                        data-testid="sub-heading"
                      >
                        Finde heraus, welcher Typ Du bist
                      </Box>
                      <Box
                        className={classes.subHeading}
                        data-testid="description"
                      >
                        Fülle den wissenschaftlich fundierten Test nach Dr.
                        Wyrsch aus und erfahre rasch und unkompliziert, zu
                        welchem Sensitivitätstypen Du gehörst. Dauert 2 Minuten.
                      </Box>
                      <Box className={classes.btnContainer}>
                        <Button
                          data-testid="start-test"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.otpButtonActive}
                          onClick={handelBack}
                          endIcon={<ArrowForwardIcon fontSize="large" />}
                        >
                          TEST STARTEN
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {!props.showText && (
                    <Box className={classes.btnContainer}>
                      <Button
                        data-testid="start-test"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.otpButtonActive}
                        onClick={handelBack}
                        endIcon={<ArrowForwardIcon fontSize="large" />}
                      >
                        TEST STARTEN
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};
