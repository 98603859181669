import { AxiosResponse } from 'axios';
import { API_ENDPOINTS, post, get, deleteAPI, put, formPost } from '../utils';

export const createAccountCoach = async (payload: any) =>
  post(API_ENDPOINTS.CREATE_ACCOUNT_COACH, payload);

export const submitSelectedToolsListAPI = async (payload: any) =>
  post(API_ENDPOINTS.ADD_TOOLS_TO_MODULE, payload);

export const handleNextStepsSubmissionAPI = async (payload: any) =>
  post(API_ENDPOINTS.POST_NEXT_STEPS, payload);

export const getAllToolsAPI = async () => get(API_ENDPOINTS.TOOLS_LIST);

export const getNextStepsDataAPI = async (payload: any) =>
  post(API_ENDPOINTS.GET_NEXT_STEPS, payload);

export const getSelectedToolsAPI = async (payload: any) =>
  post(API_ENDPOINTS.GET_PRACTICAL_MODULE, payload);

export const getCoachProfileDetailsAPI = async (payload: any) =>
  post(API_ENDPOINTS.GET_COACH_ME, payload);

export const updateCoachProfileDetailsAPI = async (payload: any) =>
  put(API_ENDPOINTS.EDIT_COACH_PROFILE, payload);

export const updateCoachProfilePictureAPI = async (qs: any, payload: any) =>
  formPost(`${API_ENDPOINTS.UPLOAD_PROFILE_PICTURE}?${qs}`, payload);

export const clientDashboardData = async (payload: any) =>
  post(API_ENDPOINTS.COACH_ME_LIST_CLIENT_RESULTS, payload);

export const getLeadListAPI = async (): Promise<any> =>
  get(API_ENDPOINTS.GET_LEADS);

export const delClientAPI = async (payload: any) =>
  deleteAPI(API_ENDPOINTS.COACH_ME_LIST, payload);

export const getClients = async () => get(API_ENDPOINTS.COACH_ME_LIST);

export const deleteClient = async (payload: any) =>
  deleteAPI(API_ENDPOINTS.COACH_ME_LIST, payload);

export const getClientProfileAPI = async (payload: any) =>
  post(API_ENDPOINTS.COACH_CLIENT_PROFILE, payload);
