import React, { FC } from 'react';
import { Routes } from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#24c776',
    },
  },
});

const App: FC = () => (
  <MuiThemeProvider theme={theme}>
    <Router>
      <Routes />
    </Router>
  </MuiThemeProvider>
);

export default App;
