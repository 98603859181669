import {
  Box,
  Button,
  Card,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert } from '@material-ui/lab';
import React, { FC, Fragment, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ForgotPasswordModal } from '../components/common';
import { useAuthDispatch, useAuthState } from '../providers';
import {
  EmailRegex,
  isEmptyOrIsNull,
  login,
  routesMapAfterLogin,
} from '../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 100,
    maxWidth: 490,
    width: '100%',
    background: 'rgba(14,165,84,0.5)',
    padding: theme.spacing(7, 5),
    borderRadius: 5,
    boxShadow: 'none',
  },
  signInText: {
    padding: theme.spacing(0, 3),
    font: "500 26px/30px 'Roboto'",
    color: '#ffffff',
  },
  signInBtn: {
    background: '#5f5f5f',
    color: theme.palette.common.white,
    padding: theme.spacing(1.4, 0),
    boxShadow: 'none',
    borderRadius: 2,
    fontSize: 18,
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
      boxShadow: 'none',
    },
  },
  inputFieldContainer: {
    padding: theme.spacing(0, 3),
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#9D9D9D',
    },
  },
  inputField: {
    background: theme.palette.common.white,
    borderRadius: 2,
    '&:hover': {
      background: '#eeeeee',
    },
    '&,.MuiInputBase-input:focus': {
      background: theme.palette.common.white,
    },
  },
  inputLabel: {
    color: '#9D9D9D',
  },
  helperText: {
    color: '#FF5F58',
    marginLeft: theme.spacing(-0.2),
    fontStyle: 'italic',
  },
  apiErrorMsg: {
    color: '#FF5F58',
    marginLeft: theme.spacing(-0.2),
    fontSize: '20px',
    marginTop: '10px',
  },
  alert: {
    margin: '24px',
  },
  resetPassword: {
    textAlign: 'right',
    '& button': {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1.19,
      letterSpacing: 0.32,
      color: '#7b7b78',
      textTransform: 'none',
      padding: '0 0 0 15px',
      '&:hover': {
        textDecoration: 'underline',
        background: 'transparent',
      },
    },
  },
}));

export const Login: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resendConfirm, setResendConfirm] = useState(false);
  const [criteriaObj, setCriteriaObj] = useState({
    email: '',
    password: '',
  });
  const [errorObj, setErrorObj] = useState({
    email: '',
    password: '',
  });
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  const { onLogin }: any = useAuthDispatch();
  const { error: authError, isLoggedIn }: any = useAuthState();

  let isFormEmpty = true;

  const validateEmail = (email: string) => EmailRegex.test(email.toLowerCase());

  const validateForm = () => {
    let errorList: any = {};

    if (isEmptyOrIsNull(criteriaObj.email)) {
      errorList.email = 'Bitte gib Deine E-Mail-Adresse ein';
    } else if (!validateEmail(criteriaObj.email)) {
      errorList.email =
        'Bitte gib die Mail-Adresse ein, mit der Du Dich registriert hast';
    }
    if (isEmptyOrIsNull(criteriaObj.password)) {
      errorList.password = 'Bitte gib Dein Passwort ein';
    }

    if (Object.keys(errorList).length === 0) {
      setErrorObj({
        email: '',
        password: '',
      });
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };

  const updateValue = (key: any, value: any) => {
    setCriteriaObj({ ...criteriaObj, [key]: value });
  };

  const onBlur = () => {
    validateForm();
  };

  const handleCleanMail = () => {
    setCriteriaObj({ ...criteriaObj, email: '' });
    setErrorObj({ ...errorObj, email: '' });
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    isFormEmpty = Object.values(criteriaObj).every(
      (x) => x === null || x === ''
    );

    if (!validateForm()) return;
    setApiErrorMsg('');

    login(criteriaObj.email, criteriaObj.password)
      .then((data: any) => {
        onLogin(data);
        if (data) {
          history.push(routesMapAfterLogin[data.permissions]);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setApiErrorMsg(err);
      });
  };

  return isLoggedIn === true ? (
    <Redirect to="/" />
  ) : (
    <Fragment>
      <form onSubmit={handleSave}>
        <Card className={classes.container}>
          <Box className={classes.signInText}>
            Bitte gib Deine Zugangsdaten ein
          </Box>
          {authError && (
            <Box mt={3} className={classes.alert}>
              <Alert severity="error">{authError}</Alert>
            </Box>
          )}
          {apiErrorMsg && (
            <Box mt={3} className={classes.alert}>
              <Alert severity="error">{apiErrorMsg}</Alert>
            </Box>
          )}
          <Box className={classes.inputFieldContainer} mt={5}>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="email" className={classes.inputLabel}>
                  Mail-Adresse
                </InputLabel>
                <FilledInput
                  id="email"
                  disableUnderline
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) => updateValue('email', event.target.value)}
                  onBlur={() => onBlur()}
                  error={!!errorObj.email}
                  value={criteriaObj.email}
                  endAdornment={
                    !!errorObj.email && !!criteriaObj.email ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleCleanMail}
                          edge="end"
                          style={{
                            background: '#FF5F58',
                            width: 20,
                            height: 20,
                            marginRight: 4,
                          }}
                        >
                          <ClearIcon
                            style={{ color: 'white' }}
                            fontSize="small"
                          />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ''
                    )
                  }
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.email}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="password" className={classes.inputLabel}>
                  Passwort
                </InputLabel>
                <FilledInput
                  id="password"
                  type="password"
                  disableUnderline
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) =>
                    updateValue('password', event.target.value)
                  }
                  onBlur={() => onBlur()}
                  error={!!errorObj.password}
                  value={criteriaObj.password}
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.password}
                </FormHelperText>
                <Box className={classes.resetPassword}>
                  <Button
                    onClick={() => {
                      setForgotPassword(true);
                    }}
                  >
                    Passwort vergessen?
                  </Button>
                  <Button
                    onClick={() => {
                      setResendConfirm(true);
                    }}
                  >
                    Bestätigung erneut senden
                  </Button>
                </Box>
                <Box mt={1.5}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className={classes.signInBtn}
                    size="large"
                  >
                    OK
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </Box>
        </Card>
      </form>
      {forgotPassword && (
        <ForgotPasswordModal
          emailCase={'password-email'}
          onClose={() => setForgotPassword(false)}
        />
      )}
      {resendConfirm && (
        <ForgotPasswordModal
          emailCase={'confirmation-email'}
          onClose={() => setResendConfirm(false)}
        />
      )}
    </Fragment>
  );
};
