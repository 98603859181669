import { Box, Button, Dialog, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthDispatch } from '../../providers';

const useStyles = makeStyles((theme) => ({
  popup: {
    display: 'flex',
    '& .MuiButtonBase-root': {
      padding: '10px !important',
      width: '100%',
      justifyContent: 'flex-start',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
      },
    },
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px',
    backgroundColor: '#8e8e8e',
  },
  description: {
    color: '#fff',
    fontSize: '26px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    marginBottom: '30px',
  },
  button: {
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '5px',
    backgroundColor: '#24c776',
    padding: '15px 50px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#24c776',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#fff',
  },
}));

export const LogoutNotification: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { onLogout }: any = useAuthDispatch();

  const handleClose = () => {
    setOpen(false);
    onLogout();
    history.push('/login');
  };

  const logout = () => {
    setOpen(true);
  };

  return (
    <Box className={classes.popup}>
      <Button data-testid="logout" onClick={() => logout()}>
        Logout
      </Button>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter') {
            handleClose();
          }
        }}
      >
        <Box className={classes.dialog}>
          <Box className={classes.description} id="alert-dialog-title">
            Du bist jetzt ausgelogged
          </Box>
          <Button
            data-testid="confirm-logout"
            className={classes.button}
            onClick={handleClose}
          >
            Alles Klar
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default LogoutNotification;
