import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '../../assets/expandGray.svg';
import React, { FC, Fragment, useState } from 'react';
import { submitSelectedToolsListAPI } from '../../api';
import BadgeGreen from '../../assets/badgeGreen.svg';
import BadgeGrey from '../../assets/badgeGrey.svg';
import {
  ClientReflections,
  ConfirmationPopup,
  WeiteresVorgehen,
} from '../common';
import { ButtonWithTooltip, IF } from '../core';
import { getFormattedDate } from '../../utils';

const useStyles = makeStyles((theme) => ({
  accordionCoachingSession: {
    width: '100%',
    background: '#7b7b78',
    border: 0,
    boxShadow: '0 2px 8px 0 rgba(57, 64, 82, 0.1)',
    borderRadius: 10,
  },
  accordionSummaryCoachingSession: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    height: 70,
  },
  accordionHeadingCoachingSession: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
    flex: '1 1 0',
  },
  accordionHeadingCoachingSessionLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  accordionHeadingCoachingSessionHeading: {
    color: '#8e8e8e',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'bold',
    marginLeft: '20px',
  },
  accordionDetailsCoachingSession: {
    display: 'flex',
    flexDirection: 'column',
  },
  badge: {
    borderRadius: '50%',
    backgroundColor: '#9d9d9d',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    height: 25,
    width: 25,
  },
  badgeNumber: {
    margin: 'auto',
  },
  accordionHeadingCoachingExpandMore: {
    width: 25,
    transform: 'rotate(180deg)',
  },
  badgeImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#9d9d9d',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  badgeCount: {
    position: 'absolute',
    top: '13px',
    fontSize: '14px',
    fontFamily: 'PT serif',
  },
  submitBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 12px',
  },
  submitBtn: {
    color: theme.palette.common.white,
    textTransform: 'none',
    '&.MuiButton-root:hover': {
      backgroundColor: '#9d9d9d',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  submitBtnDisabled: {
    textTransform: 'none',
    color: '#BCBCBC',
    fontWeight: 500,
  },
  iconArrowForward: {
    fontSize: 18,
    cursor: 'pointer',
    backgroundColor: '#24c776',
    borderRadius: '50%',
    marginLeft: '5px',
    padding: '5px',
  },
  iconArrowForwardDisabled: {
    cursor: 'unset',
    color: 'white',
  },
  moduleFinished: {
    color: '#24c776 !important',
  },
  moduleFinishedBg: {
    backgroundColor: '#24c776 !important',
  },
  pointerNone: {
    pointerEvents: 'none',
  },
  timestamp: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 70,
    fontSize: '14px',
    color: '#9d9d9d',
    fontFamily: 'Roboto',
  },
}));

type Props = {
  moduleName: string;
  moduleNumber: number;
  moduleDone: boolean;
  moduleId: number;
  submoduleId: number;
  nextModuleId: number;
  prevModuleId: number;
  prevModuleDone: boolean;
  email: string;
  isCoach: boolean;
  showEditButtonsWeiteresVorgehen: boolean;
  handleChangeSubmission: Function;
  activeModule: number;
  lastUpdated: string;
};

export const CoachingSession: FC<Props> = ({
  moduleName,
  moduleNumber,
  moduleDone,
  moduleId,
  submoduleId,
  nextModuleId,
  prevModuleId,
  prevModuleDone,
  email,
  isCoach,
  showEditButtonsWeiteresVorgehen,
  handleChangeSubmission,
  activeModule,
  lastUpdated,
}) => {
  const classes = useStyles();
  const [coachingSessionFinished, setCoachingSessionFinished] =
    useState(moduleDone);
  const [selectedToolsList, setSelectedToolsList] = useState<Array<number>>([]);
  const [open, setOpen] = useState<boolean>(false);

  const handleSubmit = (moduleId: number) => {
    setOpen(false);
    setCoachingSessionFinished(true);
    handleChangeSubmission(moduleId);
    submitSelectedToolsList(selectedToolsList);
  };

  const onChangeToolSelection = (toolIds: Array<number>) => {
    setSelectedToolsList(toolIds);
  };

  const submitSelectedToolsList = async (toolIds: Array<number>) => {
    let payload = {
      email: email,
      course: 1,
      module: nextModuleId,
      tools: toolIds,
    };

    try {
      const res = await submitSelectedToolsListAPI(payload);
    } catch (err: any) {
      console.log(err);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <Accordion
        className={`${classes.accordionCoachingSession} ${
          moduleNumber > activeModule ? classes.pointerNone : null
        }`}
        square={true}
      >
        <AccordionSummary
          expandIcon={
            <img
              className={classes.accordionHeadingCoachingExpandMore}
              src={ExpandMoreIcon}
              alt="expand-icon"
            />
          }
          aria-controls="status"
          id="status"
          className={classes.accordionSummaryCoachingSession}
        >
          <Box className={classes.accordionHeadingCoachingSession}>
            <Box className={classes.accordionHeadingCoachingSessionLeft}>
              <Box
                component="span"
                className={`
                    ${classes.badge}
                    ${coachingSessionFinished ? classes.moduleFinishedBg : ''}
                  `}
              >
                <Box component="span" className={classes.badgeNumber}>
                  {moduleNumber}
                </Box>
              </Box>
              <Typography
                className={`
                    ${classes.accordionHeadingCoachingSessionHeading}
                    ${coachingSessionFinished ? classes.moduleFinished : ''}
                  `}
              >
                {moduleName}
              </Typography>
            </Box>
            <Box
              className={`${classes.badgeImageContainer} ${
                coachingSessionFinished ? classes.moduleFinished : ''
              }`}
            >
              <img
                data-testid="badge"
                alt="Badge"
                src={coachingSessionFinished ? BadgeGreen : BadgeGrey}
                width="40"
                height="60"
              />
              <Box component="span" className={classes.badgeCount}>
                {moduleNumber}
              </Box>
            </Box>
            {moduleDone && isCoach && lastUpdated && (
              <Box className={classes.timestamp}>
                {getFormattedDate(lastUpdated)}
              </Box>
            )}
            <Box></Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsCoachingSession}>
          <IF condition={moduleNumber !== 1}>
            <ClientReflections
              email={email}
              prevModuleId={prevModuleId}
              prevModuleDone={prevModuleDone}
            />
          </IF>
          <WeiteresVorgehen
            description={``}
            showEditButtons={showEditButtonsWeiteresVorgehen}
            moduleDone={coachingSessionFinished}
            email={email}
            moduleId={moduleId}
            submoduleId={submoduleId}
            nextModuleId={nextModuleId}
            onChangeToolSelection={onChangeToolSelection}
            moduleNumber={moduleNumber}
          />
          <IF condition={isCoach}>
            <Box className={classes.submitBtnContainer}>
              <IF
                condition={
                  (!coachingSessionFinished &&
                    selectedToolsList.length !== 0) ||
                  (!coachingSessionFinished &&
                    moduleNumber === 7 &&
                    selectedToolsList.length === 0)
                }
              >
                <Button
                  data-testid="coaching-submit-button"
                  onClick={handleOpen}
                  className={classes.submitBtn}
                >
                  <Typography>Modul abschließen</Typography>
                  <CheckIcon className={classes.iconArrowForward} />
                </Button>
              </IF>
              <IF
                condition={
                  (!coachingSessionFinished &&
                    selectedToolsList.length === 0 &&
                    moduleNumber !== 7) ||
                  (moduleNumber === 1 && selectedToolsList.length === 0)
                }
              >
                <ButtonWithTooltip
                  tooltipText="Du musst zuerst mindestens ein Tool zuweisen, um das Modul abschließen zu können."
                  onClick={() => console.log('disabled')}
                  disabled={selectedToolsList.length === 0}
                >
                  <Typography className={classes.submitBtnDisabled}>
                    Modul abschließen
                  </Typography>
                  <CheckIcon
                    className={`${classes.iconArrowForward} ${classes.iconArrowForwardDisabled}`}
                  />
                </ButtonWithTooltip>
              </IF>
            </Box>
          </IF>
        </AccordionDetails>
      </Accordion>
      <IF
        condition={
          open && (moduleNumber !== 7 ? selectedToolsList.length !== 0 : true)
        }
      >
        <ConfirmationPopup
          title={'Bist Du sicher?'}
          description={
            <Fragment>
              Dadurch wird der diesem Modul zugewiesene Dashboard-Status
              <br />
              gespeichert und kann nicht mehr editiert werden.
            </Fragment>
          }
          buttonClose={'abbrechen'}
          button={'Modul Abschliessen'}
          buttonClass={'buttonRed'}
          onChange={() => handleSubmit(moduleId)}
          onClose={() => setOpen(false)}
        />
      </IF>
    </Fragment>
  );
};
