import { Box, makeStyles } from '@material-ui/core';
import React, { ChangeEventHandler, FC, Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
  descriptionContent: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: 0,
    lineHeight: '27px',
    display: 'grid',
    wordBreak: 'break-word',
    borderRadius: '10px',
    backgroundColor: '#9d9d9d',
    '&::after': {
      content: 'attr(data-replicated-value) " "',
      whiteSpace: 'pre-wrap',
      visibility: 'hidden',
      gridArea: '1 / 1 / 2 / 2',
    },
  },
  descriptionTextArea: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    border: 'unset',
    outline: 'none',
    resize: 'none',
    overflow: 'hidden',
    gridArea: '1 / 1 / 2 / 2',
    textAlign: 'justify',
    backgroundColor: '#9d9d9d',
    color: '#fff',
    borderRadius: '10px',
    padding: '16px',
    lineHeight: 1.55,
    letterSpacing: 0.66,
  },
}));

type Props = {
  readOnly?: boolean;
  textContent: string;
  rows?: number;
  backgroundColor?: string;
  color?: string;
  textAreaContainerStyles?: any;
  textAreaStyles?: any;
  ['data-testid']?: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
};

export const EditableTextArea: FC<Props> = ({
  readOnly = false,
  textContent,
  rows = 10,
  backgroundColor,
  color,
  textAreaContainerStyles,
  textAreaStyles,
  ['data-testid']: dataTestId = 'editable textarea',
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box
        className={classes.descriptionContent}
        data-replicated-value={textContent}
        style={{ ...textAreaContainerStyles, backgroundColor: backgroundColor }}
        data-testid={dataTestId}
      >
        <textarea
          className={classes.descriptionTextArea}
          value={textContent !== null ? textContent : ''}
          onChange={onChange}
          rows={rows}
          readOnly={readOnly}
          style={{
            ...textAreaStyles,
            backgroundColor: backgroundColor,
            color: color,
          }}
        ></textarea>
      </Box>
    </Fragment>
  );
};
