import { makeStyles, Box } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { UserRow } from '.';
import { getAllUsersAPI, hardDeleteUserAPI } from '../../../api';
import { FilterType, SortOrderType, UserType } from '../../../types';
import { IF } from '../../../components/core';
import { ConfirmationPopup } from '../../../components/common';
import { ActionPanel } from '.';
import { sortData } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    fontFamily: 'PTSerif',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 1.12,
    color: '#5f5f5f',
    textAlign: 'left',
    paddingBottom: 10,
  },
  allUsersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #bcbcbc',
    marginTop: 30,
    alignItems: 'baseline',
  },
  usersList: {
    marginTop: 20,
    fontFamily: 'Roboto',
    fontSize: 16,
    backgroundColor: '#7b7b78',
    borderRadius: 5,
    boxShadow: '0 2px 8px 0 rgba(57, 64, 82, 0.1)',
    padding: '20px 30px',
    maxHeight: '60vh',
    overflowY: 'auto',
  },
  deleteFab: {
    margin: 0,
    top: 'auto',
    transform: 'translate(-50%, -50%)',
    bottom: '50%',
    left: 'auto',
    position: 'fixed',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#f8f8f8',
    color: '#5f5f5f',
  },
  deleteIcon: {
    color: '#f8f8f8',
    transform: 'rotate(45deg)',
    backgroundColor: '#ff5f58',
    borderRadius: '50%',
  },
}));

export const AllUsersList: FC = () => {
  const classes = useStyles();

  const [usersList, setUsersList] = useState<Array<UserType>>([]);
  const [usersListDisplay, setUsersListDisplay] = useState<Array<UserType>>([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(-1);
  const [deleteUserEmail, setDeleteUserEmail] = useState('');

  const [sortOrder, setSortOrder] = useState<SortOrderType>(SortOrderType.ASC);
  const [sortBy] = useState('email');

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    setUsersListDisplay([...sortData(usersListDisplay, sortBy, sortOrder)]);
  }, [sortOrder]);

  const getAllUsers = async () => {
    try {
      const res = await getAllUsersAPI();
      setUsersList(res.data);
      setUsersListDisplay(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnDelete = (userId: number, userEmail: string) => {
    setDeleteUserId(userId);
    setDeleteUserEmail(userEmail);
    setDeleteDialog(true);
  };

  const handleDeleteUser = () => {
    deleteUserAPI(deleteUserId);
    setDeleteDialog(false);
  };

  const deleteUserAPI = (userId: number) => {
    const payload = {
      id: userId,
    };
    try {
      const res = hardDeleteUserAPI(payload);
      getAllUsers();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Box className={classes.allUsersContainer}>
        <Box data-testid="all-contacts" className={classes.mainHeading}>
          {'Alle Kontakte'}
        </Box>
        <ActionPanel
          sortOrder={sortOrder}
          handleChangeSort={(sortType: SortOrderType) => {
            setSortOrder(sortType);
          }}
          users={usersList}
          onSearch={(list: Array<UserType>) => setUsersListDisplay(list)}
        />
      </Box>
      <Box className={classes.usersList}>
        {usersListDisplay.map((user: UserType, index: number) => (
          <UserRow
            key={index}
            email={user.email}
            id={user.id}
            onDelete={() => handleOnDelete(user.id, user.email)}
          />
        ))}
      </Box>
      <IF condition={deleteDialog}>
        <ConfirmationPopup
          title={'Bist Du sicher?'}
          description={
            <div>
              <div>Dadurch wird der Nutzer</div>
              <div style={{ fontWeight: 500 }}>{deleteUserEmail}</div>
              <div>permanent gelöscht.</div>
            </div>
          }
          buttonClose={'abbrechen'}
          button={'Löschen'}
          buttonClass={'buttonRed'}
          onChange={handleDeleteUser}
          onClose={() => setDeleteDialog(false)}
        />
      </IF>
    </Box>
  );
};

export default AllUsersList;
