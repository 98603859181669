import {
  makeStyles,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '../../assets/expandGray.svg';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    '&.MuiAccordion-root': {
      width: '100%',
      boxShadow: '0 2px 8px 0 rgb(57 64 82 / 10%)',
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 48,
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .MuiAccordionDetails-root': {
        paddingTop: 0,
      },
    },
  },
  expandIcon: {
    width: 16,
    transform: 'rotate(180deg)',
  },
  question: {
    fontWeight: 500,
  },
  text: {
    letterSpacing: 0.54,
    color: '#3e3e3e',
    lineHeight: 1.7,
    fontSize: 14,
  },
}));

type Props = {
  question: string;
  answer: string;
};

export const FaqItem: FC<Props> = ({ question, answer }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.container} square={true}>
      <AccordionSummary
        expandIcon={
          <img
            className={classes.expandIcon}
            src={ExpandMoreIcon}
            alt="expand-icon"
          />
        }
        aria-controls="status"
        id="status"
      >
        <Typography className={`${classes.question} ${classes.text}`}>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={classes.text}>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqItem;
