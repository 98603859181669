import {
  Box,
  Button,
  Card,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CapitalRegex,
  isEmptyOrIsNull,
  LowerRegex,
  NumberRegex,
  PasswordRegex,
} from '../../utils';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ResetPasswordStyles } from '.';
import { resetPasswordRequest } from '../../api';
import { IF } from '../../components/core';

export const ResetPassword: FC<any> = () => {
  const classes = ResetPasswordStyles();
  const history = useHistory();
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [criteriaObj, setCriteriaObj] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [errorObj, setErrorObj] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [token, setToken] = useState('');
  const [apiSuccessMsg, setApiSuccessMsg] = useState('');

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [qParam] = useState(query.get('token'));

  useEffect(() => {
    qParam !== null && setToken(qParam);
  }, []);

  const resetPassword = async () => {
    const payload = {
      password: criteriaObj.newPassword,
    };
    try {
      const res = await resetPasswordRequest(token, payload);
      setApiSuccessMsg(res.data[0].status);
    } catch (err: any) {
      setApiErrorMsg(err.response.data.detail);
    }
  };

  const validatePassword = (password: string) => PasswordRegex.test(password);

  const getValidPasswordMessage = (value: any) => {
    return (
      <>
        <span
          className={`${
            value.toString().length < 8
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`Min. 8 Zeichen; `}
        </span>
        <span
          className={`${
            !CapitalRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Großbuchstabe; `}
        </span>
        <span
          className={`${
            !LowerRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Kleinbuchstabe; `}
        </span>
        <span
          className={`${
            !NumberRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Zahl`}
        </span>
      </>
    );
  };

  const validateForm = () => {
    let errorList: any = {};

    if (isEmptyOrIsNull(criteriaObj.newPassword)) {
      errorList.newPassword = 'Bitte gib ein neues Passwort ein';
    } else {
      errorList.newPassword = getValidPasswordMessage(criteriaObj.newPassword);
    }
    if (isEmptyOrIsNull(criteriaObj.confirmPassword)) {
      errorList.confirmPassword = 'Bitte gib Dein neues Passwort erneut ein';
    }
    if (
      !isEmptyOrIsNull(criteriaObj.newPassword) &&
      !isEmptyOrIsNull(criteriaObj.confirmPassword) &&
      criteriaObj.newPassword !== criteriaObj.confirmPassword
    ) {
      errorList.confirmPassword = 'Passwörter müssen identisch sein';
    }

    if (
      Object.keys(errorList).length === 1 &&
      validatePassword(criteriaObj.newPassword)
    ) {
      setErrorObj({
        newPassword: '',
        confirmPassword: '',
      });
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };

  const handleNewPassword = (value: any) => {
    let errorList: any = { ...errorObj };
    setCriteriaObj({ ...criteriaObj, newPassword: value });
    if (isEmptyOrIsNull(value)) {
      errorList.newPassword = 'Bitte gib Dein neues Passwort ein';
    } else {
      errorList.newPassword = getValidPasswordMessage(value);
    }
    setErrorObj({ ...errorList });
  };

  const handleNewPasswordConfirm = (value: any) => {
    setCriteriaObj({ ...criteriaObj, confirmPassword: value });
  };

  const onBlur = () => {
    validateForm();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handleSave = () => {
    // API call to update PW
    if (!validateForm()) return;
    resetPassword();
    setApiErrorMsg('');
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Fragment>
      <IF condition={isEmptyOrIsNull(apiSuccessMsg)}>
        <Card className={classes.container}>
          <Box className={classes.signInText}>
            Lege Dein neues Passwort fest
          </Box>
          {apiErrorMsg && (
            <Box mt={3} className={classes.alert}>
              <Alert severity="error">{apiErrorMsg}</Alert>
            </Box>
          )}
          <Box className={classes.inputFieldContainer} mt={5}>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  htmlFor="newPassword"
                  className={classes.inputLabel}
                >
                  Neues Passwort
                </InputLabel>
                <FilledInput
                  id="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  disableUnderline
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) => handleNewPassword(event.target.value)}
                  onBlur={() => onBlur()}
                  error={!!errorObj.newPassword}
                  value={criteriaObj.newPassword}
                  endAdornment={
                    <InputAdornment position="end" className={classes.eyeIcon}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.newPassword}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  htmlFor="confirmPassword"
                  className={classes.inputLabel}
                >
                  Neues Passwort wiederholen
                </InputLabel>
                <FilledInput
                  id="confirmPassword"
                  type={showPasswordConfirm ? 'text' : 'password'}
                  disableUnderline
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) =>
                    handleNewPasswordConfirm(event.target.value)
                  }
                  onBlur={() => onBlur()}
                  error={!!errorObj.confirmPassword}
                  value={criteriaObj.confirmPassword}
                  endAdornment={
                    <InputAdornment position="end" className={classes.eyeIcon}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordConfirm ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.confirmPassword}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mt={4}>
              <Button
                variant="contained"
                fullWidth
                className={classes.signInBtn}
                size="large"
                onClick={handleSave}
              >
                Neues Passwort sichern
              </Button>
            </Box>
          </Box>
        </Card>
      </IF>
      <IF condition={!isEmptyOrIsNull(apiSuccessMsg)}>
        <Card className={classes.textContainer}>
          <Box className={classes.signInText}>{apiSuccessMsg}</Box>
          <Box mt={4}>
            <Button
              className={classes.signInBtn}
              fullWidth
              onClick={() => history.push('/login')}
            >
              OK
            </Button>
          </Box>
        </Card>
      </IF>
    </Fragment>
  );
};
