import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import shadowImg from '../../../../assets/shadow.svg';
import sunImg from '../../../../assets/sun.svg';

const useStyles = makeStyles((theme) => ({
  leadContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    marginTop: 20,
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(57, 64, 82, 0.1)',
    border: 'solid 0.5px #bcbcbc',
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  },
  lead: {
    padding: '20px 24px 20px 0px',
    width: 'calc(100% - 100px)',
    display: 'grid',
    gridTemplateColumns: '0.9fr 1.1fr 0.5fr 0.75fr 1.5fr',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#5f5f5f',
    textDecoration: 'none',
    '& >': {
      '&:first-child': {
        marginRight: 15,
        width: 220,
      },
      '&:last-child': {
        display: 'flex',
        margin: '0 10px 0 30px',
        justifyContent: 'space-between',
      },
    },
  },
  checkbox: {
    padding: '20px 0px 20px 24px',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      marginRight: 10,
    },
  },
}));

type Props = {
  lead: any;
  onCheckChange: Function;
  selectedLeads?: Array<number>;
};
export const Lead: FC<Props> = ({
  lead,
  onCheckChange,
  selectedLeads = [],
}) => {
  const classes = useStyles();

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onCheckChange({ ...lead, checked: event.target.checked });

  const truncate = (str: string) => {
    return str.length > 25 ? str.substring(0, 25) + '...' : str;
  };

  return (
    <FormGroup className={classes.leadContainer}>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            onChange={handleCheckChange}
            checked={selectedLeads.indexOf(lead.user_id) > -1}
            // inputProps={{ 'data-testid': `lead-${lead.user_id}` }}
            data-testid={`lead-${lead.user_id}`}
          />
        }
        label={''}
      />
      <Link
        className={classes.lead}
        to={{
          pathname: '/coach/clients/dashboard',
          state: {
            emailProp: lead.email,
          },
        }}
      >
        <Box data-testid="email">{truncate(lead.email)}</Box>
        <Box data-testid="date">
          {'Test absolviert: '}
          {lead.c_date}
        </Box>
        <Box data-testid="neurotype">{lead.neurotype}</Box>
        <Box data-testid="auspraegung">{lead.auspraegung}</Box>
        <Box>
          <Box className={classes.icon} data-testid="sonnenseite">
            <img src={sunImg} width="50" />
            <Box>{`${lead.sonnenseite}%`}</Box>
          </Box>
          <Box className={classes.icon} data-testid="schattenseite">
            <img src={shadowImg} width="50" />
            <Box>{`${lead.schattenseite}%`}</Box>
          </Box>
        </Box>
      </Link>
    </FormGroup>
  );
};

export default Lead;
