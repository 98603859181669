import React, { FC } from 'react';
import {
  makeStyles,
  Tooltip,
  Button,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
}));

const defaultTheme = createTheme();
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        fontFamily: 'Roboto',
        padding: 20,
        position: 'relative',
        backgroundColor: '#9d9d9d',
        '&:after': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: '50%',
          widht: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '0 40px 40px 40px',
          transform: 'translateY(-50%) translateX(-50%) rotate(360deg)',
          borderColor: 'transparent transparent #9d9d9d transparent',
        },
      },
    },
  },
});

type Props = {
  tooltipText: string;
  disabled: boolean;
  onClick: any;
};

export const ButtonWithTooltip: FC<Props> = ({
  tooltipText,
  disabled,
  onClick,
  ...other
}) => {
  const classes = useStyles();
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? 'div' : undefined,
    onClick: disabled ? undefined : onClick,
  };
  return (
    <MuiThemeProvider theme={defaultTheme}>
      <MuiThemeProvider theme={theme}>
        <Tooltip className={classes.tooltip} title={tooltipText}>
          <Button {...other} {...adjustedButtonProps} />
        </Tooltip>
      </MuiThemeProvider>
    </MuiThemeProvider>
  );
};
