import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box, IconButton, makeStyles, Card } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { questions } from '../utils';

const useStyles = makeStyles((theme) => ({
  qContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#bcbcbc',
    paddingBottom: 50,
    [theme.breakpoints.down('xs')]: {
      background: 'transparent',
    },
  },
  container: {
    background: '#7b7b78',
    padding: theme.spacing(10, 20),
    marginTop: theme.spacing(5),
    width: '60vw',
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 10),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 6),
      width: 'unset',
    },
  },
  arrowContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: 270,
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrowRound: {
    width: 58,
    height: 58,
    color: 'white',
    [theme.breakpoints.down('md')]: {
      width: 38,
      height: 38,
    },
    '&,.MuiIconButton-root.Mui-disabled': {
      color: 'white !important',
      backgroundColor: '#9d9d9d !important',
    },
  },
  arrowRoundActive: {
    width: 58,
    height: 58,
    color: 'white',
    [theme.breakpoints.down('md')]: {
      width: 38,
      height: 38,
    },
    '&,.MuiIconButton-root.Mui-disabled': {
      color: 'white !important',
      background: '#0ea554 !important',
    },
  },
  qsnName: {
    textAlign: 'left',
    marginTop: theme.spacing(3.5),
    font: "500 24px/34px 'Roboto'",
    letterSpacing: '0.48px',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: 1.14,
    },
  },
  qnsOptions: {
    font: "500 14px/24px 'Roboto'",
    padding: theme.spacing(1.2, 1.2, 1.2, 2.2),
    textAlign: 'left',
    textTransform: 'uppercase',
    borderRadius: 0,
    letterSpacing: 0.3,
    boxShadow: '-1px 0px 5px 3px #9e9e9e1f',
    cursor: 'pointer',
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
    },
  },
  qnsOptionsActive: {
    font: "500 14px/24px 'Roboto'",
    padding: theme.spacing(1.2, 1.2, 1.2, 2.2),
    textAlign: 'left',
    textTransform: 'uppercase',
    borderRadius: 0,
    cursor: 'pointer',
    background: '#3e3e3e',
    color: 'white',
  },
  qsnIndex: {
    font: 'normal normal bold 22px/29px Roboto',
    letterSpacing: '0.44px',
    color: 'white',
  },
}));

type Props = {
  onSubmitAnswers: Function;
};

export const Questionnaire: FC<Props> = (props) => {
  const { onSubmitAnswers } = props;

  const classes = useStyles();

  const [currentQuestionNo, setCurrentQuestionNo] = useState(0);
  const [question, setQuestion] = useState(questions[0]);
  const [answers, setAnswers] = useState<any[]>([]);
  const [answer, setAnswer] = useState(answers[currentQuestionNo]);

  const handelArrowBack = () => {
    const prev =
      currentQuestionNo === 0 ? currentQuestionNo : currentQuestionNo - 1;
    setCurrentQuestionNo(prev);
  };

  const handelChoiceAns = (option: any) => {
    const other = answers.filter(
      (i) => i.questionNumber !== question.questionNumber
    );
    const answer = {
      ...question,
      selectedAnswer: option,
    };
    setAnswers([...other, answer]);
    handelArrowForward();
  };

  const handelArrowForward = () => {
    const next =
      questions.length === currentQuestionNo + 1
        ? currentQuestionNo
        : currentQuestionNo + 1;
    setCurrentQuestionNo(next);
  };

  useEffect(() => {
    setQuestion(questions[currentQuestionNo]);
    setAnswer(answers[currentQuestionNo]);
    if (questions.length === answers.length) {
      onSubmitAnswers(answers);
    }
  }, [currentQuestionNo, answers]);

  return (
    <Box className={classes.qContainer}>
      <Box className={classes.container}>
        <Box className={classes.arrowContainer}>
          <Box>
            <IconButton
              data-testid="arrow-back"
              className={
                answers.length <= 0
                  ? classes.arrowRound
                  : classes.arrowRoundActive
              }
              onClick={handelArrowBack}
              disabled={answers.length <= 0 ? true : false}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box className={classes.qsnIndex}>{`${currentQuestionNo + 1}/${
            questions.length
          }`}</Box>
          <Box>
            <IconButton
              data-testid="arrow-forward"
              className={
                currentQuestionNo < answers.length
                  ? classes.arrowRoundActive
                  : classes.arrowRound
              }
              onClick={handelArrowForward}
              disabled={currentQuestionNo < answers.length ? false : true}
            >
              <ArrowForwardIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
        <Box
          className={classes.qsnName}
          data-testid={`question-${question.questionNumber}`}
        >
          {question.question}
        </Box>
        <Box mt={4}>
          {question.options.map((option, index) => (
            <Fragment key={index}>
              <Box pt={1.5} key={index}>
                <Card
                  data-testid={`question-${question.questionNumber}-${option.optionNumber}`}
                  className={
                    question &&
                    answer &&
                    option.optionNumber === answer.selectedAnswer.optionNumber
                      ? classes.qnsOptionsActive
                      : classes.qnsOptions
                  }
                  onClick={() => handelChoiceAns(option)}
                >
                  <Box>{option.option}</Box>
                </Card>
              </Box>
            </Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
