import { makeStyles, Box, Menu, MenuItem, IconButton } from '@material-ui/core';
import React, { FC, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { UserSearch } from '.';
import { UserType, SortOrderType } from '../../../types';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 32,
    cursor: 'pointer',
  },
}));

type Props = {
  users: Array<UserType>;
  onSearch: Function;
  sortOrder: SortOrderType;
  handleChangeSort: Function;
};

export const ActionPanel: FC<Props> = ({
  users,
  onSearch,
  sortOrder,
  handleChangeSort,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const [usersList, setUsersList] = useState<Array<UserType>>(users);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(null);
  };

  const handleSort = (SortOrder: SortOrderType) => {
    handleClose();
    handleChangeSort(SortOrder);
  };

  return (
    <Box>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={isOpen}
        keepMounted
        open={Boolean(isOpen)}
        onClose={handleClose}
        disableScrollLock={true}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem data-testid="search" onKeyDown={(e) => e.stopPropagation()}>
          <UserSearch users={users} onSearch={onSearch} />
        </MenuItem>
        <MenuItem
          data-testid="filter-asc"
          onClick={() => handleSort(SortOrderType.ASC)}
        >
          Aufsteigend sortieren
        </MenuItem>
        <MenuItem
          data-testid="filter-desc"
          onClick={() => handleSort(SortOrderType.DESC)}
        >
          Absteigend sortieren
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ActionPanel;
