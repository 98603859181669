import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  makeStyles,
  Box,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { EmailRegex, isEmptyOrIsNull } from '../../utils';
import { forgotPasswordRequest, resendConfirmationRequest } from '../../api';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  popupContainer: {},
  popup: {
    display: 'flex',
  },
  emailDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '500px',
    height: '280px',
    backgroundColor: '#8e8e8e',
  },
  closeButton: {
    marginTop: 12,
    textTransform: 'none',
    padding: '10px 30px',
    marginRight: 20,
    backgroundColor: '#24c776',
    fontSize: 20,
    fontWeight: 'bold',
    '& > span': {
      color: 'white',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(36, 199, 118, 0.7)',
    },
  },

  inputFieldContainer: {
    padding: theme.spacing(0, 4),
    width: '380px',
    height: '56px',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#9D9D9D',
    },
  },
  inputField: {
    background: theme.palette.common.white,
    borderRadius: 2,
    '&:hover': {
      background: '#eeeeee',
    },
    '&,.MuiInputBase-input:focus': {
      background: theme.palette.common.white,
    },
  },
  inputLabel: {
    color: '#9D9D9D',
  },
  helperText: {
    color: '#FF5F58',
    marginLeft: theme.spacing(-0.2),
    fontStyle: 'italic',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#fff',
  },
  textDialog: {
    maxWidth: 488,
    height: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    background: 'rgba(14,165,84,0.5)',
    padding: theme.spacing(2, 10),
    margin: '0 auto',
  },
  successText: {
    textAlign: 'center',
    fontSize: 22,
    color: '#ffffff',
    lineHeight: 1.31,
    letterSpacing: 1.04,
  },
  button: {
    margin: '20px 10px',
    background: '#5f5f5f',
    color: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: 2,
    fontSize: 18,
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
    },
  },
}));

type Props = {
  emailCase: string;
  onClose: Function;
};
export const ForgotPasswordModal: FC<Props> = ({ emailCase, onClose }) => {
  const classes = useStyles();
  const [openEmailDialog, setOpenEmailDialog] = useState(true);
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [apiSuccessMsg, setApiSuccessMsg] = useState('');
  const [criteriaObj, setCriteriaObj] = useState({
    email: '',
  });
  const [errorObj, setErrorObj] = useState({
    email: '',
  });
  const [openTextDialog, setOpenTextDialog] = useState(false);

  const validateEmail = (email: string) => EmailRegex.test(email.toLowerCase());

  const validateForm = () => {
    let errorList: any = {};

    if (isEmptyOrIsNull(criteriaObj.email)) {
      errorList.email = 'Bitte gib Deine E-Mail-Adresse ein';
    } else if (!validateEmail(criteriaObj.email)) {
      errorList.email =
        'Bitte gib die Mail-Adresse ein, mit der Du Dich registriert hast';
    }
    if (Object.keys(errorList).length === 0) {
      setErrorObj({
        email: '',
      });
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };

  const updateValue = (key: any, value: any) => {
    setCriteriaObj({ ...criteriaObj, [key]: value });
  };

  const onBlur = () => {
    validateForm();
  };

  const handleCleanMail = () => {
    setCriteriaObj({ ...criteriaObj, email: '' });
    setErrorObj({ ...errorObj, email: '' });
  };

  const handleCloseEmailDialog = () => {
    setOpenEmailDialog(false);
    onClose();
  };

  const handleCloseTextDialog = (e: any) => {
    setOpenTextDialog(false);
    onClose(e);
  };

  const handleForgotPassword = async (payload: any) => {
    try {
      const result = await forgotPasswordRequest(payload);
      setApiSuccessMsg(
        'Bitte folge den Anweisungen in der E-Mail, die an Dich gesendet wird'
      );
      setOpenEmailDialog(false);
      setOpenTextDialog(true);
    } catch (err: any) {
      setApiErrorMsg(err.response.data.detail);
    }
  };

  const handleConfirm = () => {
    if (!validateForm()) return;
    // send email function
    if (emailCase === 'password-email') {
      handleForgotPassword({ email: criteriaObj.email });
    } else if (emailCase === 'confirmation-email') {
      handleResendConfirmation({ email: criteriaObj.email });
    }
  };

  const handleResendConfirmation = async (payload: any) => {
    try {
      const result = await resendConfirmationRequest(payload);
      setApiSuccessMsg(
        'Bitte folge den Anweisungen in der E-Mail, die an Dich gesendet wird'
      );
      setOpenEmailDialog(false);
      setOpenTextDialog(true);
    } catch (err: any) {
      setApiErrorMsg(err.response.data.detail);
    }
  };

  return (
    <Box className={classes.popup}>
      <Dialog
        onClose={handleCloseEmailDialog}
        open={openEmailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.emailDialog}>
          {!apiSuccessMsg && (
            <Box className={classes.inputFieldContainer}>
              {apiErrorMsg && (
                <Box mt={3} className={''}>
                  <Alert severity="error">{apiErrorMsg}</Alert>
                </Box>
              )}
              <Box mt={2}>
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="email" className={classes.inputLabel}>
                    Mail-Adresse
                  </InputLabel>
                  <FilledInput
                    id="email"
                    disableUnderline
                    className={classes.inputField}
                    margin="dense"
                    onChange={(event) =>
                      updateValue('email', event.target.value)
                    }
                    onBlur={() => onBlur()}
                    error={!!errorObj.email}
                    value={criteriaObj.email}
                    endAdornment={
                      !!errorObj.email && !!criteriaObj.email ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleCleanMail}
                            edge="end"
                            style={{
                              background: '#FF5F58',
                              width: 20,
                              height: 20,
                              marginRight: 4,
                            }}
                          >
                            <ClearIcon
                              style={{ color: 'white' }}
                              fontSize="small"
                            />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        ''
                      )
                    }
                  />
                  <FormHelperText
                    id="helper-text"
                    className={classes.helperText}
                  >
                    {errorObj.email}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Box>
          )}
          {!apiSuccessMsg && (
            <Button
              data-testid="confirmation-okBtn"
              className={classes.closeButton}
              onClick={handleConfirm}
            >
              {'Absenden'}
            </Button>
          )}
        </Box>
      </Dialog>
      <Dialog open={openTextDialog}>
        <Box className={classes.textDialog}>
          <Box className={classes.successText}>{apiSuccessMsg}</Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            className={classes.button}
            onClick={() => handleCloseTextDialog(true)}
          >
            OK
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ForgotPasswordModal;
